import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiLibrary } from '../helpers/api/ApiLibrary';
import { useI18nContext } from "../i18n/context/context";
import LoaderDirectiveComponent from "../directives/LoaderDirectiveComponent";


function TrackingPage({ }) {
    const [isLoading, setIsLoading] = useState(false);
    const [trckingList, setTrackingList] = useState(null);
    const [counter, setCounter] = useState({});
    const { restaurantCode } = useParams();
    const { language } = useI18nContext();

    useEffect(() => {
        const fetchData = async() => {
            setIsLoading(true);
            const response = await new ApiLibrary(language).restaurants.general.getTracking(restaurantCode);
            setIsLoading(false);

            let count = {
                all: response?.length,
                group: [...new Set(response?.map(x => x.addressIp.concat(x.appVersion).concat(x.productSub)))]?.length,
                todayAll: response.filter(x => (new Date(x.date).getDate() == (new Date).getDate()))?.length,
                todayGroup: [...new Set(response?.filter(x => (new Date(x.date).getDate() == (new Date).getDate()))?.map(x => x.addressIp.concat(x.appVersion).concat(x.productSub)))]?.length
            }
            setCounter(count)
            setTrackingList(response?.map(x => {
                const date = new Date(x.date);
                const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
                const formattedDate = localDate.replace('T', ' ').substring(0, 19);
                return {
                    addressIp: x.addressIp,
                    date: formattedDate,
                    appVersion: x.appVersion,
                    productSub: x.productSub
                }
            }))
        };
        
        if (restaurantCode) {
            fetchData();
        }
    
      }, [restaurantCode])


      return (
        <>
            {isLoading &&
              <LoaderDirectiveComponent />
            }
            <div className="display-flex">
                <div className="tracking-basic-info">
                    <div>
                        <span className="tracking-date">wszystkie: </span>
                        <span className="tracking-ip">{counter?.all}</span>
                    </div>
                    <div>
                        <span className="tracking-date">grupowe: </span>
                        <span className="tracking-ip">{counter?.group}</span>
                    </div>
                    <div>
                        dzisiaj:
                    </div>
                    <div>
                        <span className="tracking-date">wszystkie: </span>
                        <span className="tracking-ip">{counter?.todayAll}</span>
                    </div>
                    <div>
                        <span className="tracking-date">grupowe: </span>
                        <span className="tracking-ip">{counter?.todayGroup}</span>
                    </div>
                </div>
                <div className="tracking-list">
                    {trckingList?.map((x, idx) => (
                        <div key={idx}>
                            <span className="tracking-date">{x.date}</span>
                            <span className="tracking-ip">{x.addressIp}</span>
                            <span className="tracking-ip">{x.appVersion}</span>
                        </div>
                        ))
                    }    
                </div>
            </div>
        </>    
      );
}

export default TrackingPage;
