import "./SelectDirectiveStyles.css";
import { useState, useEffect, useCallback, useRef, createRef, useMemo } from "react";
import useScreenDetector from "../hooks/useScreenDetector";
import { Input, InputGroup } from 'rsuite';
import { IoClose } from "react-icons/io5";
import SelectOptionsTextComponent from "./SelectOptionsTextComponent";

function AutoCompleteDirectiveComponent({placeholder, options, onChange, value, inputClassName, clearable=true, hasError, parentElementId}){
  const [isOpened, SetIsOpened] = useState(false);
  const myRef = useRef(null);
  const listRef = createRef(null);

  const { sizeScreen } = useScreenDetector();


  useEffect(() => {
    if(!!myRef && !!listRef && !!listRef.current){
      let element = myRef.current.getBoundingClientRect();
      listRef.current.style.width = element.width + "px";
      if(parentElementId){
        let parentElement = document.getElementById(parentElementId).getBoundingClientRect();
        let parentHeight = parentElementId != "App" ? parentElement.height : parentElement.height-35;
        let listElement = listRef.current.getBoundingClientRect();
        if(parentElement.top + parentHeight < listElement.top + listElement.height){
          if((parentElement.top < listElement.top - listElement.height) && 
            (((parentElement.top + parentHeight < listElement.top + (listElement.height*0.8)) && listElement.height > 100) || ((parentElement.top + parentHeight < listElement.top + listElement.height) && listElement.height <= 100))){
            listRef.current.style.top = -listElement.height + "px";
          }
          else{
            listRef.current.style.height = parentElement.top + parentHeight - listElement.top + "px";
          }
        }          
      }
    }
  }, [sizeScreen, myRef, listRef])

  const clickOutside = useCallback((event) => {
    const el = myRef?.current
    if (!el || el.contains(event.target)) {
      SetIsOpened(true)
      return
    }
    SetIsOpened(false)
  }, [myRef])

  const selectItemFunction = useCallback((item) => {
    onChange(item);
    SetIsOpened(false);
  }, [onChange, value])

  const GetItems = useMemo(() => {
    return (
      <SelectOptionsTextComponent
        options={options?.filter((item) => value?.text !== item.text && item.text.startsWith(value?.text) )}
        onChange={selectItemFunction}
        listRef={listRef}
        value={value?.id}/>
    );
  },[options, listRef, value])

  useEffect(() => {
    window.addEventListener('click', clickOutside);
    return () => {
      window.removeEventListener('click', clickOutside);
    }
  }, []);

  const clear = useCallback((e) =>{
    onChange(null);
    e.stopPropagation();
  },[onChange])

  const handleChange = (val, e) => {
    onChange(options?.find(({text}) => text === val) ?? { text: val })
  }

  return (
    <div ref={myRef} className="sd-main" >
      <div >
          <InputGroup inside className={`${!!value?.text ? "sd-selected" : ""} ${!!hasError ? "edit-input-error" : ""}`}>
          <Input
            className={`${!!value?.text ? "sd-selected" : ""} ${!!hasError ? "edit-input-error" : ""} ${inputClassName}`}
            onFocus={(e) => SetIsOpened(true)}
            onBlur={(e) => SetIsOpened(false)}
            onChange={handleChange}
            value={value?.text}
            placeholder={placeholder}
          />
            {!!value?.text && clearable &&
              <InputGroup.Addon onClick={(e) => {clear(e);}}>
                <IoClose className="sd-clear-button" />
              </InputGroup.Addon>
          }
        </InputGroup>
      </div>
      {!!isOpened &&
            GetItems
      }
    </div>
  )
}

export default AutoCompleteDirectiveComponent;