import "bootstrap/dist/css/bootstrap.min.css";
import React, { useCallback, useMemo } from "react";
import "rsuite/dist/rsuite.min.css";
import { Dropdown, IconButton } from "rsuite";
import { IoPerson } from "react-icons/io5";
import { useCookies } from 'react-cookie';
import { ApiLibrary } from "../../../helpers/api/ApiLibrary";
import { useI18nContext } from '../../../i18n/context/context';

function ProfileComponent({}) {
  const { translate, language } = useI18nContext();
  const [cookies, setCookie, deleteCookie] = useCookies(['sefooToken']);

  const select = async (eventKey) => {
    if(eventKey == "logOut"){
        const myCookie = cookies['sefooToken'];
        if(!!myCookie?.token){
            await new ApiLibrary(language).user.users.logOut(myCookie?.userId, myCookie?.token);
            deleteCookie('sefooToken', { path: '/' });
            window.location.href = window.location.origin.concat("/login");
        }
    }
    if(eventKey == "accountConfiguration"){
      const myCookie = cookies['sefooToken'];
      if(!!myCookie?.token){
          window.location.href = window.location.origin.concat('/user/').concat(myCookie?.userId).concat("/accountConfiguration");
      }
  }
  }

  const renderIconButton = (props, ref) => {
    return (
      <IconButton {...props} ref={ref} icon={<IoPerson />} circle />
    );
  };

  return (
    <div className="profile-button">
        <Dropdown onSelect={select} renderToggle={renderIconButton}  placement="bottomEnd">
            <Dropdown.Item eventKey="accountConfiguration">{translate("accountConfiguration")}</Dropdown.Item>
            <Dropdown.Item eventKey="logOut">{translate("logOut")}</Dropdown.Item> 
        </Dropdown>
    </div>
  );
}

export default ProfileComponent;
