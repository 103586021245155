import "./ModalEdit.css";
import React, { useState, useCallback } from "react";
import Modal from '@mui/material/Modal';
import { Button } from 'rsuite';
import { useI18nContext } from "../../i18n/context/context";
import { FaXmark, FaCheck } from "react-icons/fa6";
import { FaTrashAlt  } from "react-icons/fa";

function ModalEditComponent({children, setOpen, setModelChanges, title, hideFooter, deleteItem, isSimpleModel, hideDeleteButton, hideLanguages, modalContainerClassName}) {
    const { translate } = useI18nContext();

    const [isOpen, setIsOpen] = useState(true);
    const handleClose = () => {}

  const [isDeletedQuestion, setIsDeletedQuestion] = useState(false)
  const [isSomethingChanges, setIsSomethingChanges] = useState(false)
  const [canSave, setCanSave] = useState(false)

  const [myModel, setMyModel] = useState(null)

  const setChanges = (changedModel) => {
    setMyModel(changedModel)
    setIsSomethingChanges(true)
  }

  const save = () => {
    setModelChanges(myModel)
    setIsOpen(false)
    setOpen(false)
  }

  const close = useCallback(() => {
    setOpen(false)
  }, [myModel])

  const deleteObject = () => {
    deleteItem(myModel)
    setOpen(false)
  }

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { setChanges, setCanSave, canSave });
    }
    return child;
  });

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}>
          <div>
            <div className={`${modalContainerClassName} ${isSimpleModel ? "modal-edit-simple-model-container" : "modal-edit-container"}`}>
            <div className="modal-header">
                  {title}
                </div>
              <div id="modalEditBodyContainer" className={`modal-edit-body ${isDeletedQuestion ? "modal-edit-body-deleted-question" : ""}`}>
                {!!hideLanguages &&
                  <div className="modal-edit-body-main">
                    {childrenWithProps}
                  </div>
                }
                {!hideLanguages &&
                  <>
                    <div className="modal-edit-body-main-with-language">
                      {childrenWithProps}
                    </div>
                    <div className="modal-edit-body-languages">
                      
                    </div>
                  </>
                }
              </div>
              {!hideFooter && 
                <div className="modal-edit-footer">
                  <div className="modal-edit-footer-left">
                      <Button color="green" onClick={save} disabled={isDeletedQuestion || !isSomethingChanges || !canSave} appearance="primary" className="modal-edit-footer-button">
                        {translate("save")}
                      </Button>
                      <Button color="red" onClick={close} disabled={isDeletedQuestion} appearance="primary" className="modal-edit-footer-button">
                        {translate("cancel")}
                      </Button>
                  </div>
                  <div className="modal-edit-footer-right">
                    {isDeletedQuestion && 
                      <>
                        <FaCheck size={30} onClick={deleteObject} className="modal-edit-footer-button" />
                        <FaXmark size={30} onClick={() => setIsDeletedQuestion(false)} className="modal-edit-footer-button" />
                      </>
                    }
                    {!isDeletedQuestion && !hideDeleteButton &&
                      <FaTrashAlt size={30} onClick={() => setIsDeletedQuestion(true)} className="modal-edit-footer-button"/>
                    }
                  </div>
                </div>
              }
            </div>
          </div>             
      </Modal>
    </>
    
  );
}

export default ModalEditComponent;
