import "leaflet/dist/leaflet.css";
import React, { useEffect } from "react";
import { useI18nContext } from "../../../i18n/context/context";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import {Icon, divIcon} from 'leaflet'
import markerRestaurant from "../../../assets/images/restaurant.png"
import markerBeer from "../../../assets/images/beer.png"
import markerCoffee from "../../../assets/images/coffee2.png"
import RestaurantBottomDrawer from '../../home/RestaurantBottomDrawer';
import MarkerClusterGroup from "react-leaflet-cluster";

function MapComponent({onSelectedRestaurantChange, restaurants, selectedRestaurant, OpenModal}) {
  const position = [50.07405928030033, 19.97536984701493];
  const { translate, language } = useI18nContext();


  useEffect(() => { 
    const observer = new MutationObserver((mutationsList, observer) => { 
      const elements = document.getElementsByClassName('leaflet-attribution-flag'); 
      if (elements.length > 0) { 
        console.log(elements);
        Array.from(elements).forEach(element => element.remove());
        observer.disconnect(); 
      } 
    }); 
    observer.observe(document.body, { childList: true, subtree: true }); 
    return () => { observer.disconnect(); }; 
  }, [language]);

  const defaultIcon = new Icon ({
    iconUrl: markerRestaurant,
    iconSize: [30, 30],
    popupAnchor: [-3, -20]
  })

  const coffeeIcon = new Icon ({
    iconUrl: markerCoffee,
    iconSize: [30, 30],
    popupAnchor: [-3, -20]
  })

  const beerIcon = new Icon ({
    iconUrl: markerBeer,
    iconSize: [30, 30],
    popupAnchor: [-3, -20]
  })

  const getIcon = (item) => {
    switch(item.restaurantType) {
      case "bar":
        return beerIcon
      case "cafe":
        return coffeeIcon
      default:
        return defaultIcon
    }
  }

  const createCustomClusterIcon = (cluster) => {
    return new divIcon({
      html: `<div class="cluster-icon">${cluster.getChildCount()}</div>`,
      className: "custom-marker-cluster",
      iconSize: [33, 33]
    });
  };

  return (
    <div className="">
      <MapContainer center={position} zoom={14} scrollWheelZoom={true} className="map-dynamic-container">
        <TileLayer
          attribution={`&copy; ${translate("authors")} <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>`}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup
        chunkedLoading
        iconCreateFunction={createCustomClusterIcon}>
        {!!restaurants && restaurants.map((item, idx) => (
          <Marker
            key={idx}
            position={item.locationMap}
            icon={getIcon(item)}
            eventHandlers={{
              popupopen: () => onSelectedRestaurantChange(item),
              popupclose: () => onSelectedRestaurantChange(undefined)
            }}
          >
            <Popup className="leaflet-popup" />
          </Marker>
        ))}
      </MarkerClusterGroup>

      </MapContainer>
      <RestaurantBottomDrawer
        restaurant={selectedRestaurant}
        OpenModal={OpenModal}
        onClose={() => onSelectedRestaurantChange(undefined)}
      />
    </div>
  );
}

export default MapComponent;
