import "bootstrap/dist/css/bootstrap.min.css";
import React, { } from "react";
import "rsuite/dist/rsuite.min.css";
import { IconButton } from "rsuite";
import { RiTranslate } from "react-icons/ri";

function TranslateComponent({restaurantId, notTranslatedElementsExist, translateDictionaries}) {
  return (
    <IconButton
                      icon={<RiTranslate size={15}/>}
                      onClick={() => {translateDictionaries(restaurantId)}}
                      className={`${!notTranslatedElementsExist ? "cursor-default" : ""}`}
                      circle
                      disabled={!notTranslatedElementsExist}
                      appearance="primary"
                    />
  );
}

export default TranslateComponent;
