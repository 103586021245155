import React, { useEffect, useState } from "react";
import { Input } from 'rsuite';
import { useI18nContext } from "../../i18n/context/context";

function DictionaryEditModalComponent({ model, setChanges, setCanSave, validModel }) {
  const { translate } = useI18nContext();
    
  const [myModel, setMyModel] = useState(null)
  const [myCanSave, setMyCanSave] = useState(false)

  useEffect(() => {
    if(!!model){
      setMyModel(model)
      if(!!model){
        setMyCanSave(true)
      }
      else{
        setMyCanSave(false)
      }
    }
    else{
      setMyModel("")
    }
  }, [model])

  useEffect(() => {
    setCanSave(myCanSave)
  }, [myCanSave])


  const changeValue = (val) => {
    setChanges(val);
    setMyModel(val)
    setMyCanSave(!!val && (!validModel || !validModel.map(x => x.name.toLowerCase()).includes(val.toLowerCase())));
  }

  return (
    <div className="edit-item-style">
      <div className="label-value-admin-modal-style">
        <div className="admin-edit-item-label">
          {translate("translate")}
        </div>
        <div className="edit-def-item-field">
          <Input className={`edit-item-field-text ${!!myModel ? "sd-selected" : ""} ${!myCanSave ? "edit-input-error" : ""}`}
            defaultValue={model}
            placeholder={translate("translate")}
            onChange={(val, e) => changeValue(val)}
          />
        </div>
      </div>
    </div>
  );
}

export default DictionaryEditModalComponent;
