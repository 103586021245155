import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import { useToaster, Message, Button } from 'rsuite';
import { useI18nContext } from "../../i18n/context/context";
import { ApiLibrary } from "../../helpers/api/ApiLibrary";
import LoaderDirectiveComponent from "../../directives/LoaderDirectiveComponent";
import LogoBackComponent from "../../components/base/top/LogoBackComponent";
import LanguageComponent from "../../components/base/top/LanguageComponent";
import DialogModalComponent from "../../components/modal/DialogModalComponent";
import TypesOfSubscriptionComponent from "../../components/admin/user/TypesOfSubscriptionComponent";
import ProfileComponent from "../../components/base/top/ProfileComponent";

function AccountConfigurationPage({ }) {
  const { translate, language } = useI18nContext();
  const toaster = useToaster();
  const [isLoading, setIsLoading] = useState(false);
  const [askDeleted, setAskDeleted] = useState(false)
  const [cookies, setCookie, deleteCookie] = useCookies(['sefooToken']);

  useEffect(() => {
    const verify = async () => {
      try {
        const myCookie = cookies['sefooToken'];

        if(!!myCookie?.token){
          setIsLoading(true)
          const tokenResponse = await new ApiLibrary().user.users.verifyToken(myCookie?.userId, myCookie?.token);
          setIsLoading(false)
          if (!tokenResponse?.data?.responseValue) {
            deleteCookie('sefooToken', { path: '/' });
            window.location.href = window.location.origin.concat('/login');
          }
        }        
      }
      catch(e){
        deleteCookie('sefooToken', { path: '/' });
        window.location.href = window.location.origin.concat('/login');
      }
    }
    
    verify()

  }, [])
  
  const answereDelete = async(val) => {
    if(val){
       setIsLoading(true)
       await new ApiLibrary().user.users.deleteUser(cookies['sefooToken']?.userId, cookies['sefooToken']?.token);
       setIsLoading(false)
       deleteCookie('sefooToken', { path: '/' });
       toaster.push(
        <Message showIcon type="success" closable>
          {translate("removeUserSuccess")}
        </Message>, 
        { placement: "topEnd", duration: 5000 }
      )
       window.location.href = window.location.origin.concat('/login');
    }
  }


  return (
    <>
        {isLoading &&
          <LoaderDirectiveComponent />
        }
        {askDeleted &&
          <DialogModalComponent blockCloseOutside type="confirm" setAnswere={answereDelete}  open={askDeleted} onClose={() => setAskDeleted(false)} text={translate("deleteAccountAsk")} />
        }
        <div>
            <div className="col-12 margin-bottom-sx header-flex-style main-header-style">
              <div className="margin-top-sx" >
                <LogoBackComponent pathBack={window.location.origin.concat('/user/').concat(cookies['sefooToken']?.userId)} />
              </div>
              <div className="display-flex">
                <LanguageComponent />
                <ProfileComponent />
              </div>
            </div>
          </div>
          <div className="admin-header-underline" />
          <div className="admin-user-details-container">
              <div className="admin-basic-data-inside-container">
                <div className="margin-bottom-xl">
                  <TypesOfSubscriptionComponent />
                </div>
              <div>
                <Button color="blue" onClick={() => window.location.href = window.location.origin.concat('/user/').concat(cookies['sefooToken']?.userId).concat("/changePassword")} appearance="primary" className="modal-edit-footer-button">
                    {translate("changePassword")}
                </Button>
              </div>
              <div>
              <Button color="red" onClick={() => setAskDeleted(true)} appearance="primary" className="modal-edit-footer-button">
                    {translate("deleteAccount")}
              </Button>
              </div>
              </div>
            </div>
    </>    
  );
}

export default AccountConfigurationPage;
