import React from "react";
import { Toggle } from "rsuite";
import { useI18nContext } from "../../../i18n/context/context";

function MenuCardFiltersComponent({ filtersConfig, changeFilterValue, extraData }) {
  const { translate, language } = useI18nContext();

  const {id, ...filters} = filtersConfig;

  return (
    <div className={`edit-filter-container ${extraData.defaultLang != language ? "disabled-style" : ""}`}>
      <div className="label padding-md">
        {translate("selectVisibleFilters")}
      </div>
      <div>
              {!!filters && Object.keys(filters).map((item, idx) => (
                <div key={idx} className="edit-filter-item">
                  <div className="margin-bottom-mx padding-left-xl display-flex col-11 filter-min-width" >
                    <div className={`full-width label cursor-pointer ${filters[item] ? "selected-label" : ""}`} onClick={(e) => changeFilterValue(item, !filters[item])} >{translate(item)}</div>
                    <div className="">{<Toggle checked={filters[item]} onChange={(val) => changeFilterValue(item, val)} />}</div>
                  </div>
                </div>
              ))}
        </div>
    </div>
  );
}

export default MenuCardFiltersComponent;
