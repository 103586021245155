import React, { useState } from "react";
import { HiTranslate } from "react-icons/hi";
import { useI18nContext } from "../../../i18n/context/context";
import { Sort } from '../../../helpers/arrays/sorting';
import { ApiLibrary } from "../../../helpers/api/ApiLibrary";
import LoaderDirectiveComponent from "../../../directives/LoaderDirectiveComponent";
import { useToaster, Message } from 'rsuite';
import ModalEditComponent from "../../modal/ModalEditComponent";
import DictionaryEditModalComponent from "../../admin/DictionaryEditModalComponent";

function DishItemPriceComponent({ prices, priceVariants, className, extraData }) {
  const { language, translate } = useI18nContext();
  const toaster = useToaster();
  const [isLoading, setIsLoading] = useState(false);
  const [dictionaryItem, setDictionaryItem] = useState(null);

  const changeDictionary = async (dictionaryItem, val) => {
    dictionaryItem.text = val;
    setIsLoading(true)
    let result = await new ApiLibrary(language).dictionary.change(dictionaryItem)
    setIsLoading(false)
    dictionaryItem =result.data;
    toaster.push(
      <Message showIcon type="success" closable>
        {translate("changedTranslation")}
      </Message>, 
      { placement: "topEnd", duration: 5000 }
    )
  }

  return (
    <>
    {isLoading &&
        <LoaderDirectiveComponent />
    }
    {!!dictionaryItem &&
          <ModalEditComponent
            setOpen={() => setDictionaryItem(null)}
            model={dictionaryItem.text}
            setModelChanges={(val) => changeDictionary(dictionaryItem, val)}        
            hideLanguages
            isSimpleModel
            hideDeleteButton
            title={translate("translate")}
        >
          <DictionaryEditModalComponent />
        </ModalEditComponent>
        }
        {!!prices && prices.length > 0 && 
            <div className={`price-body-inside ${className}`}>
                {prices.map((item, idx) => (
                    <div className="price-single-full-element" key={idx}>
                        <div className="label">{item.value} {item.currency}</div>
                    </div>
                ))}
            </div>
        }
        {!!priceVariants && priceVariants.length > 0 && 
            <div>
                <div className={className}>
                  {priceVariants.sort((a, b) => Sort.numericAsc(a.order, b.order)).map((variant, idx) => (
                    <div key={idx} className="display-flex">
                      <div className="price-variant label">
                        {variant.variant.items?.find(x => x.lang == language)?.text}
                        {variant.variant?.text}
                        {!!extraData && extraData.defaultLang != language && 
                    <span className="translate-button" onClick={(e) => { e.stopPropagation(); setDictionaryItem(variant.variant?.items?.find(x => x.lang == language))}}>
                      <HiTranslate size={16} />
                    </span>
                  }
                      </div>
                      <div className="price-variant-prices">
                        <div className="price-body-inside">
                            {variant.prices.map((item, idx) => (
                                <div className="price-single-full-element" key={idx}>
                                    <div className="label">{item.value} {item.currency}</div>
                                </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
            </div>
        }
    </>

  );
}

export default DishItemPriceComponent;