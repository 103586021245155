import { useI18nContext } from "../../i18n/context/context";
import ModalInfoComponent from "../modal/ModalInfoComponent";

function CardInfoContainer({card, canShowInfo, setShowedCatalogInfoFunction}) {
    const { language } = useI18nContext();
    
    return (
      <>
      {canShowInfo && !!card.info?.items?.find(x => x.lang == language)?.text &&
            <ModalInfoComponent
              info={card.info?.items?.find(x => x.lang == language)?.text}
              onClose={() => setShowedCatalogInfoFunction(card.id)}
              open={true}
            />
          }
      </>
      
    );
  }
  
  export default CardInfoContainer;
  