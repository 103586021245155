import { useMemo } from 'react';

class CloudinaryTransformationUrlBuilder {
  transformations = [];
  url;

  constructor(cloudinaryUrl) {
    this.url = cloudinaryUrl
  }

  withAspectRatio(ratio) {
    this.transformations.push(`ar_${ratio}`);
    return this;
  }

  withCrop(cropMode) {
    this.transformations.push(`c_${cropMode}`);
    return this;
  }

  withWidth(width) {
    this.transformations.push(`w_${width}`);
    return this;
  }

  withHeight(height) {
    this.transformations.push(`h_${height}`);
    return this;
  }

  build() {
    if (!this.url) {
      return null;
    }

    let [base, imagePath] = this.url?.split('/upload/');
    base += '/upload';

    return [base, this.transformations.join(','), imagePath].join('/');
  }
}

function CloudinaryImage({src, aspectRatio, cropMode, width, height, ...props}) {
  const url = useMemo(() => {
    const builder = new CloudinaryTransformationUrlBuilder(src)
      aspectRatio && builder.withAspectRatio(aspectRatio);
      cropMode && builder.withCrop(cropMode);
      width && builder.withWidth(width);
      height && builder.withHeight(height);
    return builder.build();
  }, [src, cropMode, width, height, aspectRatio]);

  return (
    <img
      {...props}
      src={url.replace('http://', 'https://')}
    />
  );
}

export default CloudinaryImage;