import React from "react";
import { IoClose } from "react-icons/io5";
import { Loader } from 'rsuite';
import VideoPlayerComponent from "../../base/VideoPlayerComponent";

function AdminPicturesItemComponent({ medias, onRemove, disabled=false }) {
  return (
    <div className={`admin-pictures-container ${disabled ? "disabled-style" : ""}`}>
      {!!medias && medias.map((media) => (
        // <AdminPictureItemComponent item={media} onRemove={onRemove} />
        <div className="admin-pictures-item" key={media.id}>
          {!!media && (
            <>
              {(media.url.slice(-4) == ".jpg" || media.url.slice(-4) == ".png") && (
                <div className='dropzone-picture-container' onClick={(e) => e.stopPropagation()}>
                  <div>
                    <img src={media.url.replace('http://', 'https://')} className="dropzone-picture-style" />
                  </div>
                  <div className='delete-image'>
                    <IoClose
                      className="remove-button"
                      onClick={(e) => {e.stopPropagation(); onRemove(media) }}
                    />
                  </div>
                </div>)
              }
              {!(media.url.slice(-4) == ".jpg" || media.url.slice(-4) == ".png") && (
                <div className='dropzone-picture-container' onClick={(e) => e.stopPropagation()}>
                  <div>
                    <VideoPlayerComponent
                      id="demo-player"
                      publicId={media.url.replace('http://', 'https://')}
                      className="dropzone-picture-style"
                      autoplay={false}
                      playIfFocus={true}
                      muted
                      currentTime={0}
                    />
                  </div>
                  <div className='delete-image'>
                    <IoClose
                      className="remove-button"
                      onClick={(e) => {e.stopPropagation(); onRemove(media) }}
                    />
                  </div>
                </div>
              )
              }
            </>)
          }
          {!media &&
                    <div className='dropzone-loading'>
                      <Loader size="lg" className='dropzone-icon' />
                    </div>
          }
        </div>
      ))}
    </div>
  );
}

export default AdminPicturesItemComponent;
