import { useCallback, useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import { ImageHandler, VideoHandler } from 'quill-upload';
import 'quill/dist/quill.snow.css';
import ResizeImage from 'quill-resize-image';
import { ApiLibrary } from '../../helpers/api/ApiLibrary';

export const useQuillEditor = (folder, readOnly) => {
  const { Quill, quill, quillRef } = useQuill({
    readOnly,
    modules: {
      imageHandler: {
        upload: async (file) => {
          const response = await new ApiLibrary().common.add(file, folder);

          return response.data.url;
        },
      },
      // videoHandler: {
      //   upload: async (file) => {
      //     const response = await new ApiLibrary().common.add(file, folder);

      //     return response.data.url;
      //   },
      // },
      resize: {
        locale: {},
      },
    },
  });

  if (Quill && !quill) {
    Quill.register("modules/imageHandler", ImageHandler);
    // Quill.register("modules/videoHandler", VideoHandler);
    Quill.register('modules/resize', ResizeImage);
    let Font = Quill.import('formats/font');
    Font.whitelist = ['inconsolata', 'roboto', 'mirza', 'arial'];
    Quill.register(Font, true);
    let parchment = Quill.import('parchment');
    Quill.register(parchment, true);
  }

  useEffect(() => {
    // const targetNode = document.querySelector('.ql-editor');

    // if (!targetNode) {
    //   return;
    // }

    // const config = { childList: true, subtree: true };

    // const callback = function(mutationsList) {
    //   for (const mutation of mutationsList) {
    //     if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
    //       mutation.addedNodes.forEach((node) => {
    //         if (node.nodeType === Node.ELEMENT_NODE && node.tagName === 'IFRAME') {
    //           node.classList = ['quill-video'];
    //         }
    //       });
    //     }
    //   }
    // };
    // const observer = new MutationObserver(callback);
    // observer.observe(targetNode, config);

    // return () => {
    //   observer.disconnect();
    // }
  }, [quill])

  const setContent = useCallback((content) => {
    if (quill) {
      quill.root.innerHTML = content;
    }
  }, [quill]);

  const setDefContent = useCallback((content) => {
    if (quill) {
      quill.root.innerHTML = content;
    }
  }, [quill]);

  const getContent = useCallback(() => {
    return quill.root.innerHTML;
  }, [quill]);

  return {
    quillRef,
    setContent,
    setDefContent,
    getContent,
    quill
  };
}