import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nContextProvider } from './i18n/context/context';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from "./ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <I18nContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </I18nContextProvider>
  </ErrorBoundary>
);

reportWebVitals();
