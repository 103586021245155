import React, { useCallback, useState } from "react";
import InputDirectiveComponent from "../../../../directives/InputDirectiveComponent";
import { InputGroup, InputNumber } from 'rsuite';
import { IoClose } from "react-icons/io5";
import { useI18nContext } from '../../../../i18n/context/context';

function PriceMinMaxComponent({ SetFilterValue, placeholder, value }) {
  const { translate } = useI18nContext();

  return (
    <div className="price-filter-input-style">
      <InputGroup className={`price-filter-input-style ${!!value ? "sd-selected" : ""}`}>
        <InputNumber 
          defaultValue={value}
          className="price-filter-input-inside"
          placeholder={placeholder}
          min={0}
          value={!!value ? value : null}
          onChange={(val, e) => SetFilterValue(val)} step={0.01} />
        <InputGroup.Addon className="input-number-clear">
            <IoClose className={`${!value ? "hide-style" : ""} sd-clear-button`}
              onClick={(e) => {
                SetFilterValue(0)
              }} 
          />
        </InputGroup.Addon>
      </InputGroup>
    </div>
  );
}

export default PriceMinMaxComponent;