import { useEffect, useRef, useState } from 'react';
import { AdvancedVideo, lazyload } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { extractPublicId } from 'cloudinary-build-url';
import { FaPlay } from "react-icons/fa";


const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
  },
})

const VideoPlayerComponent = ({
  id,
  publicId,
  className="", classNameContainer="", autoplay=true, setVideoRef, playIfFocus, touchEndCallback, muted=true, canPlay=true}) => {
  const videoRef = useRef();
  const playIconRef = useRef();
  const [canTouchEndCallback, setCanTouchEndCallback] = useState(true);

  const setShowPlayIcon = (val) => {
    if(playIconRef?.current){
      playIconRef.current.style.display = val ? 'block' : 'none';
    }
  }

  useEffect(() => {
    if(videoRef.current){
      if(setVideoRef){
        setVideoRef(videoRef);
      }
    }
  }, [videoRef]);
  
  useEffect(() => {
    if(playIconRef?.current){
      setShowPlayIcon(!autoplay)
    }
  }, [playIconRef, autoplay])

  const mouseEnter = (e) => {
    // if(playIfFocus && canPlay){
    //   videoRef?.current.videoRef?.current.play();
    //   setShowPlayIcon(false);
    // }
  }

  const mouseClick = (e) => {
    if (videoRef?.current.videoRef?.current.paused && canPlay) {
      videoRef?.current.videoRef?.current.play();
      setShowPlayIcon(false);
    } else {
      videoRef?.current.videoRef?.current.pause();
      setShowPlayIcon(true);
    }
  }

  const stopVideo = () => {
    videoRef?.current.videoRef?.current.pause();
    setShowPlayIcon(true);
  }

  const mouseLeave = (e) => {
    if(playIfFocus){
      stopVideo();
    }
  }

  const touchStart = (e) => {
    e.stopPropagation()
    if(playIfFocus){
      keepTouching();
    }
  }

  const touchEnd = (e) => {
    if(playIfFocus){
      stopVideo();
    }
    if(touchEndCallback && canTouchEndCallback){
      touchEndCallback(e);
    }
    setCanTouchEndCallback(true)
  }

  const touchMove = (e) => {
    setCanTouchEndCallback(false)
  }

  const keepTouching = () =>{
    if(canPlay){
      videoRef.current.play();
    }
  }

  return (
    <div 
      className={`video-container ${classNameContainer}`}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onMouseDown={mouseClick}
      onTouchMove={touchMove}
      onTouchStart={touchStart}
      onTouchEnd={touchEnd} 
      onTouchCancel={touchEnd}
    >
      <div ref={playIconRef} className="item-image-play">
        <FaPlay size="sm" className="item-image-play-inside" />
      </div>
      <AdvancedVideo
        ref={videoRef}
        className={className}
        cldVid={cld.video(extractPublicId(publicId))}
        cldPoster={cld.image(extractPublicId(publicId)).setAssetType('video').format('auto:image').toURL()}
        plugins={[lazyload()]}
        controls={false}
        muted={muted}
        loop
        autoPlay={autoplay}
      />
    </div>
  )
}

export default VideoPlayerComponent;