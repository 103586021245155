/* eslint-disable react-hooks/exhaustive-deps */
import "../../App.css";
import "../../css/dish.css";
import "../../css/global.css";
import "../../css/admin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Favicon from "react-favicon";
import { useState, useEffect } from "react";
import { Button, useToaster, Message } from 'rsuite';
import { useCookies } from 'react-cookie';
import { useI18nContext } from "../../i18n/context/context";
import LoaderDirectiveComponent from "../../directives/LoaderDirectiveComponent";
import SelectDirectiveComponent from "../../directives/SelectDirectiveComponent";
import { ApiLibrary } from "../../helpers/api/ApiLibrary";
import ColorSelectorComponent from "../../components/admin/ColorSelectorComponent";
import colors from '../../datas/colors.json'
import currencies from '../../datas/currencies.json'



function Configuration({ Restaurant, setCheckChangeBookmark, handleChange }) {
  const { translate, language, languageList } = useI18nContext();
  const toaster = useToaster();
  const [cookies, setCookie, deleteCookie] = useCookies(['sefooToken']);
  const [faviconUrl, setFaviconUrl] = useState(window.location.origin.concat("/").concat(Restaurant.color).concat('.svg'))
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState(null);
  const colorsSelect = Object.keys(colors).map(color => ({value: color, text: <ColorSelectorComponent color={colors[color].firstColor} />}))

  useEffect(() => {
    if(Restaurant){
      let thisModel = {...Restaurant};
      thisModel.defaultLang = languageList.map(x => ({value: x.value, text: x.label})).filter(x => x.value == thisModel.defaultLang)[0].value
      setModel(thisModel);
    }
  }, [Restaurant])

  const handleSave = async () => {
    setIsLoading(true);
    let response = await new ApiLibrary().restaurants.general.update(Restaurant.id, model)
    if(!response?.data){
      setIsLoading(false);
      return
    }
    
    let root = document.querySelector(':root');
    let firstColor = colors.sefoo.firstColor;
    let secondColor = colors.sefoo.secondColor;
    let thirdColor = colors.sefoo.thirdColor;
    let fourthColor = colors.sefoo.fourthColor;
    let fifthColor = colors.sefoo.fifthColor;
    let sixthColor = colors.sefoo.sixthColor;
    if(Object.keys(colors).includes(model.color)){
      firstColor = colors[model.color].firstColor;
      secondColor = colors[model.color].secondColor;
      thirdColor = colors[model.color].thirdColor;
      fourthColor = colors[model.color].fourthColor;
      fifthColor = colors[model.color].fifthColor;
      sixthColor = colors[model.color].sixthColor;
    }
    root.style.setProperty('--sefooColor', firstColor);
    root.style.setProperty('--secondSefooColor', secondColor);
    root.style.setProperty('--rs-btn-primary-bg', firstColor);
    root.style.setProperty('--rs-btn-primary-hover-bg', secondColor);
    root.style.setProperty('--rs-menuitem-active-text', secondColor);
    root.style.setProperty('--rs-dropdown-item-text-active', secondColor);
    root.style.setProperty('--rs-btn-primary-active-bg', secondColor);
    root.style.setProperty('--rs-btn-ghost-text', firstColor);
    root.style.setProperty('--rs-btn-ghost-border', secondColor);
    root.style.setProperty('--rs-btn-ghost-hover-text', secondColor);
    root.style.setProperty('--rs-btn-ghost-hover-border', secondColor);
    root.style.setProperty('--rs-toggle-checked-hover-bg', firstColor);
    root.style.setProperty('--rs-input-focus-border', firstColor);
    root.style.setProperty('--secondBackgroundColor', thirdColor);
    root.style.setProperty('--rs-dropdown-item-bg-active', thirdColor);
    root.style.setProperty('--rs-listbox-option-hover-bg', fourthColor);
    root.style.setProperty('--rs-menuitem-active-bg', fourthColor);
    root.style.setProperty('--rs-message-info-bg', sixthColor);
    root.style.setProperty('--rs-message-info-icon', firstColor);
    root.style.setProperty('--rs-color-focus-ring', fifthColor);
    setFaviconUrl(window.location.origin.concat("/").concat(model.color).concat('.svg'))

    response = await new ApiLibrary().restaurants.general.getRestaurantDataForAdmin(cookies['sefooToken']?.userId, response.data.restaurantCode, cookies['sefooToken']?.token);
    handleChange(response.data);
    setIsLoading(false);
    setCheckChangeBookmark(false)
    toaster.push(
      <Message showIcon type="success" closable>
        {translate("saveChangesSuccess")}
      </Message>, 
      { placement: "topEnd", duration: 5000 }
    )
  }

  const onChangeLangsTextValue = (idx, val) => {
    const langs = [...model?.extraConfig?.langs];
    if(idx != null) {
      langs[idx] = val;
    } else {
      langs.push(val)
    }

    const updatedModel = {
      ...model
    }

    updatedModel.extraConfig.langs = langs.filter(x => !!x)

    setModel(updatedModel);
    setCheckChangeBookmark(true)
  }

  const onChangeCurrenciesValue = (idx, val) => {
    const currencies = [...model?.extraConfig?.currencies];
    if(idx != null) {
      currencies[idx] = val;
    } else {
      currencies.push(val)
    }

    const updatedModel = {
      ...model
    }

    updatedModel.extraConfig.currencies = currencies.filter(x => !!x)

    setModel(updatedModel);
    setCheckChangeBookmark(true)
  }


  return (
    <>
    <Favicon url={faviconUrl} />
      {isLoading &&
        <LoaderDirectiveComponent />
      }
      <div className="admin-header-underline" />
      {!!model && 
        <>
          <div className="admin-configuration-qr-image-container">
              <a href={model.qrPhoto} download="qr.png">
                <img src={model.qrPhoto} className="admin-configuration-qr-image" />
              </a>
          </div>
          <div className={`admin-basic-data-container ${model.defaultLang != language ? "disabled-style" : ""}`}>
            <div  className="admin-basic-data-inside-container margin-top-xl">
              <div className="display-flex">
                <div className="admin-basic-label">
                  {translate("defaultLanguage")}
                </div>
                <div className="admin-basic-edit-item-field-text  ">
                  {translate(language)}
                </div>
                
              </div>

            <div className="display-flex margin-top-xl">
              <div className="admin-basic-label  ">
                {translate("additionalLanguages")}
              </div>
              <div className="edit-basic-items-field  ">
              <div className="">
                {[...(model?.extraConfig?.langs ?? []), null].filter(x => {
                    if(model?.extraConfig?.langs?.length < 5){
                      return x || !x;
                    }
                    return !!x
                  }).map((value, idx) => (
                  <div key={value} className="admin-basic-edit-item-field-text margin-top-xl">
                    <SelectDirectiveComponent 
                      parentElementId="App"
                      placeholder={translate("language")}
                      options={languageList.filter(x => x.value != model.defaultLang && !model?.extraConfig?.langs.includes(x.value)).map(x => ({value: x.value, text: translate(x.value)}))}
                      // hiddenOptionsText={myModel.allergens}
                      onChange={(val) => onChangeLangsTextValue(idx, val)}
                      selectedOption={value ? {text: translate(value), value} : null}/>
                  </div>)
                )
                }
                </div>
              </div>
            </div>

            <div className="display-flex margin-top-xl">
              <div className="admin-basic-label  ">
                {translate("selectedCurrencies")}
              </div>
              <div className="edit-basic-items-field  ">
              <div className="">
                {[...(model?.extraConfig?.currencies ?? []), null].filter(x => {
                    if(model?.extraConfig?.currencies?.length < 3){
                      return x || !x;
                    }
                    return !!x
                  }).map((value, idx) => (
                  <div key={value} className="admin-basic-edit-item-field-text margin-top-xl">
                    <SelectDirectiveComponent 
                      parentElementId="App"
                      placeholder={translate("currency")}
                      options={currencies.sort((a, b) => a.localeCompare(b)).filter(x => !model?.extraConfig?.currencies.includes(x)).map(x => ({value: x, text: x}))}
                      // hiddenOptionsText={myModel.allergens}
                      onChange={(val) => onChangeCurrenciesValue(idx, val)}
                      selectedOption={value ? {text: value, value} : null}
                      clearable={model?.extraConfig?.currencies?.length > 1}/>
                  </div>)
                )
                }
                </div>
              </div>
            </div>

              <div className="display-flex margin-top-xl">
                <div className="admin-basic-label">
                  {translate("color")}
                </div>
                <div className="admin-basic-edit-item-field-text  ">
                  <SelectDirectiveComponent 
                    options={colorsSelect}
                    selectedOption={colorsSelect.filter(x => x.value == model.color)[0]}
                    parentElementId="App"
                    clearable={false}
                    onChange={(val) => {
                      let thisModel = {...model};
                      thisModel.color = val;
                      thisModel.extraConfig.color = val;
                      thisModel.extraConfig.colorCode = colors[val].firstColor;
                      thisModel.extraConfig.defUrl = window.location.origin;
                      thisModel.extraConfig.logoUrl = window.location.origin.includes('sefoo.') ? colors[val].url : colors[val].testUrl;
                      setModel(thisModel);
                      setCheckChangeBookmark(true)
                    }}/>
                  </div>
              </div>
            </div>
            <div className='save-button-container'>
              <Button
                color="green"
                appearance="primary"
                onClick={() => handleSave()}>
                {translate('save')}
              </Button>
            </div>
          </div>
        </>
      }

    </>
  );
}

export default Configuration;
