import { BaseApi } from '../shared/baseApi';
import { createDeleteRequest, createGetRequest, createPostRequest, createPutRequest } from '../shared/fetch';

export class CardsApi extends BaseApi {
  getFlatDishesList(cardId, options = {}) {
    return createGetRequest(`catalog/${cardId}/products`, this._language, undefined, options);
  }

  getFiltersOfCard(cardId, options = {}) {
    return createGetRequest(`catalog/${cardId}/filters`, this._language, undefined, options);
  }

  get(cardId) {
    return createGetRequest(`catalog/${cardId}`, this._language);
  }

  add(menuId, payload) {
    const searchParams = new URLSearchParams({
      menuId,
    });
    return createPostRequest(`catalog?${searchParams}`, JSON.stringify(payload), this._language);
  }

  update(cardId, payload) {
    return createPutRequest(`catalog/${cardId}`, JSON.stringify(payload), this._language);
  }

  remove(cardId) {
    return createDeleteRequest(`catalog/${cardId}`, this._language);
  }

  changeOrder(menuId, cardIds) {
    const searchParams = new URLSearchParams({
      menuId,
    });
    return createPutRequest(`catalog/xd/order?${searchParams}`, JSON.stringify({ids: cardIds}), this._language);
  }
}