import React, { useCallback, useEffect, useState } from "react";
import MenuListAdminComponent from "./MenuListAdminComponent";
import MenuItemEditDefComponent from "./editor/MenuItemEditDefComponent";
import ModalEditComponent from "../../modal/ModalEditComponent";
import { FaPlus  } from "react-icons/fa";
import { useAdminApiContext } from '../../../hooks/admin/context';
import { useI18nContext } from "../../../i18n/context/context";
import LoaderDirectiveComponent from "../../../directives/LoaderDirectiveComponent";
import { Loader } from 'rsuite';

function MenuItemsListAdminComponent({ restaurantId, extraData, userId, setRunningToken }) {
  const { translate, language } = useI18nContext();
  const {
    state: { selectedCard, selectedCategory, dishes = [], isLoading = false, runningToken } = {},
    fetchDishes,
    addDish
  } = useAdminApiContext();

  const newItemDef = {
    "dishName": "",
    "shortDescription": "",
    "category": "",
    "description": "",
    "dishItems": [],
    "allergens": [],
    "priceType": "pricesOneVariant",
    "priceVariants": [],
    "prices": [],
    "isLactoseFree": false,
    "isVegetarian": false,
    "isGlutenFree": false,
    "isVegan": false,
    "isActive": true,
    "urlFirstPhoto": undefined,
    "urlRoundPhotos": "",
    "restaurantCode": ""
  }

  useEffect(() => {
    if(!!selectedCategory){
      fetchDishes(selectedCategory.id)
    }
  }, [selectedCategory.id])

  useEffect(() => {
    setRunningToken(runningToken)
  }, [runningToken])


  const [copyItem, setCopyItem] = useState(null)
  
  const setCopyModalOpen = (val) => {
    if(!val){
      setCopyItem(null)
    }
  }

  const copy = (item) => {
    let newCopyItem = {...item}
    newCopyItem.id = undefined;
    newCopyItem.dishName.id = undefined;
    if(newCopyItem.description){
      newCopyItem.description.id = undefined;
    }
    newCopyItem.priceVariants?.forEach(x => {
      x.id = undefined;
      x.prices?.forEach(y => {
        y.id = undefined;
      })
    })
    newCopyItem.prices?.forEach(y => {
      y.id = undefined;
    })

    setCopyItem(newCopyItem)
  }

  const [isNewItem, setIsNewItem] = useState(false)

  const addItem = useCallback((item) => {
    setIsNewItem(false)
    if(!!item.dishName){
      addDish(selectedCard.id, selectedCategory.id, item, userId, restaurantId);
    }
  }, [selectedCard, selectedCategory])

  const setItemsChanges = useCallback(() => {
    if(!!selectedCategory){
      fetchDishes(selectedCategory.id)
    }
  }, [selectedCategory?.id])

  if (!dishes) {
    return <div><Loader size="lg" className='dropzone-icon' /></div>;
  }

  return (
    <div className="margin-top-xl padding-left-md padding-right-sm admin-dish-list">
      <div>
        <MenuListAdminComponent
          restaurantId={restaurantId}
          items={dishes}
          copy={copy}
          setItemsChanges={setItemsChanges}
          extraData={extraData}
          userId={userId}
          setRunningToken={setRunningToken}
        />
      </div>
      {isNewItem &&
        <div>
          <ModalEditComponent
            setOpen={setIsNewItem}
            hideDeleteButton
            setModelChanges={addItem}
            hideLanguages
            title={translate("creationDish")}
          >
            <MenuItemEditDefComponent
              validModel={dishes.map(x => x.dishName.items.find(y => y.lang == language).text)}
              model={newItemDef}
              extraData={extraData}
            />
          </ModalEditComponent>
        </div>
      }
      {copyItem &&
        <div>
          <ModalEditComponent
            setOpen={setCopyModalOpen}
            hideDeleteButton
            setModelChanges={addItem}
            hideLanguages
            title={translate("copyDish")}
          >
            <MenuItemEditDefComponent 
              validModel={dishes.map(x => x.dishName.items.find(y => y.lang == language).text)}
              model={copyItem}
              extraData={extraData}
            />
          </ModalEditComponent>
        </div>
      }
      {isLoading &&
        // <Loader size="lg" className='dropzone-icon' />
        <LoaderDirectiveComponent />
      }
      {extraData.defaultLang == language && 
        <div className="add-item-admin-container" onClick={() => setIsNewItem(true)} >
          <FaPlus size={50} className="add-item-admin"/>
        </div>
      }
      
    </div>
  );
}

export default MenuItemsListAdminComponent;
