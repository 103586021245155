import React, { useState, useMemo } from "react";
import MenuItemComponent from "./MenuItemComponent";
import ModalEditComponent from "../../modal/ModalEditComponent";
import MenuItemEditDefComponent from "./editor/MenuItemEditDefComponent";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAdminApiContext } from '../../../hooks/admin/context';
import LoaderDirectiveComponent from "../../../directives/LoaderDirectiveComponent";
import { Sort } from '../../../helpers/arrays/sorting';
import { useI18nContext } from "../../../i18n/context/context";


function MenuListAdminComponent({ items, extraData, setItemsChanges, copy }) {
  const { translate, language, languageList } = useI18nContext();
  const [editItemOldValue, setEditItemOldValue] = useState(null)
  const {
    state: {selectedCategory},
    removeDish,
    updateDish,
    changeDishesOrder,
  } = useAdminApiContext();

  const editItem = (dish) => {
    setEditItemOldValue(dish);
  }

  const [isSavingOrder, setIsSavingOrder] = useState(false)
  
  const deleteItem = () => {
    removeDish(editItemOldValue.id);
    setEditItemOldValue(null);
  }
  const saveEditItem = (item) => {
    updateDish(editItemOldValue.id, item);
    setEditItemOldValue(null);
  }

  const setOpenEdit = (val) => {
    setEditItemOldValue(null);
  }

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const myItems = Array.from(items);
    const [reorderedItem] = myItems.splice(result.source.index, 1);
    myItems.splice(result.destination.index, 0, reorderedItem);
    // setOrders(myItems)
    await changeOrder(myItems.map(({id}) => id))
  }

  const changeOrder = async (items) => {
    setIsSavingOrder(true)
    await changeDishesOrder(selectedCategory.id, items);
    setIsSavingOrder(false)
  };

  const sortedItems = useMemo(() => items.sort((a, b) => Sort.numericAsc(a.order, b.order)), [items]);

  return (
    <>
      {isSavingOrder && 
        <LoaderDirectiveComponent />
      }
      {!!items?.length &&
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="items">
            {(provided) => (
              <div className="admin-items-list" {...provided.droppableProps} ref={provided.innerRef}>
                {sortedItems.map((dish, idx) => (
                  <Draggable key={dish.id} draggableId={dish.id} index={idx} isDragDisabled={extraData.defaultLang != language}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <MenuItemComponent
                          dish={dish}
                          editItem={() => editItem(dish)}
                          copy={copy}
                          extraData={extraData}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      }

      <div>
        {editItemOldValue && 
              <ModalEditComponent
                setOpen={setOpenEdit}
                model={editItemOldValue}
                extraData={extraData}
                deleteItem={deleteItem}
                validModel={items.filter(x => x.dishName.id != editItemOldValue.dishName.id).map(x => x.dishName.items.find(y => y.lang == language).text)}
                setModelChanges={saveEditItem}
                hideLanguages
                title={translate("editDish")}
              >
                <MenuItemEditDefComponent />
              </ModalEditComponent>
            }
      </div>
    </>
  );
}

export default MenuListAdminComponent;
