import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import { Input, Button, useToaster } from 'rsuite';
import { useI18nContext } from "../../i18n/context/context";
import { ApiLibrary } from "../../helpers/api/ApiLibrary";
import LoaderDirectiveComponent from "../../directives/LoaderDirectiveComponent";
import LogoBackComponent from "../../components/base/top/LogoBackComponent";
import LanguageComponent from "../../components/base/top/LanguageComponent";
import ActivationModalComponent from "../../components/modal/ActivationModalComponent";
import DialogModalComponent from "../../components/modal/DialogModalComponent";

function LoginPage({ }) {
  const { translate, language } = useI18nContext();
  const toaster = useToaster();
  const [model, setModel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [wrongMailOrPassword, setWrongMailOrPassword] = useState(null)
  const [userId, setUserId] = useState(null)
  const [cookies, setCookie, deleteCookie] = useCookies(['sefooToken']);

  useEffect(() => {
    const verify = async () => {
      try {
        const loginProblem = cookies['sefooLoginProblem'];
        if(!!loginProblem){
          deleteCookie('sefooToken', { path: '/' });
          return;
        }
        const myCookie = cookies['sefooToken'];
        
        if(!!myCookie?.token){
          setIsLoading(true)
          const tokenResponse = await new ApiLibrary().user.users.verifyToken(myCookie?.userId, myCookie?.token);
          setIsLoading(false)
          if (tokenResponse?.data?.responseValue) {
            window.location.href = window.location.origin.concat('/user/').concat(myCookie.userId);
          }
          else{
            deleteCookie('sefooToken', { path: '/' });   
          }
        }        
      }
      catch(e){
        deleteCookie('sefooToken', { path: '/' });
      }
    }
    
    verify()

    setModel({
      mail: null,
      password: null
    })
  }, [])

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await new ApiLibrary(language).user.users.login(model, language);
    setIsLoading(false);
    if (response?.data?.responseCode == 'ok' && !!response?.data?.token && !!response?.data?.userId) {
      setCookie('sefooToken', JSON.stringify({token: response?.data?.token, userId: response?.data?.userId, appVersion: response?.data?.appVersion}), {
        maxAge: 60*60*24
      });
      deleteCookie('sefooLoginProblem', { path: '/' });
      window.location.href = window.location.origin.concat('/user/').concat(response?.data?.userId);
    }
    if(response?.data?.responseCode == 'wrongMailOrPassword'){
      setWrongMailOrPassword(translate(response?.data?.responseCode))
    }
    if(response?.data?.responseCode == 'userNotActivated' && !!response?.data?.userId){
      setUserId(response?.data?.userId)
    }
  }
  
  const answereActivationUser = (val) => {
    setUserId(null)
    if(val){
      handleSubmit();
    }
  }

  const keyDownFunction = useCallback((e) => {
    if(e.code == "Enter" && !!model?.mail && !!model?.password && !userId && !wrongMailOrPassword && !isLoading){
      handleSubmit()
    }
  },[model, userId, wrongMailOrPassword, isLoading])

  useEffect(() => {
    document.addEventListener("keydown", keyDownFunction, false);
    return () => {
      document.removeEventListener("keydown", keyDownFunction, false);
    }
  }, [model, userId, wrongMailOrPassword, isLoading])

  return (
    <>
        {isLoading &&
          <LoaderDirectiveComponent />
        }
        {!!userId &&
          <ActivationModalComponent userId={userId} blockCloseOutside={true} onCloseWithAnswere={answereActivationUser} open={!!userId} text={translate("activationUserInfo")} isActivation={true}/>
        }
        {!!wrongMailOrPassword &&
          <DialogModalComponent blockCloseOutside open={!!wrongMailOrPassword} onClose={() => setWrongMailOrPassword(null)} text={wrongMailOrPassword} />
        }
        <div>
            <div className="col-12 margin-bottom-sx header-flex-style main-header-style">
              <div className="margin-top-sx" >
                <LogoBackComponent />
              </div>
              <div className="display-flex">
              </div>
              <LanguageComponent />
            </div>
          </div>
          <div className="admin-header-underline" />
          <div className="user-login-register-title">
            {translate('login')}
          </div>
        <div className="admin-user-container">
          {!!model && 
          <div id="registerContainer" className="admin-basic-data-inside-container">
          <div className="regiter-label-value">
              <div className="admin-basic-label  ">
                  {translate("mail")}
              </div>
              <div className="admin-basic-edit-item-field-text  ">
              <Input id="mail" className={` admin-basic-edit-item-field-text  ${!!model?.mail ? "sd-selected" : ""}`}
                  placeholder={translate("mail")}
                  defaultValue={model?.mail}
                  onChange={(val, e) => {
                      let thisModel = {...model};
                      thisModel.mail = val;
                      setModel(thisModel);
                  }}
                  />
              </div>
          </div>
          <div className="regiter-label-value margin-top-xl">
              <div className="admin-basic-label  ">
                  {translate("password")}
              </div>
              <div className="admin-basic-edit-item-field-text  ">
                  <Input id="password" type="password" className={` admin-basic-edit-item-field-text  ${!!model?.password ? "sd-selected" : ""}`}
                      placeholder={translate("password")}
                      defaultValue={model?.password}
                      onChange={(val, e) => {
                          let thisModel = {...model};
                          thisModel.password = val;
                          setModel(thisModel);
                      }}
                  />
              </div>
          </div>
          </div>
          }
            
            <div className='login-button-container'>
              <div className="login-button-container-inside">
                <Button
                    color="green"
                    appearance="primary"
                    onClick={() => handleSubmit()}
                    disabled={!model?.mail || !model?.password}
                    >
                    {translate('logIn')}
                </Button>
                <div className="login-register-container">
                  <div className="no-remember-password-button" onClick={() => window.location.href = window.location.origin.concat('/resetPassword')}>
                    {translate('noRememberPassword')}
                  </div>
                  <div className="register-button" onClick={() => window.location.href = window.location.origin.concat('/register')}>
                    {translate('createAccount')}
                  </div>
                </div>
              </div>
            <div className="register-button-under" onClick={() => window.location.href = window.location.origin.concat('/register')}>
                {translate('createAccount')}
              </div>
            </div>
        </div>
    </>    
  );
}

export default LoginPage;
