import { useMemo } from 'react';


export const useOpeningHours = (openCloseInfo) => {
  const info = useMemo(() => {
    const weekDays = [
      "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"
    ];
    const weekEngDays = [
      "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"
    ];
    let result = {
      hours: [],
      openNow: false
    };
    if (!!openCloseInfo) {
      let prevKey = "";
      weekDays.forEach((item, index) => {
        if (!!openCloseInfo[item]?.open || !!openCloseInfo[item]?.close) {
          let time = openCloseInfo[item].open.concat(" - ").concat(openCloseInfo[item].close);

          if (result.hours.length == 0 || prevKey != time) {
            let res = { time, days: [] };
            res["days"].push(item);
            result.hours.push(res);
          }
          else {
            result.hours[result.hours.length - 1]["days"].push(item);
          }
          prevKey = time;
        }
      });

      let date = new Date();
      let day = weekEngDays[date.getDay()];
      let hourOpen = openCloseInfo[day]?.open.split(':') ?? [];
      let hourClose = openCloseInfo[day]?.close.split(':') ?? [];
      let dateTimeForOpenRestaurant = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hourOpen[0], hourOpen[1], "00");
      let dateTimeForCloseRestaurant = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hourClose[0], hourClose[1], "00");
      result.openNow = date >= dateTimeForOpenRestaurant && date <= dateTimeForCloseRestaurant;
    }
    return result;
  }, [openCloseInfo]);

  return info;
};
