
import { useState, useEffect, useCallback, useRef} from "react";
import { Input } from 'rsuite';

function SelectOptionsTextComponent({options, selectedOptions, onChange, listRef, showSearch}){

  const [hoverIdx, setHoverIdx] = useState(-1)
  const [search, setSearch] = useState(null)
  const searchRef = useRef(null)

  const keyDownFunction = useCallback((e) => {
    if(e.code == "ArrowDown"){
      e.preventDefault();
      if(hoverIdx >= options.length - 1){
        setHoverIdx(0)
      }
      else{
        setHoverIdx(hoverIdx+1)
      }
    }
    if(e.code == "ArrowUp"){
      e.preventDefault();
      if(hoverIdx == 0){
        setHoverIdx(options.length - 1)
      }
      else{
        setHoverIdx(hoverIdx-1)
      }
    }
    if(e.code == "Enter" && hoverIdx >= 0){
      onChange(options[hoverIdx])
    }
  }, [options, hoverIdx])

  useEffect(() => {
    document.addEventListener("keydown", keyDownFunction, false);
    return () => {
      document.removeEventListener("keydown", keyDownFunction, false);
    }
  }, [hoverIdx, setHoverIdx])

    return (
      <div ref={listRef} className="msd-list-items">
        {!!showSearch && options.length > 9 &&
        <Input ref={searchRef} onMouseDown={(e) => { e.preventDefault(); e.stopPropagation(); searchRef?.current?.focus()}} className={` admin-basic-edit-item-field-text`}
                      onChange={(val, e) => {
                        setSearch(val)
                      }}
                    />
        }
        {!!options && options.filter(x => !search || x.text.toLowerCase().includes(search.toLowerCase())).map((item, idx) => (
            <div key={idx} className={`msd-item ${!!selectedOptions && selectedOptions.includes(item) ? "msd-item-selected" : ""} ${hoverIdx == idx ? "msd-item-hover" : ""} `} 
              onMouseDown={(e) => onChange(item)}
              onMouseOver={(e) => setHoverIdx(idx)}>{item.text}</div>
        ))}
      </div>
    )
}

export default SelectOptionsTextComponent;