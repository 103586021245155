import "bootstrap/dist/css/bootstrap.min.css";
import React, { useCallback, useMemo } from "react";
import "rsuite/dist/rsuite.min.css";
import { Dropdown } from "rsuite";
import { useI18nContext } from '../../../i18n/context/context';

function LanguageComponent({defLang, langs, reload=false}) {
  const { setLanguage, language, languageList, translate } = useI18nContext();

  const activeTitle = useMemo(() => (
    languageList.map(x => {
      if(!!defLang && defLang != x.value){
        return {
          ...x,
          label: `${translate("translate", defLang)}: ${translate(x.value, defLang)}`
        }
      }
      return {...x}
    }).find(({ value }) => value === language)?.label
  ), [languageList, language, defLang])

  const changeLanguage = useCallback((value) => {
    setLanguage(value, reload);
    // window.location.reload(false);
  }, []);

  return (
    <div>
      <Dropdown
        activeKey={language}
        title={activeTitle}
        placement="bottomEnd"
        className="header-right-style"
      >
        {languageList.filter(x => x.value == defLang || !langs || langs.includes(x.value)).map((item, key) => (
          <Dropdown.Item
            key={key}
            onSelect={changeLanguage}
            eventKey={item.value}
          >
            <>
              {(!defLang || defLang == item.value) && item.label}
              {(!!defLang && defLang != item.value) && 
                <>
                  {translate("translate", defLang)}: {translate(item.value, defLang)}
                </>
              }
            </>
          </Dropdown.Item> 
        ))}
      </Dropdown>
    </div>
  );
}

export default LanguageComponent;
