import React, { useCallback, useEffect, useState }  from "react";
import { useI18nContext } from "../../../i18n/context/context";
import InputHourDirectiveComponent from "../../../directives/InputHourDirectiveComponent";

function AdminBasicOpenCloseComponent({ restaurant, setChanges }) {
  const { translate, language } = useI18nContext();
  const [model, setModel] = useState({
    openCloseInfo: {
      monday: {
        open: restaurant?.openCloseInfo?.monday?.open,
        close: restaurant?.openCloseInfo?.monday?.close
      },
      tuesday: {
        open: restaurant?.openCloseInfo?.tuesday?.open,
        close: restaurant?.openCloseInfo?.tuesday?.close
      },
     wednesday: {
        open: restaurant?.openCloseInfo?.wednesday?.open,
        close: restaurant?.openCloseInfo?.wednesday?.close
      },
      thursday: {
        open: restaurant?.openCloseInfo?.thursday?.open,
        close: restaurant?.openCloseInfo?.thursday?.close
      },
      friday: {
        open: restaurant?.openCloseInfo?.friday?.open,
        close: restaurant?.openCloseInfo?.friday?.close
      },
      saturday: {
        open: restaurant?.openCloseInfo?.saturday?.open,
        close: restaurant?.openCloseInfo?.saturday?.close
      },
      sunday: {
        open: restaurant?.openCloseInfo?.sunday?.open,
        close: restaurant?.openCloseInfo?.sunday?.close
      },
    }
  })

  const onChanged = (day, oc, val) => {
    let thisModel = {...model}
    thisModel.openCloseInfo[day][oc] = val;
    // setModel(thisModel)
    let resultModel = {}
    if(!!thisModel.openCloseInfo.monday.open || !!thisModel.openCloseInfo.monday.close){
      resultModel.monday = thisModel.openCloseInfo.monday;
    }
    if(!!thisModel.openCloseInfo.tuesday.open || !!thisModel.openCloseInfo.tuesday.close){
      resultModel.tuesday = thisModel.openCloseInfo.tuesday;
    }
    if(!!thisModel.openCloseInfo.wednesday.open || !!thisModel.openCloseInfo.wednesday.close){
      resultModel.wednesday = thisModel.openCloseInfo.wednesday;
    }
    if(!!thisModel.openCloseInfo.thursday.open || !!thisModel.openCloseInfo.thursday.close){
      resultModel.thursday = thisModel.openCloseInfo.thursday;
    }
    if(!!thisModel.openCloseInfo.friday.open || !!thisModel.openCloseInfo.friday.close){
      resultModel.friday = thisModel.openCloseInfo.friday;
    }
    if(!!thisModel.openCloseInfo.saturday.open || !!thisModel.openCloseInfo.saturday.close){
      resultModel.saturday = thisModel.openCloseInfo.saturday;
    }
    if(!!thisModel.openCloseInfo.sunday.open || !!thisModel.openCloseInfo.sunday.close){
      resultModel.sunday = thisModel.openCloseInfo.sunday;
    }
    setChanges(resultModel)
  }

  return (
    <div className="admin-open-close-week">
        <div className="display-flex">
          <div className="admin-open-close-day">
            {translate("monday")}
          </div>
          <div className="admin-open-close-hours">
            <InputHourDirectiveComponent value={model.openCloseInfo?.monday?.open} setValue={(val) => onChanged("monday", "open", val)} /><span className="admin-open-close-hours-separator"> - </span><InputHourDirectiveComponent value={model.openCloseInfo?.monday?.close} setValue={(val) => onChanged("monday", "close", val)} />
          </div>
        </div>
        <div className="display-flex">
          <div className="admin-open-close-day">
            {translate("tuesday")}
          </div>
          <div className="admin-open-close-hours">
            <InputHourDirectiveComponent value={model.openCloseInfo?.tuesday?.open} setValue={(val) => onChanged("tuesday", "open", val)} /><span className="admin-open-close-hours-separator"> - </span><InputHourDirectiveComponent value={model.openCloseInfo?.tuesday?.close} setValue={(val) => onChanged("tuesday", "close", val)} />
          </div>
        </div>
        <div className="display-flex">
          <div className="admin-open-close-day">
            {translate("wednesday")}
          </div>
          <div className="admin-open-close-hours">
            <InputHourDirectiveComponent value={model.openCloseInfo?.wednesday?.open} setValue={(val) => onChanged("wednesday", "open", val)} /><span className="admin-open-close-hours-separator"> - </span><InputHourDirectiveComponent value={model.openCloseInfo?.wednesday?.close} setValue={(val) => onChanged("wednesday", "close", val)} />
          </div>
        </div>
        <div className="display-flex">
          <div className="admin-open-close-day">
            {translate("thursday")}
          </div>
          <div className="admin-open-close-hours">
            <InputHourDirectiveComponent value={model.openCloseInfo?.thursday?.open} setValue={(val) => onChanged("thursday", "open", val)} /><span className="admin-open-close-hours-separator"> - </span><InputHourDirectiveComponent value={model.openCloseInfo?.thursday?.close} setValue={(val) => onChanged("thursday", "close", val)} />
          </div>
        </div>
        <div className="display-flex">
          <div className="admin-open-close-day">
            {translate("friday")}
          </div>
          <div className="admin-open-close-hours">
            <InputHourDirectiveComponent value={model.openCloseInfo?.friday?.open} setValue={(val) => onChanged("friday", "open", val)} /><span className="admin-open-close-hours-separator"> - </span><InputHourDirectiveComponent value={model.openCloseInfo?.friday?.close} setValue={(val) => onChanged("friday", "close", val)} />
          </div>
        </div>
        <div className="display-flex">
          <div className="admin-open-close-day">
            {translate("saturday")}
          </div>
          <div className="admin-open-close-hours">
            <InputHourDirectiveComponent value={model.openCloseInfo?.saturday?.open} setValue={(val) => onChanged("saturday", "open", val)} /><span className="admin-open-close-hours-separator"> - </span><InputHourDirectiveComponent value={model.openCloseInfo?.saturday?.close} setValue={(val) => onChanged("saturday", "close", val)} />
          </div>
        </div>
        <div className="display-flex">
          <div className="admin-open-close-day">
            {translate("sunday")}
          </div>
          <div className="admin-open-close-hours">
            <InputHourDirectiveComponent value={model.openCloseInfo?.sunday?.open} setValue={(val) => onChanged("sunday", "open", val)} /><span className="admin-open-close-hours-separator"> - </span><InputHourDirectiveComponent value={model.openCloseInfo?.sunday?.close} setValue={(val) => onChanged("sunday", "close", val)} />
          </div>
        </div>
    </div>
  );
}

export default AdminBasicOpenCloseComponent;