import "../../App.css";
import "../../css/dish.css";
import "bootstrap/dist/css/bootstrap.min.css";
import HomeRestaurantPanel from "./HomeRestaurantPanel";
import { useEffect } from "react";

function HomeRestaurantPanelContainer({ OpenModal, restaurantCode, setSelectedItem }) {

  const isTouchable = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  };

  useEffect(() => {
    if(isTouchable()){
      // window.history.pushState({}, '', window.location.href);
      // const handlePopState = (event) => {
      //   setSelectedItem(null);
      //   window.history.replaceState({}, '', window.location.href);
      // }; 
      // window.addEventListener('popstate', handlePopState); 
      // return () => { window.removeEventListener('popstate', handlePopState); };
    }
  }, [])

  return (
    <div className="home-restaurant-panel-container">
      {restaurantCode && <HomeRestaurantPanel OpenModal={OpenModal} restaurantCode={restaurantCode} />}
    </div>
  );
}

export default HomeRestaurantPanelContainer;
