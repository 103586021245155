import React, { useCallback, useEffect, useState } from "react";
import MenuListAdminComponent from "./MenuListAdminComponent";
import MenuItemEditDefComponent from "./editor/MenuItemEditDefComponent";
import ModalEditComponent from "../../modal/ModalEditComponent";
import { FaPlus  } from "react-icons/fa";
import { useAdminApiContext } from '../../../hooks/admin/context';
import { useI18nContext } from "../../../i18n/context/context";
import LoaderDirectiveComponent from "../../../directives/LoaderDirectiveComponent";
import { Loader } from 'rsuite';

function MenuItemsListAdminComponent({ extraData, copy }) {
  const { translate, language, languageList } = useI18nContext();
  const {
    state: { selectedCard, selectedCategory, dishes = [], isLoading = false } = {},
    fetchDishes,
    addDish
  } = useAdminApiContext();

  const newItemDef = {
    "dishName": "",
    "shortDescription": "",
    "category": "",
    "description": "",
    "dishItems": [],
    "allergens": [],
    "priceType": "pricesOneVariant",
    "priceVariants": [],
    "prices": [],
    "isLactoseFree": false,
    "isVegetarian": false,
    "isGlutenFree": false,
    "isVegan": false,
    "isActive": true,
    "urlFirstPhoto": undefined,
    "urlRoundPhotos": "",
    "restaurantCode": ""
  }

  useEffect(() => {
    if(!!selectedCategory){
      fetchDishes(selectedCategory.id)
    }
  }, [selectedCategory.id])

  const [isNewItem, setIsNewItem] = useState(false)
  
  const addItem = useCallback((item) => {
    setIsNewItem(false)
    if(!!item.dishName){
      addDish(selectedCard.id, selectedCategory.id, item);
    }
  }, [selectedCard, selectedCategory])

  const setItemsChanges = useCallback(() => {
    if(!!selectedCategory){
      fetchDishes(selectedCategory.id)
    }
  }, [selectedCategory?.id])

  if (!dishes) {
    return <div><Loader size="lg" className='dropzone-icon' /></div>;
  }

  return (
    <div className="margin-top-xl padding-left-md admin-dish-list">
      <div>
        <MenuListAdminComponent
          items={dishes}
          setItemsChanges={setItemsChanges}
          extraData={extraData}
          copy={copy}
        />
      </div>
      {isNewItem &&
        <div>
          <ModalEditComponent
            setOpen={setIsNewItem}
            validModel={dishes.map(x => x.dishName.items.find(y => y.lang == language).text)}
            model={newItemDef}
            extraData={extraData}
            hideDeleteButton
            setModelChanges={addItem}
            hideLanguages
            title={translate("creationDish")}
          >
            <MenuItemEditDefComponent />
          </ModalEditComponent>
        </div>
      }
      {isLoading &&
        // <Loader size="lg" className='dropzone-icon' />
        <LoaderDirectiveComponent />
      }
      {extraData.defaultLang == language && 
        <div className="add-item-admin-container" onClick={() => setIsNewItem(true)} >
          <FaPlus size={50} className="add-item-admin"/>
        </div>
      }
      
    </div>
  );
}

export default MenuItemsListAdminComponent;
