import React, { useState }  from "react";
import { useI18nContext } from "../../../i18n/context/context";
import AdminBasicOpenCloseComponent from "./AdminBasicOpenCloseComponent";
import { Input, InputGroup } from 'rsuite';
import { IoClose } from "react-icons/io5";
import { HiTranslate } from "react-icons/hi";
import { useToaster, Message, Toggle } from 'rsuite';
import LoaderDirectiveComponent from "../../../directives/LoaderDirectiveComponent";
import DictionaryEditModalComponent from "../DictionaryEditModalComponent";
import ModalEditComponent from "../../modal/ModalEditComponent";
import { ApiLibrary } from "../../../helpers/api/ApiLibrary";

function AdminBasicOpenCloseListComponent({ model, defLang, setChanges, setText }) {
  const { translate, language } = useI18nContext();
  const [dictionaryItem, setDictionaryItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toaster = useToaster();

  const changeDictionary = async (dictionaryItem, val) => {
      dictionaryItem.text = val;
      setIsLoading(true)
      let result = await new ApiLibrary(language).dictionary.change(dictionaryItem)
      setIsLoading(false)
      dictionaryItem =result.data;
      toaster.push(
        <Message showIcon type="success" closable>
          {translate("changedTranslation")}
        </Message>, 
        { placement: "topEnd", duration: 5000 }
      )
    }

  return (
    <div className="">
      {isLoading &&
                <LoaderDirectiveComponent />
              }
      {!!dictionaryItem &&
          <ModalEditComponent
            setOpen={() => setDictionaryItem(null)}
            setModelChanges={(val) => changeDictionary(dictionaryItem, val)}        
            hideLanguages
            isSimpleModel
            hideDeleteButton
            title={translate("translate")}
        >
          <DictionaryEditModalComponent model={dictionaryItem.text} />
        </ModalEditComponent>
        }
      {[...model, {dictionary: {items: [{text: "", lang: language}]}}].map((x, idx) => {
        return (
          <span key={idx}>
            {defLang != language && !!x.dictionary?.items?.find(y => y.lang == language)?.text &&
              <span>
              {x.dictionary?.items?.find(y => y.lang == language)?.text}
                                  <span className="translate-button" onClick={() => setDictionaryItem(x.dictionary.items.find(y => y.lang == language))}>
                                    <HiTranslate size={16} />
                                  </span>
              </span>
            }
            {defLang == language &&
              <InputGroup key={idx} className={`edit-item-field-text ${!!x.dictionary?.items?.find(y => y.lang == language)?.text ? "sd-selected" : ""}`}>
                                    <Input
                                      placeholder={translate("openHoursName")}
                                      value={x.dictionary?.items?.find(y => y.lang == language)?.text} 
                                      onChange={(val, e) => setText(idx, val)} />
                                    <InputGroup.Addon className="input-number-clear">                        
                                      {!!x.dictionary?.items?.find(y => y.lang == language)?.text && 
                                        <IoClose className="sd-clear-button"
                                          onClick={(e) => {
                                            setText(idx, '');
                                          }} 
                                      />}
                                    </InputGroup.Addon>
                                  </InputGroup>
            }
            {!!x.dictionary?.items?.find(y => y.lang == defLang)?.text &&
              <div className={`${defLang != language ? "disabled-style" : ""}`}>
                <AdminBasicOpenCloseComponent paramsWatcher restaurant={x} setChanges={(val) => setChanges(idx, val)}/>
              </div>
            }
          </span>
        )
      })}
    </div>
  );
}

export default AdminBasicOpenCloseListComponent;