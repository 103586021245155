import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import { useI18nContext } from "../../i18n/context/context";
import { ApiLibrary } from "../../helpers/api/ApiLibrary";
import { FaPencil } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import colors from '../../datas/colors.json';
import logos from '../../datas/logo.json';
import LogoBackComponent from "../../components/base/top/LogoBackComponent";
import LanguageComponent from "../../components/base/top/LanguageComponent";
import ModalEditComponent from "../../components/modal/ModalEditComponent";
import RestaurantEditForUserComponent from "../../components/admin/user/RestaurantEditForUserComponent";
import ProfileComponent from "../../components/base/top/ProfileComponent";
import LoaderDirectiveComponent from "../../directives/LoaderDirectiveComponent";
import configLanguage from '../../datas/configLanguage.json'
import { FaChevronRight, FaPlus } from "react-icons/fa";
import { MdQueryStats } from "react-icons/md";
import { useToaster, Message } from 'rsuite';

function UserConfigurationPage({ setRunningToken, runningToken }) {
  const { translate, language, languageList } = useI18nContext();
  const toaster = useToaster();
  const [model, setModel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewItem, setIsNewItem] = useState(false);
  const [editRestaurant, setEditRestaurant] = useState(null);
  const { userId } = useParams();
  const [cookies, setCookie, deleteCookie] = useCookies(['sefooToken']);

  const restaurant = {
    restaurantName: null,
    defaultLang: language,
    restaurantType: {text: ''},
    address: {
        country: "Polska",
        city: null,
        street: null,
        streetNumber: null,
        houseNumber: null
    },
    location: {
      x: null,
      y: null
    },
    extraConfig: {
      defString: translate("default"),
      defUrl: window.location.origin,
      colorCode: colors.sefoo.firstColor,
      color: 'sefoo',
      logoUrl: window.location.origin.includes('https://sefoo.') ? logos.emptyUrl.replace('{sefooColor}', colors.sefoo.firstColor.replace('#', '')) : logos.testEmptyUrl.replace('{sefooColor}', colors.sefoo.firstColor.replace('#', '')),
      currencies: [configLanguage[language]?.defaultCurrency]
    },
    allLangs: languageList.map(x => x.value)
  }

  const getData = async () => {
    const myCookie = cookies['sefooToken'];
    if(!myCookie?.token){
      window.location.href = window.location.origin.concat("/login");
      return;
    }
    setIsLoading(true);
    let response = await new ApiLibrary(language).user.users.getConfigurationData(userId, myCookie?.token);
    setIsLoading(false);
    if(!!response?.data){
      setModel(response?.data)
      deleteCookie('sefooLoginProblem', { path: '/' });
    }else{
      setCookie('sefooLoginProblem', "true", {
        path: '/',
        maxAge: 60*60*24
      });
      deleteCookie('sefooToken', { path: '/' });
      window.location.href = window.location.origin.concat("/login");
    }
  }

  useEffect(() => {
    getData()
  }, [userId, language]);

  const openAddRestaurant = () => {
    setIsNewItem(true)
  }

  const goIntoRestaurantAdmin = (restaurant) => {
    window.location.href = window.location.origin.concat('/user/').concat(userId).concat("/restaurant/").concat(restaurant.restaurantCode);
  }

  const goIntoTracking = (restaurant) => {
    window.location.href = window.location.origin.concat('/tracking/').concat(restaurant.restaurantCode);
  }

  const editBasic = (restaurant) => {
    setEditRestaurant(restaurant)
  }

  const saveEditBasic = async (thisModel) => {
    setIsLoading(true);
    thisModel.userId = userId;
    thisModel.allLangs = languageList.map(x => x.value);
    const response = await new ApiLibrary(language).restaurants.general.update(thisModel.id, thisModel, language);
    setIsLoading(false);
    toaster.push(
      <Message showIcon type="success" closable>
        {translate("saveBasicDataRestaurantSuccess")}
      </Message>, 
      { placement: "topEnd", duration: 5000 }
    )
    await getData()
  }

  const deleteRestaurant = async (thisModel) => {
    setIsLoading(true);
    thisModel.isDeleted = true;
    thisModel.userId = userId;
    const response = await new ApiLibrary(language).restaurants.general.update(thisModel.id, thisModel, language);
    setIsLoading(false);
    toaster.push(
      <Message showIcon type="success" closable>
        {translate("deleteBasicDataRestaurantSuccess")}
      </Message>, 
      { placement: "topEnd", duration: 5000 }
    )
    await getData()
  }

  const addRestuarant = async (thisModel) => {
    setIsLoading(true);
    thisModel.userId = userId;
    const responseToken = await new ApiLibrary(language).restaurants.general.generateRunningToken({userId}, language);
    setRunningToken(responseToken?.data)
    const response = await new ApiLibrary(language).restaurants.general.createNewRestaurant({...thisModel, runningToken: responseToken?.data}, language);
    await new ApiLibrary(language).restaurants.general.deleteRunningToken({runningToken: responseToken?.data}, language);
    setRunningToken(null)
    setIsLoading(false);
    toaster.push(
      <Message showIcon type="success" closable>
        {translate("addRestaurantSuccess")}
      </Message>, 
      { placement: "topEnd", duration: 5000 }
    )
    await getData()
  }

  return (
    <>
        {isLoading &&
          <LoaderDirectiveComponent />
        }
        {!!editRestaurant &&
          <div>
              <ModalEditComponent
                setOpen={(val) => setEditRestaurant(null)}
                setModelChanges={saveEditBasic}
                deleteItem={deleteRestaurant}
                hideLanguages
                title={translate("editRestaurant")}
              >
                <RestaurantEditForUserComponent model={editRestaurant} />
              </ModalEditComponent>
            </div>
        }
        {isNewItem &&
        <div>
          <ModalEditComponent
            setOpen={setIsNewItem}
            setModelChanges={addRestuarant}
            hideDeleteButton
            hideLanguages
            title={translate("creationRestaurant")}
          >
            <RestaurantEditForUserComponent model={restaurant} />
          </ModalEditComponent>
        </div>
      }
        <div>
            <div className="col-12 margin-bottom-sx header-flex-style main-header-style">
              <div className="margin-top-sx" >
                <LogoBackComponent />
              </div>
              <div className="display-flex">
                {/* <TimerComponent /> */}
                <LanguageComponent />
                <ProfileComponent />
              </div>
            </div>
          </div>
          <div className="admin-header-underline" />
          {!!model &&
            <div className="admin-user-details-container">
              <div className="admin-basic-data-inside-container">
              <div className="restaurant-title">
                  {translate('restaurants')}
                </div>
                {model?.restaurantsData?.map((restaurant, idx) => (
                  <div className="admin-user-details-restaurant" key={idx}>
                    <div className="admin-user-details-restaurant-details">
                      <div className="admin-user-details-restaurant-text">
                          {restaurant.restaurantName}
                      </div>
                      <div className="restaurant-description-style margin-top-sx">
                        {!!restaurant.defaultLang && (
                          <span className="restaurant-label">
                            {translate("defaultLanguage")}
                          </span>
                        )}
                        {!!restaurant.defaultLang && (
                          <span className="restaurant-info-value">{translate(restaurant.defaultLang)}</span>
                        )}
                      </div>
                      <div className="restaurant-description-style">
                        {!!restaurant.restaurantType && (
                          <span className="restaurant-label">
                            {translate("restaurantType")}
                          </span>
                        )}
                        {!!restaurant.restaurantType && (
                          <span className="restaurant-info-value">{restaurant.restaurantType?.items?.filter(x => x.lang == language && (!x.restaurantId || x.restaurantId == restaurant.id))?.sort((a, b) => { if (a?.restaurantId === null) return 1; if (b?.restaurantId === null) return -1; return a - b; })[0]?.text}</span>
                        )}
                      </div>
                      <div className="restaurant-description-style">
                        {!!restaurant.address && (
                          <span className="restaurant-label">{translate("address")}</span>
                        )}
                        {!!restaurant.address && (
                          <span className="restaurant-info-value">
                            {restaurant.address.fullAddress}
                          </span>
                        )}
                      </div>
                      <div className="restaurant-description-style">
                        {!!restaurant.restaurantCode && (
                          <span className="restaurant-label">
                            {translate("sefooUrl")}
                          </span>
                        )}
                        {!!restaurant.restaurantCode && (
                          <span className="restaurant-info-value">{window.location.origin.concat('/').concat(restaurant.restaurantCode)}</span>
                        )}
                      </div>
                    </div>
                    <div className="admin-user-details-restaurant-edit-container">
                      <div className="admin-user-details-restaurant-edit-basic" onClick={() => goIntoRestaurantAdmin(restaurant)}>
                        <FaChevronRight className="admin-user-details-restaurant-edit-basic-icon" />
                      </div>
                      {restaurant.defaultLang == language && 
                        <div className="admin-user-details-restaurant-edit" onClick={() => editBasic(restaurant)}>
                          <FaPencil className="admin-user-details-restaurant-edit-icon" />
                        </div>
                      }
                      <div className="admin-user-details-restaurant-edit-basic" onClick={() => goIntoTracking(restaurant)}>
                        <MdQueryStats className="admin-user-details-restaurant-edit-basic-icon" />
                      </div>
                    </div>
                  </div>
                ))}
                {(model?.restaurantsData?.length < 1 || model?.typeOfSubscription != 0) &&
                  <div className="add-item-admin-container" onClick={() => openAddRestaurant()}>
                    <FaPlus size={50} className="add-item-admin"/>
                  </div>
                }
                
              </div>
            </div>
          }
    </>    
  );
}

export default UserConfigurationPage;
