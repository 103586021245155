// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.id-input-hour-body{
    padding: 7px 0px 7px 5px;
    border-radius: 6px;
    display: flex;
    vertical-align: middle;
    border: 1px solid var(--sefooBorderColor);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.id-input-hour-body:hover{
    border-color: var(--sefooColor) !important;
    color: var(--sefooColor) !important;
}

.id-input-hour-inside{
    width: 25px;
    text-align: right;
    border: none !important;
    outline: none !important;
    background-color: var(--backgroundColor);
}

.idh-clear{
    padding-top: 1px;
    margin-left: 7px;
    width: 20px;
    margin-right: 7px;
}

.idh-clear-button:hover{
    color: var(--sefooColor);
}

.idh-clear-button{
    cursor: pointer;
    font-size: large;
    color: var(--secondTextColor);
}

.id-input-minute-inside{
    width: 25px;
    border: none !important;
    outline: none !important;
    background-color: var(--backgroundColor);
}

.id-hour-not-empty{
    border-color: var(--sefooColor);
}

.react-time-picker__wrapper{
    border: none !important;
}

.react-time-picker__inputGroup{
    min-width: 40px !important;
}

.react-time-picker__inputGroup__input:invalid{
    background: var(--backgroundColor) !important;
}`, "",{"version":3,"sources":["webpack://./src/directives/InputHoursDirectiveStyles.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,yCAAyC;IACzC,0BAAkB;IAAlB,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,0CAA0C;IAC1C,mCAAmC;AACvC;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,uBAAuB;IACvB,wBAAwB;IACxB,wCAAwC;AAC5C;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,wBAAwB;IACxB,wCAAwC;AAC5C;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,6CAA6C;AACjD","sourcesContent":[".id-input-hour-body{\n    padding: 7px 0px 7px 5px;\n    border-radius: 6px;\n    display: flex;\n    vertical-align: middle;\n    border: 1px solid var(--sefooBorderColor);\n    width: fit-content;\n}\n\n.id-input-hour-body:hover{\n    border-color: var(--sefooColor) !important;\n    color: var(--sefooColor) !important;\n}\n\n.id-input-hour-inside{\n    width: 25px;\n    text-align: right;\n    border: none !important;\n    outline: none !important;\n    background-color: var(--backgroundColor);\n}\n\n.idh-clear{\n    padding-top: 1px;\n    margin-left: 7px;\n    width: 20px;\n    margin-right: 7px;\n}\n\n.idh-clear-button:hover{\n    color: var(--sefooColor);\n}\n\n.idh-clear-button{\n    cursor: pointer;\n    font-size: large;\n    color: var(--secondTextColor);\n}\n\n.id-input-minute-inside{\n    width: 25px;\n    border: none !important;\n    outline: none !important;\n    background-color: var(--backgroundColor);\n}\n\n.id-hour-not-empty{\n    border-color: var(--sefooColor);\n}\n\n.react-time-picker__wrapper{\n    border: none !important;\n}\n\n.react-time-picker__inputGroup{\n    min-width: 40px !important;\n}\n\n.react-time-picker__inputGroup__input:invalid{\n    background: var(--backgroundColor) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
