import React from "react";
import MultiSelectDirectiveNewComponent from "../../../../directives/MultiSelectDirectiveNewComponent";
import { useI18nContext } from '../../../../i18n/context/context';

function AllergensExcludedFilterComponent({SetFilterValue, data, values}) {
  const { translate } = useI18nContext();

  return (
      <div className="margin-bottom-md">
        <div className={`label padding-bottom-sx filter-label ${values?.length > 0 ? "selected-label" : ""}`}>
          {translate("excludeAllergensItems")}
        </div>
        <div className="col-11 filter-min-width">
          <MultiSelectDirectiveNewComponent
            placeholder={translate("select")}
            options={data}
            SetResult={(values) => SetFilterValue({
              allergens: values.map(({ value }) => value),
            })}
            defSelectedItems={values?.map((value) => ({ value, text: translate(value) })) ?? []} />
        </div>
      </div>
  );
}

export default AllergensExcludedFilterComponent;
