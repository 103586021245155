import "./ModalItem.css";
import { useState } from "react";
import { IoClose } from "react-icons/io5";
import Modal from '@mui/material/Modal';

function ModalInfoComponent({info, onClose, open}) {
  return !!info && (
    <>
      <Modal
        open={open}
        // onClose={onClose}
      >
        <div className="restaurant-start-inside">
          <div className="modal-close-button-container">
                <IoClose
                  className="modal-close-button"
                  onClick={onClose}
                />
            </div>
          <div className="card-info-style restaurant-start-inside-inside ql-editor" dangerouslySetInnerHTML={{__html: info}} />
        </div>
      </Modal>
    </>
    
  );
}

export default ModalInfoComponent;
