import { useCallback, useEffect, useState } from "react";
import NavBarCardAdminComponent from "./NavBarCardAdminComponent";
import MenusListComponent from "./MenusListComponent";
import CopyItemComponent from "./CopyItemComponent";
import ModalEditComponent from "../../modal/ModalEditComponent";
import { Dropdown, IconButton } from "rsuite";
import { FaRegCopy } from "react-icons/fa6";
import { IoMenu } from "react-icons/io5";
import { useI18nContext } from "../../../i18n/context/context";

function AdminMenuComponent({restaurantMenus, restaurantId, extraData, setMenu, userId, setRunningToken}) {
    const { translate, language } = useI18nContext();
    const [selectedItem, setSelectedItem] = useState(restaurantMenus[0])
    const [changeCounter, setChangeCounter] = useState(1)
    const [isCopyItem, setIsCopyItem] = useState(false)
    const [itemToCopy, setItemToCopy] = useState(false)

    const menuChangeCallback = (newVal) => {
        if(restaurantMenus.filter(x => x.name == selectedItem.name).length == 0){
            setSelectedItem(restaurantMenus[0])
        }

        if(!!newVal){
            setSelectedItem(restaurantMenus.filter(x => x.name == newVal)[0])
            setChangeCounter(changeCounter+1)
        }

        if(restaurantMenus.filter(x => x.isActive).length == 0){
            restaurantMenus[0].isActive = true
        }
    }

    const addNewManu = (name) => {
        let newObj = {
            isActive: false,
            name: name,
            cards: [{
                id: null,
                code: "specialEmptyCard",
                language: language,
                name: translate("specialEmptyCard"),
                filters: {
                    category: false,
                    size: false,
                    price: false,
                    includedItems: false,
                    excludedItems: false,
                    allergens: false,
                    isLactoseFree: false,
                    isGlutenFree: false,
                    isVegan: false,
                    isVegetarian: false
                },
                groups: [{
                    id: 0,
                    code: "specialEmptyGroup",
                    language: language,
                    name: translate("specialEmptyGroup"),
                    items: []
                }]
            }]
        }

        restaurantMenus.push(newObj)
        setSelectedItem(newObj)
    }


    const renderIconButton = (props, ref) => {
        return (
          <IconButton {...props} ref={ref} icon={<IoMenu />} circle appearance="primary" />
        );
      };

    return(
        <>
            <div className="display-flex">
                <div className="menu-edit-body">
                    {!!selectedItem && <NavBarCardAdminComponent restaurantMenu={selectedItem} extraData={extraData} restaurantId={restaurantId} menuId={selectedItem.id} userId={userId} setRunningToken={setRunningToken} />}
                </div>
                <div className="menu-edit-menus-container">
                    {!!selectedItem && <MenusListComponent items={restaurantMenus} selectedItemName={selectedItem.name} setSelectItem={setSelectedItem} addNewManu={addNewManu} menuChangeCallback={menuChangeCallback} />}
                </div>
            </div>
        </>
        )
}
export default AdminMenuComponent;
  