import { useI18nContext } from '../../../i18n/context/context';
import QuillEditor from '../QuillEditor';
import Container from 'rsuite/Container';
import Button from 'rsuite/Button';
import LoaderDirectiveComponent from '../../../directives/LoaderDirectiveComponent';
import { useEffect, useState } from 'react';
import { useToaster, Message } from 'rsuite';
import ToggleWithLabel from '../../ToggleWithLabel';
import { useQuillEditor } from '../useQuillEditor';
import { ApiLibrary } from '../../../helpers/api/ApiLibrary';
import TooglerDirectiveComponent from '../../../directives/TooglerDirectiveComponent';
import DropzoneDirectiveComponent from '../../../directives/DropzoneDirectiveComponent';

const PosterEditorComponent = ({restaurantId, checkChangeBookmark, setAnswere, setAskQuestion, extraData}) => {
  const { quillRef, getContent, setContent, setDefContent, quill } = useQuillEditor();
  const toaster = useToaster();
  const { language, translate } = useI18nContext();
  const [isEnabled, setEnabled] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [posterType, setPosterType] = useState("editor");
  const [media, setMedia] = useState(undefined);
  const [translationId, setTranslationId] = useState(undefined);

  const changePosterType = (val) => {
    setPosterType(val)
  }

  const check = async (setAnswere, setAskQuestion) => {
    setIsLoading(true)
    let response = await new ApiLibrary(language).restaurants.posters.get(restaurantId, language);
    setIsLoading(false)
    let actualData = getContent()
    let verifyData = !!response?.data?.content && response?.data?.content != '<p><br></p>' ? response.data.content : ""
    let veifyActualData = actualData != '<p><br></p>' ? actualData : ""
    if(verifyData != veifyActualData){
      setAskQuestion(true)  
    }
    else{
      setAnswere(true)
    }
    
  }

  useEffect(() => {
    if(checkChangeBookmark && setAskQuestion){
      check(setAnswere, setAskQuestion)
    }
  }, [checkChangeBookmark, setAskQuestion])

  const setToogleEnabled = async (val) => {
    setEnabled(val)
    setCanSave(true)
    setIsLoading(true)
      await new ApiLibrary(language).restaurants.posters.updateOrCreate(restaurantId, {
        isEnabled: val,
        content: getContent(),
        media: media
      })
      setIsLoading(false)
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await new ApiLibrary(language).restaurants.posters.get(restaurantId, language);

        if (!response.data) {
          return;
        }

        if(response.data.media){
          setPosterType("media")
          setMedia(response.data.media)
        }

        setTranslationId(response.data.translationId);
        setDefContent(response.data.content);
        setEnabled(response.data.isEnabled);
      } catch(error) {
        throw new Error('No poster available!');
      }
    }

    if (setContent) {
      loadData();
    }
  }, [setContent, language, restaurantId])

  const handleSave = async () => {
    try {
      setIsLoading(true)
      if(extraData.defaultLang == language){
        await new ApiLibrary(language).restaurants.posters.updateOrCreate(restaurantId, {
          isEnabled,
          content: getContent(),
          media: ""
        })
      }
      else{
        await new ApiLibrary(language).restaurants.general.fixTranslation({id: translationId, content: getContent()})
      }
      setIsLoading(false)
      setCanSave(false)
      setMedia("")
      setAnswere(true)
      toaster.push(
        <Message showIcon type="success" closable>
          {translate("saveChangesSuccess")}
        </Message>, 
        { placement: "topEnd", duration: 5000 }
      )
    } catch(error) {
      setIsLoading(false);
      toaster.push(
        <Message showIcon type="error" closable>
          {translate("somethingWrong")}
        </Message>, 
        { placement: "topEnd", duration: 5000 }
      )
    }
  }

  if (!restaurantId) {
    return 'No restaurant selected';
  }

  const setPhotoUrl = async ([file]) => {
    if (file) {
      setIsLoading(true);
      try {
        const response = await new ApiLibrary().common.add(file);
        if(extraData.defaultLang == language){
          await new ApiLibrary(language).restaurants.posters.updateOrCreate(restaurantId, {
            isEnabled,
            content: "",
            media: response.data?.url
          })
        }
        else{
          await new ApiLibrary(language).restaurants.general.fixTranslation({id: translationId, content: getContent()})
        }
        setContent("")
        setMedia(response.data?.url)
        setIsLoading(false);
        toaster.push(
          <Message showIcon type="success" closable>
            {translate("addMediaSuccess")}
          </Message>, 
          { placement: "topEnd", duration: 5000 }
        )
      } catch (error) {
        setIsLoading(false);
        toaster.push(
          <Message showIcon type="error" closable>
            {translate("somethingWrong")}
          </Message>, 
          { placement: "topEnd", duration: 5000 }
        )
      }
    } else {
      try{
        await new ApiLibrary().common.removeByUrl(media);
        await new ApiLibrary(language).restaurants.posters.updateOrCreate(restaurantId, {
          isEnabled,
          content: "",
          media: ""
        })
      }
      catch(e){
        setIsLoading(false);
        toaster.push(
          <Message showIcon type="error" closable>
            {translate("somethingWrong")}
          </Message>, 
          { placement: "topEnd", duration: 5000 }
        )
      }
      
      setMedia("")
      setIsLoading(false);
      toaster.push(
        <Message showIcon type="success" closable>
          {translate("removeMediaSuccess")}
        </Message>, 
        { placement: "topEnd", duration: 5000 }
      )
    }
  }

  return (
    <Container className="quill-editor-container">
      {isLoading &&
          <LoaderDirectiveComponent />
        }
      <div className='margin-top-mx'>
        <ToggleWithLabel
          label={translate("enablePoster")}
          checked={isEnabled}
          onChange={setToogleEnabled}
        />
      </div>
      
      <div className={`margin-top-mx ${!isEnabled ? "disabled-style" : ""}`}>
        <TooglerDirectiveComponent isSelectedItemColor value={posterType} setValue={changePosterType} items={[{text: translate("editor"), value: 'editor'}, {text: translate("media"), value: 'media'}]} />
      </div>

      {posterType == "media" &&
        <div className={`margin-top-mx ${!isEnabled || extraData.defaultLang != language ? "disabled-style" : ""}`}>
          <DropzoneDirectiveComponent
            disabled={extraData.defaultLang != language}
            onUpload={setPhotoUrl}
            item={media}
            emptyClassName="menu-item-edit-dropzone-empty"
            className="dropzone-def-style"
            playIfFocus={true}
            currentTime={0}
          />  
        </div>
      }

      <div className={`${posterType != "editor" ? 'display-none' : ""}`}>
        <QuillEditor
          quillRef={quillRef}
          disabled={!isEnabled}
        />
      </div>

      {posterType == "editor" &&
      <>
        <div id="buttonSaveContainer" className='save-button-container'>
        <Button
          color="green"
          appearance="primary"
          onClick={() => handleSave(getContent())}
          // disabled={!canSave}
        >
          {translate(extraData.defaultLang == language ? 'save' : 'fixTranslation')}
        </Button>
      </div>
      </> 
      }
    </Container>
  );
};

export default PosterEditorComponent;
