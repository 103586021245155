import vegetarian from "../../assets/images/vegetarian-icon.png";
import vegan from "../../assets/images/vegan-icon.png";
import lactoseFree from "../../assets/images/lactose-free-icon.png";
import glutenFree from "../../assets/images/gluten-free.png";
import React, { useCallback, useMemo, useState } from "react";
import IconWithTooltipComponent from "../../directives/IconWithTooltipComponent";
import { useI18nContext } from '../../i18n/context/context';
import CloudinaryImage from '../CloudinaryImage';
import ModalItemComponent from "../modal/ModalItemComponent";
import VideoPlayerComponent from "../base/VideoPlayerComponent";
import DishPrices from './components/DishPrices';
import DishItemPriceComponent from "./components/DishItemPriceComponent";
import { Loader } from "rsuite";
import { FaPlay } from "react-icons/fa";
import { CiPlay1 } from "react-icons/ci";


function DishItemForRestaurantComponent({ dish }) {
  const { translate } = useI18nContext();
  const [openItemModal, setOpenItemModal] = useState(false); 

  const getImage = useMemo(() => {
    if(!dish || !dish.urlFirstPhoto)
      return (<></>)
    if(dish.urlFirstPhoto.slice(-4) == ".jpg" || dish.urlFirstPhoto.slice(-4) == ".png"){
      return (
        <div className="">
          {dish.urlFirstPhoto.includes('cloudinary') ? (
            <CloudinaryImage
              alt={dish.dishName}
              src={dish.urlFirstPhoto}
              cropMode="fill"
              width={150}
              height={150}
              className="item-img-style"
            />
          ) : (
            <img
              alt={dish.dishName}
              src={dish.urlFirstPhoto}
              className="item-img-def-style"
            />
          )}
        </div>
      )
    }
    else{
      return (
        <div className='dish-picture-container'>
          <div>
            <VideoPlayerComponent
              id="demo-player"
              publicId={dish.urlFirstPhoto}
              className="dish-video-style"
              autoplay={false}
              playIfFocus={false}
              canPlay={false}
              currentTime={0}
              muted
            />
          </div>
        </div>
      )
    }
  }, [dish])

  const getDetails = useMemo(() => {
    return (
      <>
      {!!dish.dishType && 
                    <div className="item-element-style">
                    <span className="item-label">{translate("type")}</span>
                      <span className="item-description-style">{dish.dishType?.text}</span>
                    </div>
                }
                {!!dish.dishItems && dish.dishItems.length > 0 &&
                <div className="item-element-style">
                  <span className="item-label">{translate("elements")}</span>
                  <span className="item-description-style">
                    {dish.dishItems.map(({text}, index) => (
                      <span key={index}>{text}{index < dish.dishItems.length-1 && ","} </span>
                    ))}
                  </span>
                </div>
                }
                {!!dish.allergens && dish.allergens.length > 0 && 
                  <div className="item-element-style">
                    <span className="item-label">{translate("allergens")}</span>
                    <span className="item-description-style">
                      {dish.allergens.map((item, index) => (
                        <span key={index}>{translate(item)}{index < dish.allergens.length-1 && ","} </span>
                      ))}
                    </span>
                  </div>
                }
                { (dish.isVegetarian || dish.isVegan || dish.isLactoseFree || dish.isGlutenFree) &&
                  <div className="item-element-style">
                    {dish.isVegetarian &&
                      <span className="margin-right-sm">
                        <IconWithTooltipComponent icon={vegetarian} text={translate("vegetarian")} />
                      </span>
                    }
                    {dish.isVegan && 
                      <span className="margin-right-sm">
                        <IconWithTooltipComponent icon={vegan} text={translate("vegan")} />
                      </span>
                    }
                    {dish.isLactoseFree && 
                      <span className="margin-right-sm">
                        <IconWithTooltipComponent icon={lactoseFree} text={translate("lactoseFree")} />
                      </span>
                    }
                    {dish.isGlutenFree && 
                      <span className="margin-right-sm">
                        <IconWithTooltipComponent icon={glutenFree} text={translate("glutenFree")} />
                      </span>
                    }              
                  </div>
                }
                <div className="price-font-style">
                    <DishItemPriceComponent prices={dish.prices} priceVariants={dish.priceVariants} />
                </div>
      </>
    )
  })

  const getCountOfData = useCallback(() => {
    let countOfData = 0;
    if(!!dish.dishType){
      countOfData++;
    }
    if(!!dish.dishItems && dish.dishItems.length > 0){
      countOfData++;
    }
    if(!!dish.allergens && dish.allergens.length > 0){
      countOfData++;
    }
    if(dish.isVegetarian || dish.isVegan || dish.isLactoseFree || dish.isGlutenFree){
      countOfData++;
    }
    if((dish.prices && dish.prices.length > 0) || (dish.priceVariants &&  dish.priceVariants.length > 0)){
      countOfData++;
    }
    return countOfData;
  }, [dish])

  const getDetailsNextToPhoto = useMemo(() => {
    // dish.description.length > 25
    if(!!dish.description && getCountOfData() > 2){
      return (<></>)
    }
    return (<>{getDetails}</>)
  }, [dish])

  const getDetailsUnderPhoto = useMemo(() => {
    // dish.description.length <= 25
    if(!dish.description || getCountOfData() <= 2){
      return (<></>)
    }
    return (<>{getDetails}</>)
  }, [dish])

  return (
    <div>
      <div>
        {openItemModal &&
          <ModalItemComponent dish={dish} setOpenModal={() => setOpenItemModal(false)}/>
        }
      </div>
      <div className="item-def-style" onClick={() => setOpenItemModal(true)}>
        <div className="item-def-inside-style" onClick={() => setOpenItemModal(true)}>
          <div className="margin-auto">
            {!!dish && !!dish.urlFirstPhoto &&
              <div className="item-image-loader">
                <Loader size="md" className='dropzone-icon' />
              </div>
            }
            {/* {!!dish && !!dish.urlFirstPhoto && !(dish.urlFirstPhoto.slice(-4) == ".jpg" && dish.urlFirstPhoto.slice(-4) == ".png") &&
              <div className="item-image-play">
                <FaPlay size="sm" className="item-image-play-inside" />
              </div>
            } */}
            {getImage}
          </div>
          <div className="item-def-details-style">
            <div className="item-details-inside-style">
              <div className="item-name-style">{dish.dishName}</div>
              {!!dish.description && 
                <div className="item-element-style item-description-desc-style">
                  <span>{dish.description}</span>
                </div>
              }
              <div className="item-details-next-to-photo">
                {getDetailsNextToPhoto}
              </div>
            </div>
          </div>
        </div>
        <div className="item-details-under-photo">
          {getDetailsUnderPhoto}
        </div>
      </div>
    </div>
  );
}

export default DishItemForRestaurantComponent;
