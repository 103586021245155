/* eslint-disable react-hooks/exhaustive-deps */
import "../App.css";
import logoPicture from "../assets/images/logo/sefoo.svg"

function EmptyPage() {

  return (
    <div className="empty-page-container">
        <div className="eppty-page-inside">
            <div className="logo-empty-page-container">
                <img src={logoPicture} className="logo-empty-page-style" />
            </div>
            <div className="sefoo-empty-page">Sefoo</div>
        </div>
    </div>
    )
}


export default EmptyPage;
