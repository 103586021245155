import "bootstrap/dist/css/bootstrap.min.css";
import { useCallback } from "react";
import useScrollProgress from "./useScrollProgress";

function useDOMHelpers() {

  const resizeObserver = new ResizeObserver((entries) => {
    // You can iterate all of the element entries observed
    for (const entry of entries) {
      // Do something on resize, access the element on `entry.target`
    }
  });

  const initEventHandlers = () => {
    // document.addEventListener(
    //   "DOMNodeInserted",
    //   function (e) {
    //     if (e.target.className == "rs-picker-none") {
    //       document.getElementsByClassName("rs-picker-none")[0].innerText =
    //         GetString("noResultsFound");
    //     }
    //   },
    //   false,
    // );

    // document.addEventListener(
    //   "DOMNodeInserted",
    //   function (e) {
    //     if (e.target.className == "rs-anim-fade rs-picker-select-menu rs-picker-menu") {
    //       let m = document.querySelector('.rs-picker-menu');
    //       // let element = document.getElementsByClassName("rs-anim-fade rs-picker-select-menu rs-picker-menu");
    //       resizeObserver.unobserve(m)
    //     }
    //   },
    //   false,
    // );
  };

  const explicitErrorHandler = useCallback(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop completed with undelivered notifications." ||
        e.message === "ResizeObserver loop limit exceeded"
      ) {
        e.stopImmediatePropagation();
      }
    });
  }, []);

  // const initScrollEvent = (SetEnableGoTopButton) => {
  //   // const scrollProgress = useScrollProgress();
  //   // console.log(scrollProgress)
  //   window.onscroll = () => {
  //     if (window.scrollY > 1) {
  //       SetEnableGoTopButton(true);
  //     } else {
  //       SetEnableGoTopButton(false);
  //     }
  //   };
  // };

  return {
    initEventHandlers,
    explicitErrorHandler
  };
}

export default useDOMHelpers;
