
import { useState, useEffect, useCallback, useRef} from "react";
import { Input } from 'rsuite';

function SelectOptionsTextComponent({options, selectedOptions, onChange, listRef, showSearch}){

  const [hoverIdx, setHoverIdx] = useState(-1)
  const [search, setSearch] = useState(null)
  const searchRef = useRef(null)

  const setAvailableIdx = (actualIdx, direction) => {
    if(direction == 'up'){
      let newIdx = options.findLastIndex((x, idx) => idx < actualIdx && !selectedOptions?.includes(x))
      if(newIdx == -1 && options.length > selectedOptions.length){
        newIdx = options.findLastIndex(x => !selectedOptions.includes(x));
      }
      setHoverIdx(newIdx)
    }
    else{
      let newIdx = options.findIndex((x, idx) => idx > actualIdx && !selectedOptions?.includes(x))
      if(newIdx == -1 && options.length > selectedOptions.length){
        newIdx = 0;
      }
      setHoverIdx(newIdx)
    }
  }

  const keyDownFunction = useCallback((e) => {
    if(e.code == "ArrowDown"){
      e.preventDefault();
      if(hoverIdx >= options.length - 1){
        setHoverIdx(0)
      }
      else{
        setAvailableIdx(hoverIdx, 'down')
        // setHoverIdx(hoverIdx+1)
      }
    }
    if(e.code == "ArrowUp"){
      e.preventDefault();
      if(hoverIdx == 0){
        setHoverIdx(options.length - 1)
      }
      else{
        setAvailableIdx(hoverIdx, 'up')
        // setHoverIdx(hoverIdx-1)
      }
    }
    if((e.code == "Enter" || e.code == "Tab") && hoverIdx >= 0){
      onChange(options[hoverIdx])
    }
  }, [options, hoverIdx])

  useEffect(() => {
    document.addEventListener("keydown", keyDownFunction, false);
    return () => {
      document.removeEventListener("keydown", keyDownFunction, false);
    }
  }, [hoverIdx, setHoverIdx])

    return (
      <>{!!options?.filter(x => !selectedOptions?.includes(x))?.length &&
        <div ref={listRef} onMouseLeave={(e) => setHoverIdx(-1)} className="msd-list-items">
          {!!showSearch && options.length > 9 &&
          <Input ref={searchRef} onMouseDown={(e) => { e.preventDefault(); e.stopPropagation(); searchRef?.current?.focus()}} className={` admin-basic-edit-item-field-text`}
                        onChange={(val, e) => {
                          setSearch(val)
                        }}
                      />
          }
          {options.filter(x => !search || x.text.toLowerCase().includes(search.toLowerCase())).map((item, idx) => (
              <div key={idx} className={`msd-item ${!!selectedOptions && selectedOptions.includes(item) ? "msd-item-selected" : ""} ${hoverIdx == idx ? "msd-item-hover" : ""} `} 
                onMouseDown={(e) => onChange(item)}
                onMouseOver={(e) => setHoverIdx(idx)}>{item.text}</div>
          ))}
        </div>
      }
      </>
    )
}

export default SelectOptionsTextComponent;