import { useCallback, useEffect, useState } from "react";

const useScreenDetector = () => {
    const [sizeScreen, setSizeScreen] = useState([0, 0]);
    const [screenType, setScreenType] = useState("PC");
    const [screenOrientation, setScreenOrientation] = useState("HORIZONTAL");
    
    const fixElementsStyle = useCallback(() => {
        let element = document.getElementById("filtersAutoContainer");
        if(!!element){
            element.style.height = (document.documentElement.clientHeight - 115) + "px";
        }
    }, [])

    useEffect(() => {
        if(sizeScreen[0] < sizeScreen[1]){
            setScreenOrientation("VERTICAL");
        }
        else{
            setScreenOrientation("HORIZONTAL");
        }
    }, [sizeScreen])

    useEffect(() => {
        setSizeScreen([document.documentElement.clientWidth, document.documentElement.clientHeight])

        const resizeScreen = () => {
            setSizeScreen([document.documentElement.clientWidth, document.documentElement.clientHeight])
            fixElementsStyle();
        }

        if(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)){
            setScreenType("TOUCH")
        }
        else{
            setScreenType("NO-TOUCH")
        }

        window.addEventListener('resize', resizeScreen);
        window.addEventListener('fullscreenchange', resizeScreen);

        return () => {
            window.removeEventListener('resize', resizeScreen);
            window.removeEventListener('fullscreenchange', resizeScreen);
        }
    }, []);
  
    return {sizeScreen, screenType, screenOrientation, fixElementsStyle};
  };
  
  export default useScreenDetector;