const API_URL = process.env.REACT_APP_APIURL;

const getDefaultHeaders = ({language = 'pl', contentType = 'application/json'}) => {
  return {
    'Accept-Language': language,
    ...(contentType ? {'Content-Type': contentType} : {}),
  };
};

const getHeadersWithToken = ({language = 'pl', token = "", contentType = 'application/json'}) => {
  return {
    'Accept-Language': language,
    'Token': token,
    ...(contentType ? {'Content-Type': contentType} : {}),
  };
};

export const createGetRequest = (url, language, contentType, options = {}) => (
  fetch(API_URL.concat(url), {
    headers: getDefaultHeaders({language, contentType}),
    method: 'GET',
    ...options
  }).then((response) => response.json())
);

export const createGetRequestWithToken = (url, language, token, contentType, options = {}) => (
  fetch(API_URL.concat(url), {
    headers: getHeadersWithToken({language, contentType, token}),
    method: 'GET',
    ...options
  }).then((response) => response.json())
);

export const createPostRequest = (url, body, language, contentType) => (
  fetch(API_URL.concat(url), {
    headers: getDefaultHeaders({language, contentType}),
    method: 'POST',
    body,
  }).then((response) => response.json())
);

export const createPutRequestWithToken = (url, body, language, token, contentType) => (
  fetch(API_URL.concat(url), {
    headers: getHeadersWithToken({language, contentType, token}),
    method: 'PUT',
    body,
  }).then((response) => response.json())
);

export const createPutRequest = (url, body, language, contentType) => (
  fetch(API_URL.concat(url), {
    headers: getDefaultHeaders({language, contentType}),
    method: 'PUT',
    body,
  }).then((response) => response.json())
);

export const createDeleteRequest = (url, language, contentType) => (
  fetch(API_URL.concat(url), {
    headers: getDefaultHeaders({language, contentType}),
    method: 'DELETE',
  }).then((response) => response.json())
);
