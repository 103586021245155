import PropTypes from 'prop-types';
import "./SelectDirectiveStyles.css";
import { useState, useEffect, useCallback, useRef, createRef, useMemo } from "react";
import useScreenDetector from "../hooks/useScreenDetector";
import { IoClose } from "react-icons/io5";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import SelectOptionsObjectComponent from "./SelectOptionsObjectComponent";

const SelectDirectiveComponent = ({
  placeholder,
  options,
  onChange,
  selectedOption,
  defaultValue,
  parentElementId,
  clearable = true
}) => {
  const [isOpened, SetIsOpened] = useState(false);
  const myRef = useRef(null);
  const listRef = createRef(null);

  const { sizeScreen } = useScreenDetector();

  useEffect(() => {
    if(!!onChange && !!defaultValue && !selectedOption?.text){
      onChange(defaultValue)
    }
  }, [onChange, defaultValue, selectedOption])

  useEffect(() => {
    if(!!myRef && !!listRef && !!listRef.current){
      let element = myRef.current.getBoundingClientRect();
      listRef.current.style.width = element.width + "px";
      if(parentElementId){
        let parentElement = document.getElementById(parentElementId).getBoundingClientRect();
        let parentHeight = parentElementId != "App" ? parentElement.height : parentElement.height-35;
        let listElement = listRef.current.getBoundingClientRect();
        listRef.current.style.height = ''
        if(parentElement.top + parentHeight < element.top + element.height + listElement.height){
                    if(((parentElement.top + parentHeight)-(element.top + element.height) > (listElement.height*0.75)) && listElement.height > 250){
                        listRef.current.style.height = parentElement.top + parentHeight - listElement.top + "px";
                        listRef.current.style.top = ''
                    }
                    else if(parentElement.top < (element.top-listElement.height)){
                        listRef.current.style.top = -listElement.height + "px";
                    }
                }
                else{
                    listRef.current.style.top = element.height + "px";
                }        
      }
    }
  }, [sizeScreen, myRef, listRef])

  const clickOutside = (event) => {
    const el = myRef?.current
    if (!el || el.contains(event.target)) {
      return
    }
    SetIsOpened(false)
  }

  // const clickOutside = useCallback((event) => {
  //   const el = myRef?.current
  //   if (!el || el.contains(event.target)) {
  //     // SetIsOpened(true)
  //     return
  //   }
  //   SetIsOpened(false)
  // }, [myRef])

  const selectItemFunction = useCallback((item) =>{
    onChange(item);
    SetIsOpened(false);
  }, [onChange])

  const GetItems = useMemo(() => {
    return (
      <SelectOptionsObjectComponent
        options={options}
        onChange={selectItemFunction}
        listRef={listRef}
        value={selectedOption?.value}
      />
    );
  },[options, listRef])

  useEffect(() => {
    window.addEventListener('click', clickOutside);
    return () => {
      window.removeEventListener('click', clickOutside);
    }
  }, [clickOutside]);

  const clear = useCallback((e) =>{
    onChange(null);
    e.stopPropagation();
    e.preventDefault();
  },[onChange])


  const onDown = (e) => {
    SetIsOpened(!isOpened);
  }

  return (
    <div
      ref={myRef}
      className="sd-main"
      tabIndex={0}
      onFocus={() => SetIsOpened(true)}
      onBlur={() => SetIsOpened(false)}
    >
      <div onMouseDown={onDown}
            // onTouchStart={onDown} 
          className={`sd-input-body ${isOpened ? "sd-focused" : ""} ${selectedOption ? "sd-selected" : ""}`} >
        <div className="sd-input-inside">
          {!!placeholder && (!selectedOption || (!selectedOption?.text && !selectedOption.value)) && <div className="sd-placeholder-style">{placeholder}</div>}
          {!!selectedOption && (!!selectedOption?.text || !!selectedOption.value) && <div className="sd-selected-item-style">{selectedOption?.text}</div>}
        </div>
        <div className="sd-clear">
          {clearable && !!selectedOption && (!!selectedOption?.text || !!selectedOption.value) && (
            <IoClose className="sd-clear-button"
              onMouseDown={clear}
              // onTouchStart={clear}
            />
          )}
          {(!clearable || (!selectedOption || (!selectedOption?.text && !selectedOption.value))) && !!isOpened &&
                    <FaChevronUp className="sd-oc-button"/>
          }
          {(!clearable || (!selectedOption || (!selectedOption?.text && !selectedOption.value))) && !isOpened &&
                    <FaChevronDown className="sd-oc-button"/>
          }
        </div>
      </div>
      {!!isOpened &&
                GetItems
      }
    </div>
  )
}

SelectDirectiveComponent.propTypes = {
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  selectedOption: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default SelectDirectiveComponent;