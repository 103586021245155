import "leaflet/dist/leaflet.css";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import {Icon} from 'leaflet'
import { IoNavigate } from "react-icons/io5";
import { useI18nContext } from "../../../i18n/context/context";
import blackMarker from '../../../assets/images/blackMarker.png'
import { hexToCSSFilter } from 'hex-to-css-filter';

function MapStaticComponent({positions, color}) {
  const { translate, language } = useI18nContext();
  const [position] = useState([positions.y, positions.x]);
  const [marker, setMarker] = useState(blackMarker)
  const markerRef = useRef(null)

  useEffect(() => { 
    const observer = new MutationObserver((mutationsList, observer) => { 
      const elements = document.getElementsByClassName('leaflet-attribution-flag'); 
      if (elements.length > 0) { 
        Array.from(elements).forEach(element => element.remove());
        observer.disconnect(); 
      }
      const markers = document.getElementsByClassName('leaflet-marker-icon');
      let filter = hexToCSSFilter(color).filter;
      for (let i = 0; i < markers.length; i++) {
        markers[i].style.filter = filter.replace(';', '');
      }
    }); 
    observer.observe(document.body, { childList: true, subtree: true }); 

    markerRef.current?.openPopup()
    return () => { observer.disconnect(); }; 
  }, [language, markerRef.current]);

  const navigate = (e) => {
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${position[0]},${position[1]}`)
  }

  const legalIcon = new Icon ({
    iconUrl: marker,
    iconSize: [40, 40]
  })
  
  const staticProps = {
    zoomControl: false,
    doubleClickZoom: false,
    closePopupOnClick: false,
    dragging: false,
    zoomSnap: false,
    zoomDelta: false,
    trackResize: false,
    touchZoom: false,
    scrollWheelZoom: false
  }

  return (
    <div className="map-container-style">
      <MapContainer center={position} zoom={15} className="map-container" >
        <TileLayer
          attribution={`&copy; ${translate("authors")} <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>`}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          className= 'map-tiles'
        />
        <Marker position={position} icon={legalIcon} ref={markerRef} >
          <Popup ><div className="map-popup-navigate" onClick={navigate}><IoNavigate className="margin-right-sm"/> {translate("navigate")}</div></Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}

export default MapStaticComponent;
