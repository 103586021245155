import React, { useEffect, useState } from "react";
import DropzoneDirectiveComponent from "../../../directives/DropzoneDirectiveComponent";
import AdminPicturesItemComponent from "./AdminPicturesItemComponent";
import LoaderDirectiveComponent from "../../../directives/LoaderDirectiveComponent";
import { ApiLibrary } from '../../../helpers/api/ApiLibrary';
import { useLogger } from '../../../hooks/logger/useLogger';
import { useToaster, Message } from "rsuite";
import { useI18nContext } from "../../../i18n/context/context";


function AdminPicturesComponent({ restaurantId, restaurantPictures, disabled=false }) {
  const logger = useLogger();
  const { translate, language, languageList } = useI18nContext();
  const toaster = useToaster();
  const [isLoading, setIsLoading] = useState(false);
  const [medias, setMedias] = useState(restaurantPictures);

  useEffect(() => {
    const fetch = async () => {
      const response = await new ApiLibrary().restaurants.media.getList(restaurantId);
      setMedias(response.data);
    }

    fetch();
  }, [restaurantId]);

  const removeMedia = async (item) => {
    try {
      setIsLoading(true)
      const response = await new ApiLibrary().restaurants.media.remove(restaurantId, item.id);
      setMedias((medias) => medias.filter(({id}) => id !== response.data?.id))
      setIsLoading(false)
      toaster.push(
        <Message showIcon type="success" closable>
          {translate("removeMediaSuccess")}
        </Message>, 
        { placement: "topEnd", duration: 5000 }
      )
    } catch (error) {
      setIsLoading(false);
      toaster.push(
        <Message showIcon type="error" closable>
          {translate("somethingWrong")}
        </Message>, 
        { placement: "topEnd", duration: 5000 }
      )
    }
  };

  const addMedia = async (files) => {
    try {
      setIsLoading(true)
      const responses = await Promise.all(files.map((file) => {
        return new ApiLibrary().restaurants.media.add(restaurantId, file);
      }));
      setIsLoading(false)
      if (!responses?.length) {
        setIsLoading(false);
        toaster.push(
          <Message showIcon type="error" closable>
            {translate("somethingWrong")}
          </Message>, 
          { placement: "topEnd", duration: 5000 }
        )
        return
      }
      setMedias((medias) => ([...responses.map(response => response.data), ...medias]));
      toaster.push(
        <Message showIcon type="success" closable>
          {translate("addMediaSuccess")}
        </Message>, 
        { placement: "topEnd", duration: 5000 }
      )
    } catch (error) {
      setIsLoading(false);
        toaster.push(
          <Message showIcon type="error" closable>
            {translate("somethingWrong")}
          </Message>, 
          { placement: "topEnd", duration: 5000 }
        )
    }
  }

  if (!restaurantId) {
    return 'No restaurant';
  }

  return (
    <div className="admin-pictures-container">
      {isLoading &&
        <LoaderDirectiveComponent />
      }
      <div className="admin-pictures-dropzone">
        <DropzoneDirectiveComponent
          disabled={disabled}
          isMulti={true}
          // onlyPhoto
          // canLoading={false}
          onUpload={addMedia}
          className="dropzone-def-style"
        /> 
      </div>
      {!!medias && <AdminPicturesItemComponent
        disabled={disabled}
        medias={medias}
        onRemove={removeMedia}
      />}
    </div>
  );
}

export default AdminPicturesComponent;
