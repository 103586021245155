import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import { Input, Button, useToaster, Message } from 'rsuite';
import { useI18nContext } from "../../i18n/context/context";
import { ApiLibrary } from "../../helpers/api/ApiLibrary";
import LoaderDirectiveComponent from "../../directives/LoaderDirectiveComponent";
import LogoBackComponent from "../../components/base/top/LogoBackComponent";
import LanguageComponent from "../../components/base/top/LanguageComponent";
import { useParams } from "react-router-dom";
import DialogModalComponent from "../../components/modal/DialogModalComponent";
import ProfileComponent from "../../components/base/top/ProfileComponent";

function ChangePasswordPage({ }) {
  const { translate, language } = useI18nContext();
  const toaster = useToaster();
  const [model, setModel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [wrongInfo, setWrongInfo] = useState(null)
  const { userId } = useParams();
  const [cookies, setCookie, deleteCookie] = useCookies(['sefooToken']);


  useEffect(() => {

    const verify = async () => {
        try {
            const myCookie = cookies['sefooToken'];
  
            if(!myCookie?.token){
                window.location.href = window.location.origin.concat('/login');
            }
            setIsLoading(true)
            const tokenResponse = await new ApiLibrary().user.users.verifyToken(myCookie?.userId, myCookie?.token);
            setIsLoading(false)
            if (!tokenResponse?.data?.responseValue) {
                deleteCookie('sefooToken', { path: '/' });
                window.location.href = window.location.origin.concat('/login');
            }
        }
        catch(e){
            deleteCookie('sefooToken', { path: '/' });
            window.location.href = window.location.origin.concat('/login');
        }
      }
      
    verify()

    setModel({
        id: userId,
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
    })
  }, [])

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await new ApiLibrary(language).user.users.changePassword(model, language);
    setIsLoading(false);
    if (response?.data?.responseValue && response?.data?.responseCode == 'ok') {
        toaster.push(
            <Message showIcon type="success" closable>
              {translate("changePasswordSuccess")}
            </Message>, 
            { placement: "topEnd", duration: 5000 }
          )
        window.location.href = window.location.origin.concat('/user/').concat(userId);
    }
    else{
        setWrongInfo(translate(response?.data?.responseCode))
    }
  }


  const keyDownFunction = useCallback((e) => {
    if(e.code == "Enter" && model?.oldPassword && model?.newPassword && model?.confirmPassword && model?.confirmPassword == model?.newPassword && !wrongInfo && !isLoading
        && String(model?.newPassword).match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()\-_=+\[\]{};:'",.<>?/\\|]).{8,}$/)
    ){
      handleSubmit()
    }
  }, [model, wrongInfo, isLoading])

  useEffect(() => {
    document.addEventListener("keydown", keyDownFunction, false);
    return () => {
      document.removeEventListener("keydown", keyDownFunction, false);
    }
  }, [model, wrongInfo, isLoading])

  return (
    <>
        {isLoading &&
          <LoaderDirectiveComponent />
        }
        {!!wrongInfo &&
          <DialogModalComponent blockCloseOutside open={!!wrongInfo} onClose={() => setWrongInfo(null)} text={wrongInfo} />
        }
        <div>
            <div className="col-12 margin-bottom-sx header-flex-style main-header-style">
              <div className="margin-top-sx" >
                <LogoBackComponent pathBack={window.location.origin.concat('/user/').concat(cookies['sefooToken']?.userId).concat("/accountConfiguration")} />
              </div>
              <div className="display-flex">
                <ProfileComponent />
                <LanguageComponent />
              </div>
            </div>
          </div>
          <div className="admin-header-underline" />
          <div className="user-login-register-title">
            {translate('changePassword')}
          </div>
        <div className="admin-user-container">
            {!!model &&
                <div id="registerContainer" className="admin-basic-data-inside-container">
            <div className="display-flex">
                        <div className="admin-basic-label">
                            {translate("oldPassword")}
                        </div>
                        <div className="admin-basic-edit-item-field-text  ">
                            <Input id="password" type="password" className={` admin-basic-edit-item-field-text  ${!!model?.oldPassword ? "sd-selected" : ""}`}
                                placeholder={translate("oldPassword")}
                                defaultValue={model?.oldPassword}
                                onChange={(val, e) => {
                                    let thisModel = {...model};
                                    thisModel.oldPassword = val;
                                    setModel(thisModel);
                                }}
                            />
                        </div>
                    </div>
                    <div className="display-flex margin-top-xl">
                        <div className="admin-basic-label">
                            {translate("newPassword")}
                        </div>
                        <div className="admin-basic-edit-item-field-text  ">
                            <Input id="password" type="password" className={` admin-basic-edit-item-field-text 
                                                                    ${!!model?.newPassword && !String(model?.newPassword).match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()\-_=+\[\]{};:'",.<>?/\\|]).{8,}$/) ? "edit-input-error" : ""}
                                                                     ${!!model?.newPassword ? "sd-selected" : ""}`}
                                placeholder={translate("newPassword")}
                                defaultValue={model?.newPassword}
                                onChange={(val, e) => {
                                    let thisModel = {...model};
                                    thisModel.newPassword = val;
                                    setModel(thisModel);
                                }}
                            />
                            <div className={`input-text-format-info ${!String(model?.newPassword).match(/^.{8,}$/) ? "input-text-format-info-wrong" : ""}`}>
                                {translate("passwordFormatMinEightSign")}
                            </div>
                            <div className={`input-text-format-info ${!model?.newPassword || !String(model?.newPassword).match(/^(?=.*?[a-z])/) ? "input-text-format-info-wrong" : ""}`}>
                                {translate("passwordFormatSmallLetter")}
                            </div>
                            <div className={`input-text-format-info ${!String(model?.newPassword).match(/^(?=.*?[A-Z])/) ? "input-text-format-info-wrong" : ""}`}>
                                {translate("passwordFormatBigLetter")}
                            </div>
                            <div className={`input-text-format-info ${!String(model?.newPassword).match(/^(?=.*?[0-9])/) ? "input-text-format-info-wrong" : ""}`}>
                                {translate("passwordFormatDigit")}
                            </div>
                            <div className={`input-text-format-info ${!String(model?.newPassword).match(/^(?=.*?[!@#$%^&*()\-_=+\[\]{};:'",.<>?/\\|])/) ? "input-text-format-info-wrong" : ""}`}>
                                {translate("passwordFormatSpecialSign")}
                            </div>
                        </div>
                    </div>
                    <div className="display-flex margin-top-xl">
                        <div className="admin-basic-label">
                            {translate("confirmPassword")}
                        </div>
                        <div className="admin-basic-edit-item-field-text  ">
                            <Input id="confirmPassword" type="password" className={` admin-basic-edit-item-field-text  
                                                                          ${!!model?.confirmPassword && model?.newPassword != model?.confirmPassword ? "edit-input-error" : ""}
                                                                           ${!!model?.confirmPassword ? "sd-selected" : ""}`}
                                placeholder={translate("confirmPassword")}
                                defaultValue={model?.confirmPassword}
                                onChange={(val, e) => {
                                    let thisModel = {...model};
                                    thisModel.confirmPassword = val;
                                    setModel(thisModel);
                                }}
                            />
                        </div>
                    </div>
            </div>
            }
            <div className='save-button-container'>
            <Button
                color="green"
                appearance="primary"
                onClick={() => handleSubmit()}
                disabled={!model?.oldPassword || !model?.newPassword || !model?.confirmPassword || model?.confirmPassword != model?.newPassword || 
                    !String(model?.newPassword).match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()\-_=+\[\]{};:'",.<>?/\\|]).{8,}$/)
                }
            >
                {translate('save')}
            </Button>
            </div>
        </div>
    </>    
  );
}

export default ChangePasswordPage;
