import { Input } from 'rsuite';

function ItemsTextDirectiveComponent({ items, setItems, placeholder }){   
  return (
        <>
            {!!items && 
                [...(items ?? []), '' ].map((item, idx) => (
                    <div key={idx} className="edit-basic-items-text-field-item">
                      <Input className={` admin-basic-edit-item-field-text   ${!!item  ? "sd-selected" : ""}`}
                        placeholder={placeholder}
                        defaultValue={item}
                        onChange={(val) => setItems(idx, val)}
                      />
                    </div>)
                  )
            }
        </>
    )
}

export default ItemsTextDirectiveComponent;