import React from "react";
import { useI18nContext } from "../../../i18n/context/context";

function TypesOfSubscriptionComponent({ }) {
  const { translate } = useI18nContext();
  return (
    <div className="user-subscriptin-container">
        <div className="user-subscriptin-container-inside">
            <div className="free-subscritption">
                <div className="subscritption-title">
                    {translate("freeSubscriptionTitle")}
                </div>
                <div className="subscritption-description">
                    <div className="subscritption-description-single">
                        {translate("freeSubscriptionDescription1")}
                    </div>
                    <div className="subscritption-description-single">
                        {translate("freeSubscriptionDescription2")}
                    </div>
                    <div className="subscritption-description-single">
                        {translate("freeSubscriptionDescription3")}
                    </div>
                    <div className="subscritption-description-single">
                        {translate("freeSubscriptionDescription4")}
                    </div>
                    <div className="subscritption-description-single">
                        {translate("freeSubscriptionDescription5")}
                    </div>
                </div>
            </div>
            {/* <div className="premium-subscritption">
                <div className="subscritption-title">
                    {translate("premiumSubscriptionTitle")}
                </div>
                <div className="subscritption-description">
                    <div className="subscritption-description-single">
                        {translate("premiumSubscriptionDescription")}
                    </div>
                </div>
            </div> */}
        </div>
    </div>
  );
}

export default TypesOfSubscriptionComponent;
