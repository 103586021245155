import { useCallback, useEffect, useState } from "react";

const useScrollProgress = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrollingState, setScrollingState] = useState(false);
    var start = {x:0, y:0};

    const fixStylesElements = useCallback((e) => {
        document.documentElement.dataset.showfilters = 0;
        document.documentElement.dataset.scrolltop = 0;
        document.documentElement.dataset.scrollbottom = 0;
    }, [])

    const fixHeaderFooterPosition = useCallback((e) => {
        if(window.scrollY <= 1){
            if(e.deltaY > 0 && document.documentElement.dataset.hideheader != 1){
                e.preventDefault();
                e.stopPropagation();
                document.documentElement.dataset.hideheader = 1;        
            }

            if(e.deltaY < 0 && document.documentElement.dataset.hideheader == 1){
                document.documentElement.dataset.hideheader = 0;
            }
        }
    }, [])


    const startTouchPosition = useCallback((e) => {
        start.x = e.touches[0].pageX;
        start.y = e.touches[0].pageY;
        // setStartTouch({x: e.touches[0].pageX, y: e.touches[0].pageY})
    }, [])

    const fixHeaderFooterPositionByTouch = useCallback((e) => {
        if(window.scrollY <= 1){
            if(e.touches[0].pageY < start.y && document.documentElement.dataset.hideheader != 1){
                e.preventDefault();
                e.stopPropagation();
                document.documentElement.dataset.hideheader = 1;        
            }

            if(e.touches[0].pageY > start.y && document.documentElement.dataset.hideheader == 1){
                document.documentElement.dataset.hideheader = 0;
            }
        }
    }, [])

    const updateScrollPosition = useCallback((e) => {
        setScrollingState(true);
        document.documentElement.dataset.hidesearchheader = (window.scrollY > 90 && scrollPosition < window.scrollY) || Math.abs(scrollPosition - window.scrollY) > 200 ? 1 : 0;
        // document.getElementById("headerManuSearch").style.top = (window.scrollY > 90 && scrollPosition < window.scrollY) || Math.abs(scrollPosition - window.scrollY) > 30 ? "-60px" : "0px";
        setScrollPosition(window.scrollY);
        fixStylesElements(e);
    }, [scrollPosition])

    useEffect(() => {
        const updateScrollEndState = () => {
            setScrollingState(false);
        }

        window.addEventListener('scroll', updateScrollPosition);
        window.addEventListener('scrollend', updateScrollEndState);


        // window.addEventListener('wheel', fixHeaderFooterPosition, {passive:false});
        // window.addEventListener('mousewheel', fixHeaderFooterPosition, {passive:false});
        // window.addEventListener('DOMMouseScroll', fixHeaderFooterPosition, {passive:false});

        // window.addEventListener('touchstart', startTouchPosition, {passive:false});
        // window.addEventListener('touchmove', fixHeaderFooterPositionByTouch, {passive:false});
        
        return () => {
            window.removeEventListener('scroll', updateScrollPosition);
            window.removeEventListener('scrollend', updateScrollEndState);

            // window.removeEventListener('wheel', fixHeaderFooterPosition, {passive:false});
            // window.removeEventListener('mousewheel', fixHeaderFooterPosition, {passive:false});
            // window.removeEventListener('DOMMouseScroll', fixHeaderFooterPosition, {passive:false});

            // window.removeEventListener('touchstart', startTouchPosition, {passive:false});
            // window.removeEventListener('touchmove', fixHeaderFooterPositionByTouch, {passive:false});

        }
    }, [scrollPosition]);
  
    return {scrollPosition, scrollingState, fixStylesElements};
  };
  
  export default useScrollProgress;