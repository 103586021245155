import { Loader } from "rsuite";
import VideoPlayerComponent from "../VideoPlayerComponent";
import PictureComponent from "./PictureComponent";
import { useMemo } from "react";

function PicturesComponent({ items, OpenModal }) {


  const openModal = (idx) => {
    OpenModal(null, items, idx);
  }

  const getImages = useMemo(() => {
    return (
      <>
        {!!items && 
        items.map((item, idx) => (
          <PictureComponent item={item} idx={idx} OpenModal={openModal} />
        ))
    }
      </>
    ) 
  }, [items])


    return (
      <div className="pictures-style">
        {getImages}
      </div>
    );
  }
  
  export default PicturesComponent;