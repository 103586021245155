import vegetarian from "../../../assets/images/vegetarian-icon.png";
import vegan from "../../../assets/images/vegan-icon.png";
import lactoseFree from "../../../assets/images/lactose-free-icon.png";
import glutenFree from "../../../assets/images/gluten-free.png";
import React, { useEffect, useMemo, useState } from "react";
import IconWithTooltipComponent from "../../../directives/IconWithTooltipComponent";
import { FaPencil } from "react-icons/fa6";
import { useI18nContext } from "../../../i18n/context/context";
import VideoPlayerComponent from "../../base/VideoPlayerComponent";
import CloudinaryImage from "../../CloudinaryImage";
import DishItemPriceComponent from "../../dish/components/DishItemPriceComponent";
import DictionaryEditModalComponent from "../DictionaryEditModalComponent";
import { useToaster, Message } from 'rsuite';
import ModalEditComponent from "../../modal/ModalEditComponent";
import { HiTranslate } from "react-icons/hi";
import { ApiLibrary } from "../../../helpers/api/ApiLibrary";
import LoaderDirectiveComponent from "../../../directives/LoaderDirectiveComponent";


function MenuItemComponent({ dish, editItem, extraData }) {
  const { translate, language } = useI18nContext();
  const [changeCounter, setChangeCounter] = useState(1)
  const [dictionaryItem, setDictionaryItem] = useState(null);
  const [isSaving, setIsSaving] = useState(false)
  const toaster = useToaster();

  const changeDictionary = async (dictionaryItem, val) => {
    dictionaryItem.text = val;
    setIsSaving(true)
    let result = await new ApiLibrary(language).dictionary.change(dictionaryItem)
    setIsSaving(false)
    dictionaryItem =result.data;
    toaster.push(
      <Message showIcon type="success" closable>
        {translate("changedTranslation")}
      </Message>, 
      { placement: "topEnd", duration: 5000 }
    )
  }

  const getPrice = useMemo(() => {
    if(!dish || !dish.price)
      return (<></>)
    return (
      <div className="display-flex">
        {dish.price.map((x, idx) => (
          <div key={idx} className="item-price-style padding-right-xl">
            {Object.values(x)[0]} {Object.keys(x)[0]}
          </div>
        ))}
      </div>
    )
}, [dish]);

  useEffect(() => {
    setChangeCounter(changeCounter+1)
  }, [dish])


  const getImage = useMemo(() => {
    if(!dish || !dish.urlFirstPhoto)
      return (<></>)
    if(dish.urlFirstPhoto.slice(-4) == ".jpg" || dish.urlFirstPhoto.slice(-4) == ".png"){
      return (

        <div className="">
        {dish.urlFirstPhoto.includes('cloudinary') ? (
          <CloudinaryImage
            alt={dish.dishName}
            src={dish.urlFirstPhoto}
            cropMode="fill"
            width={150}
            height={150}
            className="item-img-style"
          />
        ) : (
          <img
            alt={dish.dishName}
            src={dish.urlFirstPhoto}
            className="item-img-style"
          />
        )}
      </div>
      )
    }
    else{
      return (
        <div className='admin-dish-picture-container'>
          <div>
            <VideoPlayerComponent
              id="demo-player"
              publicId={dish.urlFirstPhoto}
              className="admin-dish-video-style"
              autoplay={false}
              playIfFocus={true}
              currentTime={0}
              muted
            />
          </div>
        </div>
      )
    }
  }, [dish])

  return (
    <div>
      {isSaving && 
        <LoaderDirectiveComponent />
      }
      {!!dictionaryItem &&
          <ModalEditComponent
            setOpen={() => setDictionaryItem(null)}
            model={dictionaryItem.text}
            setModelChanges={(val) => changeDictionary(dictionaryItem, val)}        
            hideLanguages
            isSimpleModel
            hideDeleteButton
            title={translate("translate")}
        >
          <DictionaryEditModalComponent />
        </ModalEditComponent>
        }
      <div className="admin-item-style">
        {!!dish.urlFirstPhoto &&
          <div className="item-picture-style">
            {getImage}
          </div>
        }
        <div className="item-body">
          {extraData.defaultLang == language &&
            <div className="item-header-style">
              <div className="item-header-inside">
                <span className="margin-right-md edit-icon-butoon">
                  <FaPencil size={30} onClick={() => editItem()} />
                </span>
              </div>
            </div>
          }
          <div className="admin-item-details-style">
            <div className="item-details-inside-style">
              <div className="item-name-style margin-bottom-sm">{dish.dishName.items.find(x => x.lang == language)?.text}
              {extraData.defaultLang != language && 
                    <span className="translate-button" onClick={(e) => { e.stopPropagation(); setDictionaryItem(dish.dishName?.items?.find(x => x.lang == language))}}>
                      <HiTranslate size={16} />
                    </span>
                  }
              </div>
              {!!dish.description && 
                <div className="item-element-style item-description-desc-style">
                  <span>{dish.description?.items?.find(x => x.lang == language)?.text}
                  {extraData.defaultLang != language && 
                    <span className="translate-button" onClick={(e) => { e.stopPropagation(); setDictionaryItem(dish.description?.items?.find(x => x.lang == language))}}>
                      <HiTranslate size={16} />
                    </span>
                  }
                  </span>
                </div>
              }
              {!!dish.dishType && 
                <div className="item-element-style">
                <span className="item-label">{translate("type")}</span>
                <span className="item-description-style">{dish.dishType?.items?.find(x => x.lang == language)?.text}
                {extraData.defaultLang != language && 
                    <span className="translate-button" onClick={(e) => { e.stopPropagation(); setDictionaryItem(dish.dishType?.items?.find(x => x.lang == language))}}>
                      <HiTranslate size={16} />
                    </span>
                  }
                </span>
                </div>
              }
              {!!dish.dishItems && dish.dishItems.length > 0 &&
              <div className="item-element-style">
                <span className="item-label">{translate("elements")}</span>
                  <span className="item-description-style">
                {dish.dishItems.map((elements, index) => (
                  <span key={index}>{elements.items?.find(x => x.lang == language)?.text}
                  {extraData.defaultLang != language && 
                    <span className="translate-button" onClick={(e) => { e.stopPropagation(); setDictionaryItem(elements?.items?.find(x => x.lang == language))}}>
                      <HiTranslate size={16} />
                    </span>
                  }
                  {index < dish.dishItems.length-1 && ","} </span>
                  ))}
                  </span>
                </div>
              }
              {!!dish.allergens && dish.allergens.length > 0 && 
                <div className="item-element-style">
                  <span className="item-label">{translate("allergens")}</span>
                  <span className="item-description-style">
                    {dish.allergens.map((item, index) => (
                      <span key={index}>{translate(item)}{index < dish.allergens.length-1 && ","} </span>
                    ))}
                  </span>
                </div>
              }
              { (dish.isVegetarian || dish.isVegan || dish.isLactoseFree || dish.isGlutenFree) &&
                <div className="item-element-style">
                  {dish.isVegetarian &&
                    <span className="margin-right-sm">
                      <IconWithTooltipComponent icon={vegetarian} text={translate("vegetarian")} />
                    </span>
                  }
                  {dish.isVegan &&
                    <span className="margin-right-sm">
                      <IconWithTooltipComponent icon={vegan} text={translate("vegan")} />
                    </span>
                  }
                  {dish.isLactoseFree &&
                    <span className="margin-right-sm">
                      <IconWithTooltipComponent icon={lactoseFree} text={translate("lactoseFree")} />
                    </span>
                  }
                  {dish.isGlutenFree &&
                    <span className="margin-right-sm">
                      <IconWithTooltipComponent icon={glutenFree} text={translate("glutenFree")} />
                    </span>
                  }
                </div>
              }
              <div className="price-font-style">
                <DishItemPriceComponent prices={dish.prices} extraData={extraData} priceVariants={dish.priceVariants} />
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuItemComponent;
