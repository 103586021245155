import React from 'react';
import "./RestaurantStartComponent.css";
import { Fab } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Modal from '@mui/material/Modal';

function RestaurantStartComponent({content, onContinue}) {

  return (
    <Modal
        open={true}
        onClose={() => onContinue()}
        style={{ backgroundColor: "rgb(0, 0, 0, 0.4)" }}
      >
<div onClick={(e) => {onContinue()}} className="restaurant-start-poster-inside ql-editor" >
        <div className='restaurant-start-poster-inside-inside' dangerouslySetInnerHTML={{__html: content}} />
      </div>
      {/* <Fab
        color="primary"
        onClick={() => onContinue()}
        style={{position: 'fixed', right: '16px', bottom: '16px'}}
      >
        <ArrowForwardIcon />
      </Fab> */}
    </Modal>
    // <div className="restaurant-start-container ql-snow" onClick={() => onContinue()}>
      
    // </div>
    )
}


export default RestaurantStartComponent;
