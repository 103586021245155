import "bootstrap/dist/css/bootstrap.min.css";
import { FaChevronLeft } from "react-icons/fa";

import logoEmpty from "../../../assets/images/logo/sefooLogoSmall.png"


import React from "react";

function LogoBackComponent({ pathBack, color }) {

    const mouseDownHandler = ( event ) => {
      if(!pathBack){
        return;
      }
      
      if( event.button === 0 ) {
        window.location.href = pathBack;
      }
      // if( event.button === 1 ) {
      //   window.open(window.location.origin, "_blank");
      // }
    }


  return (
    <div>
        {<div className={`restaurant-back-container ${!!pathBack ? "cursor-pointer" : ""}  `} onMouseDown={mouseDownHandler} >
          {!!pathBack && <div className="restuarant-arrow-left">
            <FaChevronLeft size={30} />
          </div>}
          <div className="">
            <img src={logoEmpty} className="logo-back-style" />
            {/* {(!color || color == "sefoo") && <img src={logoPictureSefoo} className="logo-back-style" />}
            {color == "brown" && <img src={logoPictureBrown} className="logo-back-style" />}
            {color == "green" && <img src={logoPictureGreen} className="logo-back-style" />}
            {color == "orange" && <img src={logoPictureOrange} className="logo-back-style" />}
            {color == "pink" && <img src={logoPicturePink} className="logo-back-style" />}
            {color == "purple" && <img src={logoPicturePurple} className="logo-back-style" />}
            {color == "red" && <img src={logoPictureRed} className="logo-back-style" />}
            {color == "yellow" && <img src={logoPictureYellow} className="logo-back-style" />} */}
            
          </div>
          <div className="restaurant-go-to-text">Sefoo</div>
        </div>}
    </div>
  );
}

export default LogoBackComponent;
