import { BaseApi } from '../shared/baseApi';
import { createPostRequest } from '../shared/fetch';

export class DictionaryApi extends BaseApi {
    change(payload){
        return createPostRequest(`dictionary/change`, JSON.stringify(payload), this._language);
    }

    checkIfNotTranslatedExist(payload){
        return createPostRequest(`dictionary/checkIfNotTranslatedExist`, JSON.stringify(payload), this._language);
    }

    translateNotTranslated(payload){
        return createPostRequest(`dictionary/translateNotTranslated`, JSON.stringify(payload), this._language);
    }

    changeGlobal(payload){
        return createPostRequest(`dictionary/changeGlobal`, JSON.stringify(payload), this._language);
    }

    getDictionariesByCodes(payload){
        return createPostRequest(`dictionary/getDictionariesByCodes`, JSON.stringify(payload), this._language);
    }
}