import "./InputDirectiveStyles.css";
import "./InputHoursDirectiveStyles.css"
import TimePicker from 'react-time-picker'
import 'react-time-picker/dist/TimePicker.css';
import { useState, useEffect, useCallback, useRef } from "react";
import { IoClose } from "react-icons/io5";

function InputHourDirectiveComponent({value, setValue, className}){
    const [isFocused, setIsFocused] = useState(false);
    const [generatedId, SetGeneratedId] = useState("");
    const [hourGeneratedId, SetHourGeneratedId] = useState("");
    const [minuteGeneratedId, SetMinuteGeneratedId] = useState("");
    const [myValue, setMyValue] = useState(value);
    const myRef = useRef(null);
    const inputHourRef = useRef(null);
    const inputMinuteRef = useRef(null);


    useEffect(() => {
        if(inputHourRef?.current){
            let element = inputHourRef.current.getBoundingClientRect();
            SetHourGeneratedId("inputHourHourDirective".concat(element.x).concat(element.y)); 
            // inputHourRef.current.addEventListener('keydown', handleKeyHourDown);
            // return () => {
            //     inputHourRef.current.removeEventListener('keydown', handleKeyHourDown);
            // };
        }

        if(inputMinuteRef?.current){
            let element = inputMinuteRef.current.getBoundingClientRect();
            SetMinuteGeneratedId("inputHourMinuteDirective".concat(element.x).concat(element.y)); 
            // inputHourRef.current.addEventListener('keydown', handleKeyHourDown);
            // return () => {
            //     inputHourRef.current.removeEventListener('keydown', handleKeyHourDown);
            // };
        }
    }, [inputHourRef, inputMinuteRef])

    const focusInput = (e) => {
        setIsFocused(true);
    }

    const focusOutInput = (e) => {
        setIsFocused(false);
    }

    const focusOnMinute = (e) => {
        if(!!inputHourRef && !!inputMinuteRef){
            if(document.activeElement != inputHourRef.current){
                inputMinuteRef.current.focus();
            }
        }
    }

    const clear = (e) =>{
        e.preventDefault();
        e.stopPropagation();
        setIsFocused(false);
        setMyValue(null)
        if(setValue){
            setValue(null);
        }
    }

    const onChange = (val, idx) => {
        let values = !!myValue ? myValue.split(':') : ['00', '00']
        if (val.length > 2) {
            val = val.slice(val.length-2, val.length);
        }
        if(idx == 0 && val > 23){
            val = "23";
        }
        if(idx == 1 && val > 59){
            val = "59";
        }
        values[idx] = val.padStart(2, '0');
        let result = `${values[0]}:${values[1]}`
        setMyValue(result)
        if(setValue){
            setValue(result);
        }
    }

    useEffect(() => {
        if(!!myRef){
            let element = myRef.current.getBoundingClientRect();
            SetGeneratedId("inputHourDirective".concat(element.x).concat(element.y)); 
        }
    }, [myRef])

    return (
        <div id={generatedId} ref={myRef} className={`id-main ${className}`} onClick={(e) => focusOnMinute(e)}>
            <div className={`id-input-hour-body ${!!isFocused ? "id-focused" : ""} ${!!myValue ? "id-hour-not-empty" : ""}`}>
                <input id={hourGeneratedId} ref={inputHourRef} className="id-input-hour-inside" min={0} max={23} type="number" value={value?.split(':')[0] ?? ''} onFocus={focusInput} onBlur={focusOutInput} onChange={(e) => onChange(e.currentTarget.value, 0)} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}/>
                :
                <input id={minuteGeneratedId} ref={inputMinuteRef} className="id-input-minute-inside" min={0} max={59} type="number" value={value?.split(':')[1] ?? ''} onFocus={focusInput} onBlur={focusOutInput} onChange={(e) => onChange(e.currentTarget.value, 1)} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}/>
                <div className="idh-clear">
                    {!!value && 
                    <IoClose className="idh-clear-button"
                        onClick={(e) => {
                            clear(e);
                        }} 
                    />}
                </div>
            </div>
        </div>
    )
}

export default InputHourDirectiveComponent;