import "./ModalItem.css";
import { IoClose } from "react-icons/io5";
import Modal from '@mui/material/Modal';
import { Button } from 'rsuite';
import { useCallback, useEffect } from "react";
import { useI18nContext } from "../../i18n/context/context";

function DialogModalComponent({text, type, blockCloseOutside, setAnswere, showCloseButton, onClose, open}) {
    const { translate, language } = useI18nContext();
    const onCloseWithAnswere = (val) => {
        if(setAnswere){
            setAnswere(val)
        }
        onClose();
    }

    const onCloseFunction = () => {
      if(blockCloseOutside){
        return;
      }
      onClose();
    }

    const keyDownFunction = useCallback((e) => {
      if(e.code == "Enter"){
        if(type != 'confirm'){
          onCloseWithAnswere(true)
        }
        else{
          onClose()
        }
      }
      if(e.code == "Escape"){
        if(type != 'confirm'){
          onCloseWithAnswere(false)
        }
        else{
          onClose()
        }
      }
    }, [type])
  
    useEffect(() => {
      document.addEventListener("keydown", keyDownFunction, false);
      return () => {
        document.removeEventListener("keydown", keyDownFunction, false);
      }
    }, [type])

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseFunction}
      >
        <div className="modal-info-confirm-inside">
            {showCloseButton &&
                <div className="modal-vertical-close-button-container">
                <IoClose
                  className="modal-vertical-close-button"
                  onClick={onClose}
                />
                </div>
            }
          <div className="margin-xl">
            {text}
          </div>
        {type != 'confirm' &&
            <div className="modal-edit-footer-left">
            <Button color="blue" onClick={onClose} appearance="primary" className="modal-edit-footer-button">
                {translate("ok")}
            </Button>
            
            </div>
        }
        {type == 'confirm' &&
            <div className="modal-edit-footer-left">
            <Button color="green" onClick={() => onCloseWithAnswere(true)} appearance="primary" className="modal-edit-footer-button">
                {translate("yes")}
            </Button>
            <Button color="red" onClick={() => onCloseWithAnswere(false)} appearance="primary" className="modal-edit-footer-button">
                {translate("no")}
            </Button>
            </div>
        }
          
        </div>
      </Modal>
    </>
  );
}

export default DialogModalComponent;
