import "../../../../css/filters.css";
import React, { useMemo } from "react";
import { Toggle } from "rsuite";
import { IconButton } from "rsuite";
import { FaArrowLeft } from 'react-icons/fa';
import { useI18nContext } from "../../../../i18n/context/context";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

function FiltersHidingAdminComponent({ filtersConfig, changeFilterValue, isFilterActive, isOpen, SetFilterClose}) {

    const { translate } = useI18nContext();
    const {id, ...filters} = filtersConfig;
    const FilterButtonAppearance = {
      DEFAULT: 'ghost',
      ACTIVE: 'primary'
    };

    const filterButtonAppearance = useMemo(() => (
        isFilterActive ? FilterButtonAppearance.ACTIVE : FilterButtonAppearance.DEFAULT
      ), [isFilterActive]);

  return (
<div className={`filter-menu-style ${isOpen ? 'active': ''}`}>
      <Modal
        id="filters"
        className={`filter-menu-modal-style ${isOpen ? 'active': ''}`}
        open={isOpen}
        onClose={SetFilterClose}
      >
        <Box className={`filter-menu-style ${isOpen ? 'active': ''}`}>
        <div className="margin-top-mx padding-right-md">
        <div className="margin-bottom-mx padding-left-xl">
          <IconButton icon={<FaArrowLeft size={15}/>} circle appearance={filterButtonAppearance} onClick={() => SetFilterClose()}/>
        </div>
        <div className="">
            <div className="label padding-md">
                {translate("selectVisibleFilters")}
            </div>
            <div>
                    {!!filters && Object.keys(filters).map((item, idx) => (
                        <div key={idx} className="edit-filter-item">
                        <div className="margin-bottom-mx padding-left-xl display-flex col-11 filter-min-width" >
                            <div className={`full-width label cursor-pointer filter-label ${filters[item] ? "selected-label" : ""}`} onClick={(e) => changeFilterValue(item, !filters[item])} >{translate(item)}</div>
                            <div className="">{<Toggle checked={filters[item]} onChange={(val) => changeFilterValue(item, val)} />}</div>
                        </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        </Box>
      </Modal>
    </div>
  );
}

export default FiltersHidingAdminComponent;
