import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from "react";
import "../../../css/global.css";
import useScrollProgress from "../../../hooks/useScrollProgress";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import useScreenDetector from "../../../hooks/useScreenDetector";

function HorizontalScrollingMenuComponent({ items, menuItemsRefs, isInCard = false }) {
  const [selectedItem, setSelectedMenuItem] = useState(null);
  const [canSelectItem, setCanSelectItems] = useState(true)
  const [menuItems, setMenuItems] = useState([]);
  const [itemsRefs, setItemsRefs] = useState([]);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const { scrollPosition, scrollingState } = useScrollProgress();

  useEffect(() => {
    if(Object.keys(menuItemsRefs).length > 0 && !!menuItemsRefs[Object.keys(menuItemsRefs)[0]].current){
      let menu = Object.keys(menuItemsRefs).reduce((acc, value) => {
        let elementPosition = menuItemsRefs[value].current.getBoundingClientRect().top;
        let headerOffset = isInCard ? 158 : 121;
        let offsetPosition = elementPosition + window.scrollY - headerOffset;
        acc[value] = offsetPosition;
        return acc;
      }, {});
      setMenuItems(menu)
      
      let refds = Object.keys(menu).reduce((acc, value) => {
        acc[value] = React.createRef();
        return acc;
      }, {});
      setItemsRefs(refds);
      window.scrollTo(0, 1);
      setSelectedMenuItem(Object.keys(refds)[0]);
    }
  }, [menuItemsRefs]);

  const scrollIntoElement = (item) => {
    window.scrollTo({
      top: menuItems[item],
      behavior: "instant"
    })
  }

  useLayoutEffect(() => {
    // if(canChangeItem){
    //   setSelectedMenuItem(Object.keys(menuItems).findLast(key => (menuItems[key] <= scrollPosition+121)));
    // }
    if(scrollPosition + window.innerHeight == document.body.scrollHeight){
      setSelectedMenuItem(Object.keys(menuItems).findLast(key => key == key))
    }
    else{
      setSelectedMenuItem(Object.keys(menuItems).findLast(key => (menuItems[key] <= scrollPosition+(isInCard ? 158 : 121))));
    }
  }, [scrollPosition, selectedItem]);

  // useMemo(() => {
  //   if(!scrollingState){
  //     setCanChangeItem(true);
  //   }
  // }, [scrollingState, canChangeItem]);

  useEffect(() =>{
    let sliderProperties = ourRef?.current?.children[0]?.getBoundingClientRect();
    let itemProperties = itemsRefs[selectedItem]?.current?.getBoundingClientRect();
    if(!!sliderProperties && !!itemProperties){
      if(itemProperties.x + itemProperties.width > sliderProperties.x + sliderProperties.width){
        let destPosition = ((itemProperties.x + itemProperties.width) - (sliderProperties.x + sliderProperties.width)) + ourRef.current.children[0].scrollLeft;
        ourRef.current.children[0].scrollTo({left: destPosition+30});
      }
      if(itemProperties.x < sliderProperties.x){
        let destPosition = ourRef.current.children[0].scrollLeft + itemProperties.x - sliderProperties.x;
        ourRef.current.children[0].scrollTo({left: destPosition-30});
      }
    }
  }, [selectedItem])


  const setItem = useCallback((item) => {
    setCanSelectItems(true);
    if(canSelectItem){
      scrollIntoElement(item);
      setSelectedMenuItem(item);
    }
  }, [canSelectItem, selectedItem]);

  const ourRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });
  
  const handleDragStart = (e) => {
    if (!ourRef.current) return;
    const slider = ourRef.current.children[0];
    const startX = e.pageX - slider.offsetLeft;
    const startY = e.pageY - slider.offsetTop;
    const scrollLeft = slider.scrollLeft;
    const scrollTop = slider.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    document.addEventListener("mouseup", mouseDownOutsideComponent);
    setIsMouseDown(true);
  }

  const handleDragEnd = () => {
    setIsMouseDown(false);
    setCanSelectItems(true);
    document.removeEventListener("mouseup", mouseDownOutsideComponent, false);
    if (!ourRef.current) return;
  }

  const handleDrag = (e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const slider = ourRef.current.children[0];
    const x = e.pageX - slider.offsetLeft;
    const y = e.pageY - slider.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const walkY = (y - mouseCoords.current.startY) * 1.5;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    if(Math.abs(mouseCoords.current.scrollLeft - slider.scrollLeft) > 5){
      setCanSelectItems(false);
    }
  }

  const setArrowsInfo = useCallback((sliderMenu) => {
    setShowLeftArrow(sliderMenu.scrollLeft > 1);
    setShowRightArrow(sliderMenu.scrollWidth - sliderMenu.scrollLeft > sliderMenu.clientWidth + 1)
  }, [showLeftArrow, showRightArrow])

  const handleWheel = useCallback((e) => {
    if (!ourRef.current || (ourRef.current.children[0].clientWidth >= ourRef.current.children[0].scrollWidth)) return;
    e.preventDefault()
    const sliderMenu = ourRef.current.children[0];
    sliderMenu.scrollLeft = sliderMenu.scrollLeft + (e.deltaY/5);
  }, [ourRef, showLeftArrow, showRightArrow]);

  const mouseDownOutsideComponent = useCallback((event) => {
    const el = ourRef?.current
    if (!el || el.contains(event.target)) {
        return;
    }
    handleDragEnd();
  }, [ourRef])


  const clickLeft = () => {
    const sliderMenu = ourRef.current.children[0];
    sliderMenu.scrollLeft = sliderMenu.scrollLeft - 30;
    setArrowsInfo(sliderMenu)
  }

  const clickRight = () => {
    const sliderMenu = ourRef.current.children[0];
    sliderMenu.scrollLeft = sliderMenu.scrollLeft + 30;
    setArrowsInfo(sliderMenu)
  }

  const handleScroll = (e) => {
    setArrowsInfo(ourRef.current.children[0])
  }

  const { sizeScreen } = useScreenDetector();
  useEffect(() => {
    if(!ourRef)
      return;

    const sliderMenu = ourRef?.current.children[0];
    if(sliderMenu.scrollWidth - sliderMenu.scrollLeft > sliderMenu.clientWidth + 1){
      sliderMenu.addEventListener("wheel", handleWheel)
      sliderMenu.addEventListener("scroll", handleScroll);
      setArrowsInfo(sliderMenu);
    }
    else{
      sliderMenu.removeEventListener("wheel", handleWheel, false)
      sliderMenu.removeEventListener("scroll", handleScroll, false);
      setArrowsInfo(sliderMenu);
    }
  }, [ourRef, sizeScreen])

  return (
    <div className="header-menu-scroll">
      {showLeftArrow &&
        (<div className="menu-left-arrow" onClick={clickLeft}>
          <FaChevronLeft size={20} className="menu-left-arrow-horizontal-menu-scroll"/>
        </div>)
      }
      <div
        ref={ourRef}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        onMouseMove={handleDrag}
        className="padding-left-sx padding-right-sx horizontal-menu-container scroll-horizontal"
        >
          <div id="slider" className="container-menu scroll-horizontal">
          {!!items &&
            items.map((item) => (
              <div className="cursor-pointer" key={item}>
                <div onMouseUp={(id) => setItem(item)} ref={itemsRefs[item]} className={`horizontal-menu-item ${item == selectedItem ? "horizontal-menu-item-selected" : ""}`}>
                  {item}
                </div>
              </div>
            ))}
        </div>
        {showRightArrow && 
              <div className="menu-right-arrow" onClick={clickRight}>
                <FaChevronRight size={20} className="menu-right-arrow-horizontal-menu-scroll"/>
              </div>
            }
      </div>
    </div>
  );
}

export default HorizontalScrollingMenuComponent;
