import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import HomePageOld from './pages/HomePageOld';
import RegisterPage from './pages/user/RegisterPage';
import LoginPage from './pages/user/LoginPage';
import EmptyPage from './pages/EmptyPage';
import HomeDefPage from './pages/HomeDefPage';
import AdministratorPage from './pages/AdministratorPage';
import RestaurantDefPage from './pages/RestaurantDefPage';
import UserConfigurationPage from './pages/user/UserConfigurationPage';
import { useState, useEffect, useCallback } from 'react';
import useDOMHelpers from './hooks/useDOMHelpers';
import { IconButton } from 'rsuite';
import SortUpIcon from '@rsuite/icons/SortUp';
import useScrollProgress from './hooks/useScrollProgress';
import ModalDefComponent from './components/modal/ModalDefComponent';
import ModalEditComponent from './components/modal/ModalEditComponent';
import CreateNewRestaurant from './pages/CreateNewRestaurant';
import AccountConfigurationPage from './pages/user/AccountConfigurationPage';
import { FaPercent } from "react-icons/fa";

import 'quill/dist/quill.core.css';
import { AdminApiContextProvider } from './hooks/admin/Provider';
import { CookiesProvider } from 'react-cookie';
import ResetPasswordPage from './pages/user/ResetPasswordPage';
import ChangePasswordPage from './pages/user/ChangePasswordPage';

function App() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [selectedInspectPhoto, setSelectedInspectPhoto] = useState(null);
  const [selectedIndexPhoto, setSelectedIndexPhoto] = useState(null);
  const [photos, setPhotos] = useState(null);
  const [showGoTopButton, setShowGoTopButton] = useState(false);
  const [showPromotionButton, setShowPromotionButton] = useState(false);
  const [showPromotionModal, setShowPromotionModal] = useState(false);
  const { initEventHandlers, explicitErrorHandler } = useDOMHelpers();

  const { scrollPosition, fixStylesElements } = useScrollProgress();
  const navigate = useNavigate();

  useEffect(() => {
    fixStylesElements();
    initEventHandlers();
    explicitErrorHandler();
  }, []);

  const ClickScrollToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  const OpenModal = useCallback((photo, photos, idx) => {
    if(!photos){
      setSelectedInspectPhoto(photo);
    }
    if(!!photos){
      setPhotos(photos);
      setSelectedIndexPhoto(idx);
    }
    SetModalState(true);
  }, []);

  const SetModalEditState = useCallback((isOpen) => {
    setModalEditOpen(isOpen)
  }, [])

  const SetModalState = useCallback((isOpen) => {
    if (isOpen) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
      setSelectedInspectPhoto(null);
      setPhotos(null);
      setSelectedIndexPhoto(null);
      // allowScroll();
    }
  }, []);

  const ChangeLocation = useCallback((res) => {
    window.location.href = window.location.origin.concat('/').concat(res);
  }, []);

  return (
    <div id="App" className="App">
      {/* {modalOpen && (
        <Modal SetModalState={SetModalState} photo={selectedInspectPhoto} modalState={modalOpen} />
      )} */}
      {modalOpen && <ModalDefComponent SetModalState={SetModalState} photo={selectedInspectPhoto} modalState={modalOpen} photos={photos} index={selectedIndexPhoto} />}
      {modalEditOpen && <ModalEditComponent classname="edit-modal-container" SetModalState={setModalEditOpen} modalState={modalEditOpen} />}
      <div id="body">
        <div
          className={
            modalOpen || modalEditOpen ? ' ' : ''
          }
        >
          <div className="body-style">
            <Routes>
              {/* <Route exact path="/old"
                element={ <HomePageOld OpenModal={OpenModal} setShowGoTopButton={setShowGoTopButton} />}
              /> */}
              <Route exact path="/homedefpage"
                  element={ <HomeDefPage OpenModal={OpenModal} setShowGoTopButton={setShowGoTopButton} />}
              />
              <Route exact path="/"
                  element={ <EmptyPage />}
              />
              <Route exact path="/register"
                  element={ <RegisterPage />}
              />
              <Route exact path="/login"
                  element={ <LoginPage />}
              />
              <Route exact path="/resetPassword"
                  element={ <ResetPasswordPage />}
              />
              <Route exact path="user/:userId"
                  element={ <UserConfigurationPage />}
              />
              <Route exact path="user/:userId/changePassword"
                  element={ <ChangePasswordPage />}
              />
              <Route exact path="user/:userId/accountConfiguration"
                  element={ <AccountConfigurationPage />}
              />

              <Route exact path="user/:userId/restaurant/:restaurantCode"
                element={
                  <CookiesProvider>
                    <AdminApiContextProvider>
                      <AdministratorPage OpenEditModal={SetModalEditState} OpenModal={OpenModal} setShowGoTopButton={setShowGoTopButton} />
                    </AdminApiContextProvider>
                  </CookiesProvider>
                }
              />
              <Route
                exact
                path="/:restaurantCode"
                element={
                  <RestaurantDefPage OpenModal={OpenModal} setShowGoTopButton={setShowGoTopButton} />
                }
              />
              <Route
                exact
                path="createNewRestaurant"
                element={
                  <AdminApiContextProvider>
                    <CreateNewRestaurant />
                  </AdminApiContextProvider>
                }
              />
              <Route path="/about" element={'asdasdasd'} />
            </Routes>
            
            {/* {!!showPromotionButton && <div id="promotionButton" className="go-top-icon-style">
              <IconButton
                icon={<FaPercent className="go-top-icon-inside-button" />}
                appearance="primary"
                circle
                onClick={() => setShowPromotionModal(true)}
              />
            </div>} */}
          </div>

        </div>

      </div>
      <div id="footer">
        <div className="fake-field-class">
          © 2024 Sefoo
        </div>
      </div>
    </div>
  );
}

export default App;
