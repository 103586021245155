import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useCookies } from "react-cookie";
import languageMapper from "./languageMapper"
import languages from "../language/languages.json";
import config from "../../datas/config.json"

const I18nContext = createContext({});

const useI18nStore = () => {
  const [cookies, setCookie] = useCookies(["SEFOO_LANGUAGE_COOKIE"]);
  const [restaurantLanguages, setRestaurantLanguages] = useState(null);
  const [language, setLanguage] = useState(cookies["SEFOO_LANGUAGE_COOKIE"] ?? (Object.entries(languages).map(([value, label]) => value).filter(x => (navigator.language || navigator.userLanguage)?.toLowerCase().includes(x))?.[0] ?? config.domain));

  useEffect(() => {
    if(restaurantLanguages?.length && !restaurantLanguages.some(x => x == language)){
      let browserLang = (navigator.language || navigator.userLanguage);
      let lang = restaurantLanguages.filter(x => (x.length == 2 && x.toLowerCase() == browserLang.toLowerCase().slice(0, 2)) || (x.length > 2 && x.toLowerCase() == browserLang.toLowerCase()));
      if(lang?.length){
        setLanguage(lang[0]);
        setCookie("SEFOO_LANGUAGE_COOKIE", lang[0], { path: "/" });
        return;
      }
      lang = restaurantLanguages.filter(x => x.length > 2 && x.toLowerCase().slice(0, 2) == browserLang.toLowerCase().slice(0, 2));
      if(lang?.length){
        setLanguage(lang[0]);
        setCookie("SEFOO_LANGUAGE_COOKIE", lang[0], { path: "/" });
        return;
      }
      setLanguage(config.domain);
      setCookie("SEFOO_LANGUAGE_COOKIE", config.domain, { path: "/" });
      return;
    }
  }, [restaurantLanguages, language])

  const languageList = useMemo(() => (
    Object.entries(languages).map(([value, label]) => ({label, value})).sort((a, b) => a.label.localeCompare(b.label))
  ), [languages]);

  const translate = useCallback((translationKey, lang=null) => {
    return languageMapper[lang ?? language]?.[translationKey] ?? `TODO: (${lang ?? language})${translationKey}`;
  }, [language]);

  return {
    language,
    setLanguage: (language, shouldReload = false) => {
      setLanguage(language);
      setCookie("SEFOO_LANGUAGE_COOKIE", language, { path: "/" });
      shouldReload && window.location.reload(false);
    },
    translate,
    languageList,
    setRestaurantLanguages
  }
}

export const useI18nContext = () => useContext(I18nContext);

export const I18nContextProvider = ({ children }) => {
  const store = useI18nStore();

  return (
    <I18nContext.Provider value={store}>
      {children}
    </I18nContext.Provider>
  );
}