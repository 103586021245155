import "./Modal.css";
import { useState, useEffect, useCallback, useRef } from "react";
import Modal from '@mui/material/Modal';
import { IoClose } from "react-icons/io5";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import VideoPlayerComponent from "../base/VideoPlayerComponent";

function ModalDefComponent({ SetModalState, photo, modalState, photos, index, hideCloseButton }) {
  const [open, setOpen] = useState(modalState);
  const [idx, setIdx] = useState(index);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const ourRef = useRef(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    SetModalState(false);
  }

  const clieckLeft = () => {
    setIdx(idx-1);
  }

  const clieckRight = () => {
    setIdx(idx+1);
  }


  const handleDragStart = useCallback((e) => {
    if(ourRef?.current){

    }
    setIsMouseDown(true)
  }, [ourRef, isMouseDown])

  const handleDragEnd = (e) => {
    if(ourRef?.current){
      
    }
    setIsMouseDown(false)
  }

  const handleDrag = useCallback((e) => {
    if(ourRef?.current && isMouseDown){
      // console.log(e)
      // console.log(ourRef)
    }
  }, [ourRef, isMouseDown])

  const keyDownFunction = useCallback((e) => {
    if(e.code == "ArrowLeft" && idx > 0){
      setIdx(idx-1);
    }
    if(e.code == "ArrowRight" && idx < photos.length-1){
      setIdx(idx+1);
    }
  }, [idx])

  useEffect(() => {
    if(!!photos){
      document.addEventListener("onmouseup", handleDragEnd, false);
      document.addEventListener("ontouchend", handleDragEnd, false);
      document.addEventListener("keydown", keyDownFunction, false);
      return () => {
        document.removeEventListener("onmouseup", handleDragEnd, false);
        document.removeEventListener("ontouchend", handleDragEnd, false);
        document.removeEventListener("keydown", keyDownFunction, false);
      }
    }
  }, [photos, idx])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        {!!photos && 
          <div className="modalBackground">
            <div className="modalContainer" 
              ref={ourRef}
              // onMouseDown={handleDragStart}
              // onMouseUp={handleDragEnd}
              // onMouseMove={handleDrag}
              // onTouchStart={handleDragStart} 
              // onTouchMove={handleDrag} 
              >
            <div className="body">
              {idx > 0 && 
                <div className="photos-arrow">
                  <FaChevronLeft size={40} className="photo-left-arrow" onClick={clieckLeft}/>
                </div>
              }
              {(photos[idx].slice(-4) == ".jpg" || photos[idx].slice(-4) == ".png") &&
                        <img
                          src={photos[idx]}
                          className="modal-picture-style"
                        />
                      }
                      {!(photos[idx].slice(-4) == ".jpg" || photos[idx].slice(-4) == ".png") &&
                        <div className="" >
                          <VideoPlayerComponent
                            id="demo-player"
                            publicId={photos[idx]}
                            autoplay={true}
                            muted={true}
                            className="modal-video-style"
                            classNameContainer="modal-video-style"
                          />
                        </div>
                      }


              {idx < photos.length-1 && 
                <div className="photos-arrow">
                  <FaChevronRight size={40} className="photo-right-arrow" onClick={clieckRight} />
                </div>
              }
              {!hideCloseButton &&
                <IoClose
                  className="closeButtonList"
                  onClick={handleClose}
                />
              }   
            </div>
          </div>
        </div>
        }
      </Modal>
    </div>
  );
}

export default ModalDefComponent;
