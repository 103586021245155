import "./ModalItem.css";
import Modal from '@mui/material/Modal';
import { Button, Input } from 'rsuite';
import { useI18nContext } from "../../i18n/context/context";
import { ApiLibrary } from "../../helpers/api/ApiLibrary";
import { useState, useCallback, useEffect } from "react";
import LoaderDirectiveComponent from "../../directives/LoaderDirectiveComponent";

function ActivationModalComponent({blockCloseOutside, onCloseWithAnswere, open, text, isActivation, userId}) {
    const { translate, language } = useI18nContext();
    const [wrongKey, setWrongKey] = useState(false)
    const [model, setModel] = useState(null)
    const [isLoading, setIsLoading] = useState(false); 
    
    const sendActivationKey = async () => {
      setIsLoading(true);
      let response = !!isActivation 
                    ? await new ApiLibrary(language).user.users.activateUser({id: userId, activationCode: model}, language)
                    : await new ApiLibrary(language).user.users.verifyKey({id: userId, activationCode: model}, language);
      setIsLoading(false);
      if(response?.data?.responseValue){
        onCloseWithAnswere(true);
      }
      else{
        setWrongKey(true)
      }
    }

    const onCloseFunction = () => {
      if(blockCloseOutside){
        return;
      }
      onClose();
    }

    const keyDownFunction = useCallback((e) => {
      if(e.code == "Enter" && !!model){
        sendActivationKey()
      }
      if(e.code == "Escape"){
        onCloseWithAnswere(false)
      }
    }, [model])
  
    useEffect(() => {
      document.addEventListener("keydown", keyDownFunction, false);
      return () => {
        document.removeEventListener("keydown", keyDownFunction, false);
      }
    }, [model])

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseFunction}
      >
        <>
        {isLoading &&
          <LoaderDirectiveComponent />
        }
        <div className="modal-info-confirm-inside">
          <div className="margin-xl">
            {text}
            <div className="display-flex margin-top-xl">
                        <div className="admin-basic-label  ">
                            {translate("verificationKey")}
                        </div>
                        <div className="activation-key-field-text">
                            <Input className={` admin-basic-edit-item-field-text ${wrongKey ? "edit-input-error" : ""}  ${!!model ? "sd-selected" : ""}`}
                                placeholder={translate("verificationKey")}
                                defaultValue={model}
                                onChange={(val, e) => {
                                    setModel(val);
                                    setWrongKey(false)
                                }}
                            />
                        </div>
                    </div>
          </div>
          <div className="modal-edit-footer-left">
            <Button color="green" onClick={() => sendActivationKey()} appearance="primary" className="modal-edit-footer-button" disabled={!model}>
                {translate("activate")}
            </Button>
            <Button color="red" onClick={() => onCloseWithAnswere(false)} appearance="primary" className="modal-edit-footer-button">
                {translate("cancel")}
            </Button>
            </div>
          
        </div>
        </>
      </Modal>
    </>
  );
}

export default ActivationModalComponent;
