import { useI18nContext } from '../../../i18n/context/context';
import QuillEditor from '../QuillEditor';
import Container from 'rsuite/Container';
import Button from 'rsuite/Button'
import { useToaster, Message } from 'rsuite';
import LoaderDirectiveComponent from '../../../directives/LoaderDirectiveComponent';
import { useEffect, useState } from 'react';
import ToggleWithLabel from '../../ToggleWithLabel';
import { useQuillEditor } from '../useQuillEditor';
import { ApiLibrary } from '../../../helpers/api/ApiLibrary';

const AnnouncementEditorComponent = ({restaurantId, checkChangeBookmark, setAnswere, setAskQuestion, extraData}) => {
  const { quillRef, getContent, setContent, setDefContent, quill } = useQuillEditor();
  const toaster = useToaster();
  const { language, translate } = useI18nContext();
  const [isEnabled, setEnabled] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState(null);
  const [translationId, setTranslationId] = useState(undefined);


  const check = async (setAnswere, setAskQuestion) => {
    setIsLoading(true)
    let response = await new ApiLibrary(language).restaurants.announcements.get(restaurantId, language);
    setIsLoading(false)
    let actualData = getContent()
    let verifyData = !!response?.data?.content  && response?.data?.content != '<p><br></p>' ? response.data.content : ""
    let veifyActualData = actualData != '<p><br></p>' ? actualData : ""
    if(verifyData != veifyActualData){
      setAskQuestion(true)  
    }
    else{
      setAnswere(true)
    }
    
  }

  useEffect(() => {
    if(checkChangeBookmark && setAskQuestion){
      check(setAnswere, setAskQuestion)
    }
  }, [checkChangeBookmark, setAskQuestion])

  const setToogleEnabled = async (val) => {
    setEnabled(val)
    setCanSave(true)
    setIsLoading(true)
      await new ApiLibrary(language).restaurants.announcements.updateOrCreate(restaurantId, {
        name: 'test',
        isEnabled: val,
        content: getContent()
      })
      setIsLoading(false)
  }
  
  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await new ApiLibrary(language).restaurants.announcements.get(restaurantId, language);

        if (!response.data) {
          return;
        }
        setTranslationId(response.data.translationId);
        setModel(response.data.content)
        setDefContent(response.data.content);
        setEnabled(response.data.isEnabled)
      } catch(error) {
        setContent('');
      }
    }

    if (setContent) {
      loadData();
    }
  }, [setContent, language, restaurantId])


  const handleSave = async () => {
    try {
      setIsLoading(true)
      if(extraData.defaultLang == language){
        await new ApiLibrary(language).restaurants.announcements.updateOrCreate(restaurantId, {
          name: 'test',
          isEnabled,
          content: getContent()
        })
      }
      else{
        await new ApiLibrary(language).restaurants.general.fixTranslation({id: translationId, content: getContent()})
      }

      setModel(getContent())
      setIsLoading(false)
      setCanSave(false)
      setAnswere(true)
      toaster.push(
        <Message showIcon type="success" closable>
          {translate("saveChangesSuccess")}
        </Message>, 
        { placement: "topEnd", duration: 5000 }
      )
    } catch(error) {
      setIsLoading(false);
      toaster.push(
        <Message showIcon type="error" closable>
          {translate("somethingWrong")}
        </Message>, 
        { placement: "topEnd", duration: 5000 }
      )
    }
  }

  if (!restaurantId) {
    return 'No restaurant selected';
  }

  return (
    <Container className="quill-editor-container">
      {isLoading &&
          <LoaderDirectiveComponent />
        }
      <div className='margin-top-mx'>
        <ToggleWithLabel
          label={translate("enableAnnouncement")}
          checked={isEnabled}
          onChange={setToogleEnabled}
        />
      </div>
      <div>
        <QuillEditor
          quillRef={quillRef}
          disabled={!isEnabled}
        />
      </div>
      <div id="buttonSaveContainer" className='save-button-container'>
        <Button
          color="green"
          appearance="primary"
          onClick={() => handleSave(getContent())}
          // disabled={!canSave}
        >
          {translate(extraData.defaultLang == language ? 'save' : 'fixTranslation')}
        </Button>
      </div>
    </Container>
  );
};

export default AnnouncementEditorComponent;
