import React from "react";
import { useI18nContext } from "../../../../i18n/context/context";
import AutoCompleteDirectiveComponent from "../../../../directives/AutoCompleteDirectiveComponent";
import MenuItemEditPriceComponent from "./MenuItemEditPriceComponent";
import { generateGuid } from '../../../../helpers/formatting';
import { MdDragIndicator } from 'react-icons/md';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

function MenuItemEditPriceVariantComponent({ priceVariants, variants, setVariant, setVariants, currencies }) {
  const { translate, language } = useI18nContext();  

  const clearFunction = (id, idx) => {
    const myPriceVariants = [...priceVariants];
    myPriceVariants[id].prices[idx].value = null;
    myPriceVariants[id].prices[idx].convert = null;
    
    myPriceVariants[id].prices = myPriceVariants[id].prices.filter(x => !!x.value || !!x.convert)
    
    if(myPriceVariants[id].prices.length){
      myPriceVariants[id].prices[0].convert = null;
      if(!myPriceVariants[id].prices[0].value){
        myPriceVariants[id].prices[0].value = 1;
      }
    }

    setVariant(id, myPriceVariants[id])
  }

  const setConvert = (id, idx, val) => {
    const myPriceVariants = [...priceVariants];
      
    if(myPriceVariants[id].prices[idx] != null) {
      myPriceVariants[id].prices[idx].convert = val;
    } else {
      myPriceVariants[id].prices.push({value: "", currency: "", convert: val})
    }

    myPriceVariants[id].prices = myPriceVariants[id].prices.filter(x => !!x.value || !!x.convert)

    setVariant(id, myPriceVariants[id])
  }

  const setPriceFunction = (id, idx, val) => {
    const myPriceVariants = [...priceVariants];
      
    if(myPriceVariants[id].prices[idx] != null) {
      myPriceVariants[id].prices[idx].value = val;
    } else {
      myPriceVariants[id].prices.push({value: val, currency: ""})
    }

    myPriceVariants[id].prices = myPriceVariants[id].prices.filter(x => !!x.value || !!x.convert)

    setVariant(id, myPriceVariants[id])
  }
  
  const setCurrencyFunction = (id, idx, curr) => {
    const myPriceVariants = [...priceVariants];
    myPriceVariants[id].prices[idx].currency = curr;
    setVariant(id, myPriceVariants[id])
  }

  const onChangeVariantValue = (id, priceVariant, val) => {
    if(!!val?.text){
      setVariant(id, {variant: {id: val?.id, text: val?.text}, prices: priceVariant.prices, order: priceVariant.order})
    }
    else{
      setVariant(id, {variant: null})
    }
    
    // const myPriceVariants = [...priceVariants];
    // if(myPriceVariants[id] != null) {
    //   myPriceVariants[id].variant = {id: val?.id, text: val?.text}
    //   setVariant(id, myPriceVariants[id].variant)
    // } else {
      
    // }
  }

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const myItems = Array.from(priceVariants);
    const [reorderedItem] = myItems.splice(result.source.index, 1);
    myItems.splice(result.destination.index, 0, reorderedItem);
    setVariants(myItems.map((item, index) => ({ ...item, order: index })));
  }

  return (
    <div className="">
      {!!priceVariants && 
                <div className="">
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable direction='vertical' droppableId="variants">
                      {(provided) => (
                        <div
                          style={{display: 'flex', flexDirection: 'column'}}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {
                            [...(priceVariants ?? []), { variant: {text: '', id: ''}, prices: [], order: priceVariants.length}].map((item, idx) => (
                              <Draggable key={item.id} draggableId={item.id} index={idx}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="admin-price-variant-container"
                                  >
                                    <div className="display-flex">
                                      <MdDragIndicator size="1.5rem" />
                                      <div className="admin-price-variant">
                                        <AutoCompleteDirectiveComponent 
                                          value={item.variant}
                                          onChange={(val) => onChangeVariantValue(idx, item, val)} 
                                          parentElementId="modalEditBodyContainer"
                                          options={variants?.filter(x => !priceVariants?.map(y => y.variant?.id).includes(x.id))}
                                          placeholder={translate("variant")}
                                        />
                                      </div>
                                    </div>
                                    {!!item?.variant?.text &&
                                      <div className="admin-price-variant-prices">
                                        <MenuItemEditPriceComponent
                                          prices={item.prices}
                                          currencies={currencies}
                                          setPrice={(id, val) => setPriceFunction(idx, id, val)}
                                          setConvert={(id, val) => setConvert(idx, id, val)}
                                          setCurrency={(id, val) => setCurrencyFunction(idx, id, val)}
                                          clearFunction={(id) => clearFunction(idx, id)}
                                        />
                                      </div>
                                    }
                                  </div>
                                )}
                              </Draggable>
                            ))
                          }
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
      }
    </div>
  );
}

export default MenuItemEditPriceVariantComponent;
