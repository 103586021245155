function FilterSimpleElementComponent({ label, component, isSelected, onChange}) {

  const changeVal = (val) => {
    if(!!onChange){
      onChange(val)
    }
  }

  return (
    <div className="margin-bottom-mx padding-left-xl display-flex col-11 filter-min-width">
      <div className={`margin-right-sm label filter-label full-width ${!!onChange ? "cursor-pointer" : ""} ${isSelected > 0 ? "selected-label" : ""}`} onClick={(e) => changeVal(!isSelected)}>{label}</div>
      <div className="">{component}</div>
    </div>
  );
}

export default FilterSimpleElementComponent;
