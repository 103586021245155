
import AutoCompleteDirectiveComponent from "./AutoCompleteDirectiveComponent";
import { useI18nContext } from "../i18n/context/context";


function ItemsDirectiveComponent({items, setItems, options, className}){
    const { translate, language } = useI18nContext();


    return (
        <>
            {!!items && 
                [...(items ?? []), { text: '', id: '' }].map((item, idx) => (
                    <div key={idx} className="edit-items-item-directive">
                      <AutoCompleteDirectiveComponent 
                        value={item}
                        onChange={(val) => setItems(idx, val)} 
                        parentElementId="modalEditBodyContainer"
                        options={options}
                        placeholder={translate("item")}
                      />
                    </div>)
                  )
            }
        </>
    )
}

export default ItemsDirectiveComponent;