import { useI18nContext } from '../../../i18n/context/context';
import QuillEditor from '../QuillEditor';
import Container from 'rsuite/Container';
import Button from 'rsuite/Button'
import { useToaster, Message } from 'rsuite';
import LoaderDirectiveComponent from '../../../directives/LoaderDirectiveComponent';
import { useEffect, useState } from 'react';
import ToggleWithLabel from '../../ToggleWithLabel';
import { useQuillEditor } from '../useQuillEditor';
import { ApiLibrary } from '../../../helpers/api/ApiLibrary';

const DescriptionEditorComponent = ({restaurantCode, checkChangeBookmark, setAnswere, setAskQuestion, extraData}) => {
  const { quillRef, getContent, setContent, setDefContent, quill } = useQuillEditor();
  const toaster = useToaster();
  const { language, translate } = useI18nContext();
  const [isEnabled, setEnabled] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [translationId, setTranslationId] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const check = async (setAnswere, setAskQuestion) => {
    setIsLoading(true)
    let response = await new ApiLibrary(language).restaurants.description.get(restaurantCode, language);
    setIsLoading(false)
    let actualData = getContent()
    let verifyData = !!response?.content && response?.content != '<p><br></p>' ? response.content : ""
    let veifyActualData = actualData != '<p><br></p>' ? actualData : ""
    if(veifyActualData != verifyData){
      setAskQuestion(true)  
    }
    else{
      setAnswere(true)
    }
    
  }

  useEffect(() => {
    if(checkChangeBookmark && setAskQuestion){
      check(setAnswere, setAskQuestion)
    }
  }, [checkChangeBookmark, setAskQuestion])

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true)
        const description = await new ApiLibrary(language).restaurants.description.get(restaurantCode, language);
        setIsLoading(false)
        setTranslationId(description?.id);
        setDefContent(description?.content);
      } catch(error) {
        throw new Error('No description available!');
      }
    }

    if(setContent){
      loadData();
    }
  }, [setContent, language])

  const handleSave = async () => {
    try {
      setIsLoading(true)
      if(extraData.defaultLang == language){
        await new ApiLibrary(language).restaurants.description.updateOrCreate(restaurantCode, {
          name: 'test',
          content: getContent()
        })
      }
      else{
        await new ApiLibrary(language).restaurants.general.fixTranslation({id: translationId, content: getContent()})
      }
      setIsLoading(false)
      setCanSave(false)
      setAnswere(true)
      toaster.push(
        <Message showIcon type="success" closable>
          {translate("saveChangesSuccess")}
        </Message>, 
        { placement: "topEnd", duration: 5000 }
      )
    } catch(error) {
      setIsLoading(false);
      toaster.push(
        <Message showIcon type="error" closable>
          {translate("somethingWrong")}
        </Message>, 
        { placement: "topEnd", duration: 5000 }
      )
    }
  }

  if (!restaurantCode) {
    return 'No restaurant selected';
  }

  return (
    <Container className="quill-editor-container">
      {isLoading &&
          <LoaderDirectiveComponent />
        }
      <div>
        <QuillEditor
          quillRef={quillRef}
        />
      </div>
      <div id="buttonSaveContainer" className='save-button-container'>
        <Button
          color="green"
          appearance="primary"
          onClick={() => handleSave(getContent())}
          // disabled={!canSave}
        >
          {translate(extraData.defaultLang == language ? 'save' : 'fixTranslation')}
        </Button>
      </div>
    </Container>
  );
};

export default DescriptionEditorComponent;
