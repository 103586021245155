import "./InputDirectiveStyles.css";
import { Radio } from 'rsuite';

function SelectRadioDirective({list, selectedIndex, selectedCode, selectItem}){
    return (
        <div className="selcted-radio">
            {!!list &&
                list.map((item, idx) => (
                    <div key={idx} onClick={() => selectItem(item, idx)}>
                        <Radio checked={(idx == selectedIndex && !selectedCode) || (item.code == selectedCode && !selectedIndex)}> {item.value}</Radio>
                    </div>
                ))
            }
        </div>
    )
}

export default SelectRadioDirective;