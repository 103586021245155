/* eslint-disable react-hooks/exhaustive-deps */
import "../../App.css";
import "../../css/dish.css";
import "../../css/global.css";
import "../../css/admin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import NavBarScrollAdminComponent from "../../components/base/head/NavBarScrollAdminComponent";
import useScreenDetector from "../../hooks/useScreenDetector";
import { useI18nContext } from "../../i18n/context/context";
import AdminMenuComponent from "../../components/admin/menu/AdminMenuComponent";
import { useNavigate, useParams } from 'react-router-dom';
import AdminPicturesComponent from "../../components/admin/pictures/AdminPicturesComponent";
import AdminBasicComponent from "../../components/admin/basic/AdminBasicComponent";
import DescriptionEditorComponent from "../../components/admin/description/DescriptionEditorComponent";


function MyPage({ OpenEditModal, Restaurant, handleChange, handleImageChange, setCheckChangeBookmark, checkChangeBookmark, setAnswere, setAskQuestion, watchChanges, afterQuestion, setAfterQuestion }) {
  const { translate, language } = useI18nContext();
  const [restaurant, setRestaurant] = useState(null);
  const { restaurantCode } = useParams();
  
  const { sizeScreen } = useScreenDetector();
  const [tabs, setTabs] = useState([]);
  const [activeTab, SetActiveTab] = useState(null);

  useEffect(() => {
    const mTabs = ['menu', 'basicData', 'description', 'pictures'];
    setTabs(mTabs);
    if (!mTabs.includes(activeTab) || !activeTab) {
      SetActiveTab(mTabs[0]);
    }

  }, [sizeScreen])

  useEffect(() => {
    if(!!Restaurant){
      setRestaurant(Restaurant)
    }
    else{
      setRestaurant({
        id: null,
        code: "NewRestaurant",
        restaurantBasicData: {
          name: null
        },
        pictures: [],
        restaurantMenus: [{
          isActive: true,
          name: translate("default"),
          cards: [{
            id: null,
            code: "specialEmptyCard",
            language: language,
            name: translate("specialEmptyCard"),
            filters: {
              category: false,
              size: false,
              price: false,
              includedItems: false,
              excludedItems: false,
              allergens: false,
              isLactoseFree: false,
              isGlutenFree: false,
              isVegan: false,
              isVegetarian: false
            },
            groups: [{
              id: 0,
              code: "specialEmptyGroup",
              language: language,
              name: translate("specialEmptyGroup"),
              items: []
            }]
          }]
        }]
      })
    }
  }, [Restaurant])

  const onRestaurantChange = (restaurantUpdate) => {
    handleChange(restaurantUpdate);
  };

  return (
    <div>
      <>
      <div id="header">
      { !!tabs &&
              <div className="col-12 display-flex fix-margin-under padding-top-sx">
                <div className="left-header-style">
                  <div className="fake-field-class"></div>
                </div>
                <div className="center-header-style">
                  <NavBarScrollAdminComponent
                    items={tabs}
                    setSelectedMenuItem={SetActiveTab}
                    translate={translate}
                    DefActiveTab={activeTab}
                    watchChanges={watchChanges}
                    canChangeBookmark={!checkChangeBookmark}
                    setAskQuestion={setAskQuestion}
                    afterQuestion={afterQuestion}
                    setAfterQuestion={setAfterQuestion}
                  />
                </div>
              </div>
            }
      </div>
          {activeTab === "menu" && <AdminMenuComponent OpenEditModal={OpenEditModal} restaurantMenus={restaurant.menus} restaurantId={restaurant?.id} extraData={{currencies: restaurant.extraConfig.currencies, defaultLang: restaurant.defaultLang }} />}
          {activeTab === 'pictures' && <AdminPicturesComponent restaurantPictures={restaurant.pictures} restaurantId={restaurant?.id} disabled={restaurant.defaultLang != language} />}
          {activeTab === 'basicData' && <AdminBasicComponent handleImageChange={handleImageChange} checkChangeBookmark={checkChangeBookmark} setCheckChangeBookmark={setCheckChangeBookmark} restaurant={restaurant} handleChange={onRestaurantChange} />}
          {activeTab === 'description' && <DescriptionEditorComponent checkChangeBookmark={checkChangeBookmark} setAnswere={setAnswere} setAskQuestion={setAskQuestion} restaurantCode={restaurantCode} extraData={{defaultLang: restaurant.defaultLang }} />}
        </>
    </div>);
}

export default MyPage;
