import PropTypes from 'prop-types';
import InputDirectiveComponent from '../../../directives/InputDirectiveComponent';
import { useI18nContext } from '../../../i18n/context/context';

function SearchComponent ({ SetSearchFunction }) {
  const { translate, language } = useI18nContext();
  return (
    <div>
      <div className="padding-left-md padding-right-md">
        <InputDirectiveComponent
          SetValue={(val) => SetSearchFunction(val)}
          isSearch={true}
          clearCallback={() => SetSearchFunction('')}
          placeholder={translate("searchByName")}
        />
      </div>
    </div>
  );
}

SearchComponent.propTypes = {
  SetSearchFunction: PropTypes.func.isRequired,
  delay: PropTypes.number.isRequired
};

export default SearchComponent;
