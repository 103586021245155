import "./ModalEdit.css";
import { IoClose } from "react-icons/io5";
import vegetarian from "../../assets/images/vegetarian-icon.png";
import vegan from "../../assets/images/vegan-icon.png";
import lactoseFree from "../../assets/images/lactose-free-icon.png";
import glutenFree from "../../assets/images/gluten-free.png";
import React, { useMemo, useRef } from "react";
import IconWithTooltipComponent from "../../directives/IconWithTooltipComponent";
import { useI18nContext } from '../../i18n/context/context';
import CloudinaryImage from '../CloudinaryImage';
import VideoPlayerComponent from "../base/VideoPlayerComponent";
import DishPrices from '../dish/components/DishPrices';
import DishItemPriceComponent from "../dish/components/DishItemPriceComponent";
import { Loader } from "rsuite";

function ModalItemVerticalComponent({dish, handleClose}) {

  const myRef = useRef(null);
  
  const { translate } = useI18nContext();

  const getImage = useMemo(() => {
    if(!dish || !dish.urlFirstPhoto)
      return (<></>)
    if(dish.urlFirstPhoto.slice(-4) == ".jpg" || dish.urlFirstPhoto.slice(-4) == ".png"){
      return (
        <div className="modal-vertical-item-picture-container">
          <div className="item-vertical-image-loader">
            <Loader size="lg" className='dropzone-icon' />
          </div>
          {dish.urlFirstPhoto.includes('cloudinary') ? (
          <CloudinaryImage
            alt={dish.dishName}
            src={dish.urlFirstPhoto}
            cropMode="fill"
            className="modal-vertical-item-img-style"
            height={1000}
            width={1000}
          />
        ) : (
          <img
            alt={dish.dishName}
            src={dish.urlFirstPhoto}
            className="modal-vertical-item-img-style"
          />
        )}
        </div>
      )
    }
    else{
      return (
        <div className="modal-vertical-item-picture-container">
          <div className="item-vertical-image-loader">
            <Loader size="lg" className='dropzone-icon' />
          </div>
          <VideoPlayerComponent
            id="demo-player"
            publicId={dish.urlFirstPhoto}
            className="modal-vertical-item-video-style"
            classNameContainer="modal-vertical-item-img-container"
            muted
          />
        </div>
      )
    }
  }, [dish])

  return (
    <>
    <div className="modal-vertical-close-button-container">
                <IoClose
                  className="modal-vertical-close-button"
                  onClick={handleClose}
                />
            </div>
      <div className="item-vertical-modal-style">
        
        <div className="item-vertical-modal-details-style" ref={myRef}>
        
          <div className="item-details-inside-style">
            
            {getImage}
            <div className="modal-item-vertical-details">
              <div className="modal-item-name-style">{dish.dishName}</div>
              {!!dish.description && 
                <div className="model-item-description-desc-style">
                  <span>{dish.description}</span>
                </div>
              }
              {!!dish.dishType && 
              <div className="modal-item-element-style">
              <span className="modal-item-label">{translate("type")}</span>
                <span className="modal-item-description-style">
                  {dish.dishType?.text}
                </span>
              </div>
            }
              {!!dish.dishItems && dish.dishItems.length > 0 &&
              <div className="modal-item-element-style">
                <span className="modal-item-label">{translate("elements")}</span>
                  <span className="modal-item-description-style">
                  {dish.dishItems.map(({text}, index) => (
                    <span key={index}>{text}{index < dish.dishItems.length-1 && ","} </span>
                  ))}
                  </span>
                </div>
              }
              {!!dish.allergens && dish.allergens.length > 0 && 
                <div className="modal-item-element-style">
                  <span className="modal-item-label">{translate("allergens")}</span>
                  <span className="modal-item-description-style">
                    {dish.allergens.map((item, index) => (
                      <span key={index}>{translate(item)}{index < dish.allergens.length-1 && ","} </span>
                    ))}
                  </span>
                </div>
              }
              { (dish.isVegetarian || dish.isVegan || dish.isLactoseFree || dish.isGlutenFree) &&
                <div className="modal-item-element-style">
                  {dish.isVegetarian && 
                  <span className="margin-right-sm">
                    <IconWithTooltipComponent icon={vegetarian} text={translate("vegetarian")} parentRef={myRef} />
                  </span>
                }
                {dish.isVegan && 
                  <span className="margin-right-sm">
                    <IconWithTooltipComponent icon={vegan} text={translate("vegan")} parentRef={myRef} />
                  </span>
                }
                {dish.isLactoseFree && 
                  <span className="margin-right-sm">
                    <IconWithTooltipComponent icon={lactoseFree} text={translate("lactoseFree")} parentRef={myRef} />
                  </span>
                }
                {dish.isGlutenFree && 
                  <span className="margin-right-sm">
                    <IconWithTooltipComponent icon={glutenFree} text={translate("glutenFree")} parentRef={myRef} />
                  </span>
                }
                </div>
              }
              {/* <DishPrices
                className="modal-item-price-style display-flex"
                prices={dish.prices}
                variants={dish.priceVariants}
              /> */}
              <DishItemPriceComponent prices={dish.prices} priceVariants={dish.priceVariants} className="modal-item-element-price-style" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalItemVerticalComponent;
