import { useState, useEffect, useCallback, useMemo } from "react";
import DishItemsListPerRestaurantComponent from "../dish/DishItemsListPerRestaurantComponent";
import DishItemsListPerRestaurantMenuComponent from "../dish/DishItemsListPerRestaurantMenuComponent";
import HomeRestaurantTabComponent from "./HomeRestaurantTabComponent";
import useScreenDetector from "../../hooks/useScreenDetector";
import DescriptionComponent from "../base/center/DescriptionComponent";
import PicturesComponent from "../base/center/PicturesComponent";
import NavBarScrollComponent from "../base/head/NavBarScrollComponent";
import HomeRestaurantPanelCardComponent from "./HomeRestaurantPanelCardComponent";
import { useDishesSort,DishesSortByType } from "../../hooks/dishes/useDishesSort";
import { useDishesSearch } from "../../hooks/dishes/useDishesSearch";
import { useI18nContext } from "../../i18n/context/context";
import { useDishesFilter } from "../../hooks/dishes/useDishesFilter";
import { ApiLibrary } from "../../helpers/api/ApiLibrary";
import { Sort } from "../../helpers/arrays/sorting";
import logoPictureSefoo from "../../assets/images/logo/sefoo.svg"
import { FaChevronRight } from "react-icons/fa";

function HomeRestaurantPanel({ OpenModal, restaurantCode }) {
  const { translate, language, setRestaurantLanguages } = useI18nContext();
  const [restaurant, setRestaurant] = useState(null);
  const [rawDishesData, setRawDishesData] = useState(null);
  const { filter, filters } = useDishesFilter('PLN');
  const { sort, sortBy, setSortBy, sortByOptions } = useDishesSort();
  const [filtersList, setFiltersList] = useState(null);

  const { search, searchBy, setSearchBy } = useDishesSearch();
  const [selectedMenu, setSelectedMenu] = useState(null);

  const calculateItems = useCallback((items) => {
    if (!items) {
      return;
    }

    return items.reduce((categorizedDishes, dish) => {
      const category = dish.category?.text ?? translate('noDishType');

      if (!categorizedDishes[category]) {
        return {
          ...categorizedDishes,
          [category]: [dish]
        }
      }

      return {
        ...categorizedDishes,
        [category]: [
          ...categorizedDishes[category],
          dish
        ]
      }
    }, {});
  }, [language]);

  useEffect(() => {
    const fetchData = async() => {
      const response = await new ApiLibrary(language).restaurants.general.getByCode(restaurantCode);

      if (!response.data) {
        window.location.href = window.location.origin;
        return;
      }
      setRestaurantLanguages(response?.data?.extraConfig.langs.concat(response?.data?.defaultLang));    

      setRestaurant(response.data);

      if (!selectedMenu || !response.data.menus[0]?.catalogs.map(x => x.name).some(x => x == selectedMenu?.name)) {
        setSelectedMenu(response.data.menus[0]?.catalogs.find(x => x.id == selectedMenu?.id) ?? response.data.menus[0]?.catalogs[0])
      }
    };

    if (restaurantCode) {
      fetchData();
    }
  }, [restaurantCode, language]);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async() => {
      setRawDishesData(null);
      setFiltersList(null);

      const [dishesResponse, filtersResponse] = await Promise.all([
        new ApiLibrary(language).menu.cards.getFlatDishesList(selectedMenu.id, {signal: abortController.signal}),
        new ApiLibrary(language).menu.cards.getFiltersOfCard(selectedMenu.id, {signal: abortController.signal})
      ]);

      if (dishesResponse.data) {
        setRawDishesData(dishesResponse.data);
      }

      if (filtersResponse.data) {
        setFiltersList(filtersResponse.data);
      }
    };

    if (selectedMenu) {
      fetchData();
    }

    return () => {
      abortController.abort('Aborted');
    }
  }, [selectedMenu, language])

  const { sizeScreen } = useScreenDetector();
  const [tabs, setTabs] = useState([]);
  const [activeTab, SetActiveTab] = useState(null);

  const setActiveTabFunction = (val) => {
    SetActiveTab(val)
  }


  useEffect(() => {
    const mTabs = ['menu'];
    mTabs.push('basicData');

    if(!!restaurant && !!restaurant.description){
      mTabs.push('description');
    }

    if (!!restaurant && !!restaurant.medias && !!restaurant.medias.visible) {
      mTabs.push('pictures');
    }

    setTabs(mTabs);
    if (!mTabs.includes(activeTab) || !activeTab) {
      setActiveTabFunction(mTabs[0]);
    }
  }, [sizeScreen, restaurant])


  const prepareData = useCallback((filters, searchBy, sortBy) => {
    if (!rawDishesData) {
      return;
    }

    let sortRes = rawDishesData;
    if (rawDishesData) {
      if (filters) {
        sortRes = filter(sortRes, filters);
      }
      if (searchBy) {
        sortRes = search(sortRes, searchBy);
      }
      if (sortBy) {
        sortRes = sort(sortRes, sortBy, filters);
      }
    }

    return sortRes;
  }, [rawDishesData]);

  const dishes = useMemo(() => {
    return prepareData(filters, searchBy, sortBy);
  }, [rawDishesData, sortBy, searchBy, filters]);

  const categorizedDishes = useMemo(() => {
    return calculateItems(dishes);
  }, [dishes, searchBy, filters]);

  const [menuItemsRefs, setMenuItemsRefs] = useState([]);
  const GetItemsRefs = (refs) => {
    setMenuItemsRefs(refs);
  };

  const handleCardChange = (menuName) => {
    if (menuName === selectedMenu.name) {
      return;
    }
    setSelectedMenu(restaurant?.menus[0]?.catalogs.find(({ name }) => menuName === name));
    setRawDishesData(null);
  }

  const sortedCards = useMemo(() => (
    restaurant?.menus[0].catalogs.sort((a, b) => Sort.numericAsc(a.order, b.order)) ?? []
  ), [restaurant?.menus]);

  const getHeaderPictures = useMemo(() => {
      if(!restaurant || !restaurant.mainPhotoUrl)
        return (<></>)
      return (
        <img src={restaurant.mainPhotoUrl} className="restaurant-selected-backgruond-picture" />
      )
    }, [restaurant])
    
    const getLogoPictures = useMemo(() => {
      if(!restaurant)
        return (<></>)
      if(!restaurant.logoPhotoUrl)
        return (<>
          <div className={`restaurant-picture-container ${!restaurant?.mainPhotoUrl ? "restaurant-picture-container-without-header" : ""}`}>
                                                                <img src={logoPictureSefoo} className="restaurant-picture" />
                                                              </div>
        </>)
      return (
        <div className={`restaurant-picture-container ${!restaurant.mainPhotoUrl ? "restaurant-picture-container-without-header" : ""}`}>
          <img src={restaurant.logoPhotoUrl} className="restaurant-picture" />
        </div>
      )
    }, [restaurant])
    

  return (
    <div>
      <div className="home-panel-body">
        <div className="display-flex">
            <div className="home-panel-header">
                <div className="">
                    {getHeaderPictures}
                </div>
                <div className="display-flex">
                    {getLogoPictures}
                    <div className="restaurant-name-cointener">
                        <div className="restaurant-name-style">
                        {restaurant?.restaurantName}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="home-panel-body-inside">
        <div id="header" className="padding-bottom-less">
          { !!tabs &&
            <div className="col-12 display-flex fix-margin-under home-restaurant-panel-margin home-header-tabs">
              <div className="center-header-style">
                <NavBarScrollComponent
                  items={tabs}
                  setSelectedMenuItem={setActiveTabFunction}
                  translate={translate}
                  DefActiveTab={activeTab}
                />
              </div>
            </div>
          }
        </div>
        <div id="restaurantBody" className="col-12">
          {(activeTab === 'description' || activeTab === 'pictures' || activeTab === 'basicData') && <div className="">
            {
              activeTab === 'description' && <DescriptionComponent content={restaurant.description.content} />
            }
            {
              activeTab === 'pictures' && <PicturesComponent items={restaurant.medias.items.map(({url}) => url)} OpenModal={OpenModal} />
            }
            {activeTab === 'basicData' && (
                <div className="margin-top-mx padding-left-md padding-right-md">
                  {!!restaurant && (
                    <HomeRestaurantTabComponent
                      translate={translate}
                      restaurant={restaurant}
                      OpenModal={OpenModal}
                    />
                  )}
                </div>
              )}
          </div>}
          { activeTab === 'menu' && 

          <HomeRestaurantPanelCardComponent activeTab={activeTab}
                          cards={sortedCards.map(({ name }) => name)}
                          SetActiveCard={handleCardChange}
                          activeCard={selectedMenu?.name}>
            <div>
              {
                  <div className="">
                    {sortBy !== DishesSortByType.GROUP_SORT
                      ? <div className="margin-top-sx">
                        <DishItemsListPerRestaurantComponent
                          translate={translate}
                          OpenModal={OpenModal}
                          dishes={dishes}
                        />
                      </div>
                      : <div className="margin-top-sm"><DishItemsListPerRestaurantMenuComponent
                        translate={translate}
                        OpenModal={OpenModal}
                        dishes={categorizedDishes}
                        GetItemsRefs={GetItemsRefs}
                      /></div>
                    }
                  </div>
              }
            </div>
          </HomeRestaurantPanelCardComponent>
          }
        </div>
        {/* { !!tabs && !!restaurant &&
            <div className="col-12 display-flex fix-margin-under home-restaurant-panel-margin home-footer-tabs">
              <div className="center-header-style">
                <NavBarScrollComponent
                  items={tabs}
                  setSelectedMenuItem={setActiveTabFunction}
                  translate={translate}
                  DefActiveTab={activeTab}
                />
              </div>
            </div>
          } */}
        </div>
      </div>
    </div>
  );
}

export default HomeRestaurantPanel;
