import React, { useEffect, useMemo, useState } from "react";
import { Input, useToaster, Checkbox, Toggle } from 'rsuite';
import SelectDirectiveComponent from "../../../../directives/SelectDirectiveComponent";
import AutoCompleteDirectiveComponent from "../../../../directives/AutoCompleteDirectiveComponent";
import { useI18nContext } from "../../../../i18n/context/context";
import { useAdminApiContext } from '../../../../hooks/admin/context';
import { ApiLibrary } from '../../../../helpers/api/ApiLibrary';
import { Allergen } from '../../../../helpers/formatting';
import MenuItemEditPriceComponent from "./MenuItemEditPriceComponent";
import MenuItemEditPriceVariantComponent from "./MenuItemEditPriceVariantComponent";
import TooglerDirectiveComponent from "../../../../directives/TooglerDirectiveComponent";
import LoaderDirectiveComponent from "../../../../directives/LoaderDirectiveComponent";
import currencies from '../../../../datas/currencies.json'
import ItemsDirectiveComponent from "../../../../directives/ItemsDirectiveComponent";
import MenuItemEditDropzoneComponent from "./MenuItemEditDropzoneComponent";


function MenuItemEditDefComponent({ model, extraData, setChanges, setCanSave, canSave, validModel }) {
  const { translate, language } = useI18nContext();
  const toaster = useToaster();
  const { state: { selectedCard } } = useAdminApiContext();
  const [filtersList, setFiltersList] = useState(null);
  const [myModel, setMyModel] = useState({...model, 
    dishName: model.dishName?.items?.find(x => x.lang == language)?.text, 
    description: model.description?.items?.find(x => x.lang == language)?.text,
    dishItems: model.dishItems?.map(x => x?.items?.find(y => y.lang == language) ),
    dishType: model?.dishType?.items?.find(x => x.lang == language),
    priceVariants: model?.priceVariants?.map(x => {
      return{
        id: x.id,
        order: x.order,
        prices: x.prices,
        variant: x.variant?.items?.find(y => y.lang == language)
      }
    }),
    priceType: !!model?.priceVariants && model?.priceVariants.length > 0 ? 'pricesVariants' : 'pricesOneVariant'});
  const [myCanSave, setMyCanSave] = useState(false)
  const [count, setCount] = useState(1)
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async() => {
      let response = await new ApiLibrary(language).dictionary.getDictionariesByCodes({
        codes: ["dishType", "dishPriceVariant", "dishItem"],
        restaurantId: undefined,
        additionalId: selectedCard.id
      })

      if (!response.data) {
        return;
      }

      setFiltersList(response.data);
    };

    if (selectedCard) {
      fetchData();
    }
  }, [selectedCard, language])

  useEffect(() => {
    if(!!myModel){
      setCanSave(!!myModel?.dishName && (!validModel || !validModel?.map(x => x.toLowerCase()).includes(myModel?.dishName.toLowerCase())))
      setMyCanSave(!!myModel?.dishName && (!validModel || !validModel?.map(x => x.toLowerCase()).includes(myModel?.dishName.toLowerCase())))
    }
  }, [myModel])
  
  const availableDishTypes = useMemo(() => (
    filtersList?.dishType ?? []
  ), [filtersList]);

  const availableDishItems = useMemo(() => (
    filtersList?.dishItem ?? []
  ), [filtersList]);

  const availableVariants = useMemo(() => (
    filtersList?.dishPriceVariant ?? []
  ), [filtersList]);

  const allergens = useMemo(() => (
    Object.values(Allergen)
      .map((value) => ({ value, text: translate(value) }))
      .filter(({ value }) => !myModel?.allergens.includes(value))
  ), [myModel]);

  const onChangeItemsTextValue = (idx, val) => {
    const dishItems = [...myModel.dishItems];
    if(dishItems[idx] != null) {
      dishItems[idx] = val;
    } else {
      dishItems.push(val)
    }

    const updatedModel = {
      ...myModel,
      dishItems: dishItems.filter(x => !!x)
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  const onChangeAllergensTextValue = (idx, val) => {
    const allergens = [...myModel.allergens];
    if(idx != null) {
      allergens[idx] = val;
    } else {
      allergens.push(val)
    }

    const updatedModel = {
      ...myModel,
      allergens: allergens.filter(x => !!x)
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  const onChangeDishTypeValue = (val) => {
    const updatedModel = {
      ...myModel,
      dishType: val
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  const changeValue = (field, val) => {
    let thisModel = myModel;
    thisModel[field] = val;
    setMyModel(thisModel);
    setChanges(thisModel);
    setCount(count+1)
  }

  const setPhotoUrl = (url) => {
    setMyModel(prevMyModel => { 
      let newModel = {
        ...prevMyModel, 
        urlFirstPhoto: url
      }
      setChanges(newModel);
      return newModel;
    });
  }


  const clearFunction = (idx) => {
    const prices = [...myModel.prices];
    prices[idx].value = null;
    prices[idx].convert = null;

    let resultPrices = prices.filter(x => !!x.value || !!x.convert);

    if(resultPrices.length){
      resultPrices[0].convert = null;
      if(!resultPrices[0].value){
        resultPrices[0].value = 1;
      }
    }

    const updatedModel = {
      ...myModel,
      prices: resultPrices
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  const setPriceFunction = (idx, val) => {
    const prices = [...myModel.prices];
    if(prices[idx] != null) {
      prices[idx].value = val;
    } else {
      prices.push({value: val, currency: ""})
    }

    const updatedModel = {
      ...myModel,
      prices: prices.filter(x => !!x.value || !!x.convert)
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  const setConvert = (idx, val) => {
    const prices = [...myModel.prices];
    if(prices[idx] != null) {
      prices[idx].convert = val;
    } else {
      prices.push({value: "", currency: "", convert: val})
    }

    const updatedModel = {
      ...myModel,
      prices: prices.filter(x => !!x.value || x.convert)
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  const setCurrencyFunction = (idx, curr) => {
    const prices = [...myModel.prices];
    prices[idx].currency = curr;

    const updatedModel = {
      ...myModel,
      prices: prices.filter(x => !!x.value || !!x.convert)
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  const setVariantFunction = (idx, variant) => {
    const myVariants = [...myModel.priceVariants];

    if(myVariants[idx] != null) {
      myVariants[idx] = variant;
    } else {
      myVariants.push(variant)
    }

    const updatedModel = {
      ...myModel,
      priceVariants: myVariants.filter(x => !!x.variant)
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  const setVariants = (priceVariants) => {
    const updatedModel = {
      ...myModel,
      priceVariants
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  const changePriceType = (val) => {
    const updatedModel = {
      ...myModel,
      priceType: val,
    }
    if(val == 'pricesOneVariant'){
      updatedModel.priceVariants = [];
    }
    else{
      updatedModel.prices = [];
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  return (
    <div className="edit-item-style">
      {isLoading &&
        <LoaderDirectiveComponent />
      }
      <div className="label-value-admin-style">
        <div className="admin-edit-item-label">
          {translate("dishName")}
        </div>
        <div className="edit-def-item-field">
          <Input className={` edit-item-field-text ${!myCanSave ? "edit-input-error" : ""} ${!!myModel?.dishName ? "sd-selected" : ""}`}
            placeholder={translate("dishName")}
            defaultValue={myModel?.dishName}
            onChange={(val, e) => {
              changeValue("dishName", val);
              setCanSave(!!val && (!validModel || !validModel.map(x => x.toLowerCase()).includes(val.toLowerCase())))
              setMyCanSave(!!val && (!validModel || !validModel.map(x => x.toLowerCase()).includes(val.toLowerCase())))
            }}
          />
          <Checkbox checked={!!myModel?.isTranslateName} onChange={(value, checked, event) => {changeValue("isTranslateName", checked)}}>{translate('isTranslateName')}</Checkbox>
        </div>
      </div>

      <div className="edit-item-field">
        {!!myModel &&
          <div className="admin-edit-dish-picture">
            <MenuItemEditDropzoneComponent model={myModel} photoUrl={myModel?.urlFirstPhoto} setPhotoUrl={setPhotoUrl} setIsLoading={setIsLoading} />
            {/* <DropzoneDirectiveComponent
              onUpload={setPhotoUrl}
              item={myModel?.urlFirstPhoto}
              emptyClassName="menu-item-edit-dropzone-empty"
              className="dropzone-def-style"
              playIfFocus={true}
              currentTime={0}
            />   */}
          </div>
        }
      </div>

      <div className="label-value-admin-style">
        <div className="admin-edit-item-label">
          {translate("description")}
        </div>
        <div className="edit-def-item-field">
          <Input as="textarea" rows={4} 
            className={`${!!myModel?.description ? "sd-selected" : ""}`}
            onChange={(val, e) => changeValue("description", val)}
            defaultValue={myModel?.description}
            placeholder={translate("description")} 
          />
        </div>
      </div>

      <div className="label-value-admin-style">
        <div className="admin-edit-item-label">
          {translate("type")}
        </div>
        <div className="edit-def-item-field">
          <AutoCompleteDirectiveComponent 
            clearable={false}
            parentElementId="modalEditBodyContainer"
            value={myModel?.dishType} 
            placeholder={translate("type")}
            onChange={(val) => onChangeDishTypeValue(val)} 
            options={filtersList?.dishType}
          />
        </div>
      </div>

      <div className="label-value-admin-style">
        <div className="admin-edit-item-label">
          {translate("dishItems")}
        </div>
        <div className="edit-def-item-field">
          <div className="edit-items-field">
            {!!count &&
              <ItemsDirectiveComponent items={myModel?.dishItems} setItems={onChangeItemsTextValue} options={availableDishItems}/>
              // [...(myModel?.dishItems ?? []), { text: '', value: `new_${generateGuid()}` }].map((item, idx) => (
              //   <div key={item?.value ?? idx} className="edit-items-item">
              //     <AutoCompleteDirectiveComponent 
              //       value={item}
              //       onChange={(val) => onChangeItemsTextValue(idx, val)} 
              //       parentElementId="modalEditBodyContainer"
              //       options={availableDishItems}
              //       placeholder={translate("item")}
              //     />
              //   </div>)
              // )
            }        
          </div>
        </div>
      </div>

      <div className="label-value-admin-style">
        <div className="admin-edit-item-label">
          {translate("allergens")}
        </div>
        <div className="edit-def-item-field">
          <div className="edit-items-field">
            {[...(myModel?.allergens ?? []), null].filter(x => {
              if(allergens.length > 0){
                return x || !x;
              }
              return !!x
            }).map((value, idx) => (
              <div key={value} className="edit-items-item">
                <SelectDirectiveComponent 
                  placeholder={translate("allergen")}
                  parentElementId="modalEditBodyContainer"
                  options={allergens}
                  // hiddenOptionsText={myModel.allergens}
                  onChange={(val) => onChangeAllergensTextValue(idx, val)}
                  selectedOption={value ? {text: translate(value), value} : null}/>
              </div>)
            )
            }        
          </div>
        </div>
      </div>

      <div className="edit-allergens-safe-field">
        <div className="edit-allergens-safe">
          <div className="margin-bottom-mx padding-left-xl display-flex col-11 filter-min-width margin-auto">
            <div className={`padding-right-xl label cursor-pointer allergen-toggle-label ${!!count && myModel?.isLactoseFree ? "selected-label" : ""}`} onClick={(e) => changeValue("isLactoseFree", !myModel?.isLactoseFree)}>{translate("isLactoseFree")}</div>
            <div className="">{!!count && !!myModel && <Toggle checked={myModel?.isLactoseFree} onChange={(val) => changeValue("isLactoseFree", val)} />}</div>
          </div>
        </div>
        <div className="edit-allergens-safe">
          <div className="margin-bottom-mx padding-left-xl display-flex col-11 filter-min-width margin-auto">
            <div className={`padding-right-xl label cursor-pointer allergen-toggle-label ${!!count && myModel?.isGlutenFree ? "selected-label" : ""}`} onClick={(e) => changeValue("isGlutenFree", !myModel?.isGlutenFree)} >{translate("isGlutenFree")}</div>
            <div className="">{!!count && !!myModel && <Toggle checked={myModel?.isGlutenFree} onChange={(val) => changeValue("isGlutenFree", val)} />}</div>
          </div>
        </div>
        <div className="edit-allergens-safe">
          <div className="margin-bottom-mx padding-left-xl display-flex col-11 filter-min-width margin-auto">
            <div className={`padding-right-xl label cursor-pointer allergen-toggle-label ${!!count && myModel?.isVegetarian ? "selected-label" : ""}`} onClick={(e) => changeValue("isVegetarian", !myModel?.isVegetarian)} >{translate("isVegetarian")}</div>
            <div className="">{!!count && !!myModel && <Toggle checked={myModel?.isVegetarian} onChange={(val) => changeValue("isVegetarian", val)} />}</div>
          </div>
        </div>
        <div className="edit-allergens-safe">
          <div className="margin-bottom-mx padding-left-xl display-flex col-11 filter-min-width margin-auto">
            <div className={`padding-right-xl label cursor-pointer allergen-toggle-label ${!!count && myModel?.isVegan ? "selected-label" : ""}`} onClick={(e) => changeValue("isVegan", !myModel?.isVegan)} >{translate("isVegan")}</div>
            <div className="">{!!count && !!myModel && <Toggle checked={myModel?.isVegan} onChange={(val) => changeValue("isVegan", val)} />}</div>
          </div>
        </div>
      </div>

      <div className="label-value-admin-style">
        <div className="admin-edit-item-label">
          {translate("buttonUrl")}
        </div>
        <div className="edit-def-item-field">
          <Input className={` edit-item-field-text ${!!myModel?.hrefUrl ? "sd-selected" : ""}`}
            placeholder={translate("buttonUrl")}
            defaultValue={myModel?.hrefUrl}
            onChange={(val, e) => {
              changeValue("hrefUrl", val);
            }}
          />
        </div>
      </div>

      <div className="label-value-admin-style">
        <div className="admin-edit-item-label">
          {translate("price")}
        </div>
        <div className="edit-def-item-field margin-bottom-xl">
          <div className="edit-price-field">
            <div className="price-radio-style">
              <TooglerDirectiveComponent isSelectedItemColor value={myModel?.priceType} setValue={changePriceType} items={[{text: translate("pricesOneVariant"), value: 'pricesOneVariant'}, {text: translate("pricesVariants"), value: 'pricesVariants'}]} />
            </div>

            <div className="price-body-container">
                {myModel?.priceType == "pricesOneVariant" && <MenuItemEditPriceComponent prices={myModel?.prices} currencies={extraData?.currencies ?? currencies} setPrice={setPriceFunction} setCurrency={setCurrencyFunction} clearFunction={clearFunction} setConvert={setConvert} />}
                

                {myModel?.priceType == "pricesVariants" && !!myModel?.priceVariants &&
                  <div className="margin-bottom-xl">
                    <MenuItemEditPriceVariantComponent
                      variants={availableVariants}
                      priceVariants={myModel?.priceVariants}
                      currencies={extraData?.currencies ?? currencies}
                      setVariant={setVariantFunction}
                      setVariants={setVariants}
                    />
                  </div>
                }
            </div>    
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuItemEditDefComponent;
