import vegetarian from "../../assets/images/vegetarian-icon.png";
import vegan from "../../assets/images/vegan-icon.png";
import lactoseFree from "../../assets/images/lactose-free-icon.png";
import glutenFree from "../../assets/images/gluten-free.png";
import React, { useMemo, useState, useRef, useLayoutEffect, useEffect } from "react";
import IconWithTooltipComponent from "../../directives/IconWithTooltipComponent";
import { useI18nContext } from '../../i18n/context/context';
import CloudinaryImage from '../CloudinaryImage';
import ModalItemComponent from "../modal/ModalItemComponent";
import VideoPlayerComponent from "../base/VideoPlayerComponent";
import DishItemPriceComponent from "./components/DishItemPriceComponent";
import useScreenDetector from "../../hooks/useScreenDetector";
import { Loader, Button } from "rsuite";


function DishItemForRestaurantComponent({ dish }) {
  const { translate } = useI18nContext();
  const [openItemModal, setOpenItemModal] = useState(false);
  const [renderedSizeScrren, setRenderedSizeScreen] = useState(0);
  const picRef = useRef(null);
  const priceRef = useRef(null);
  const detailsRef = useRef(null);
  const descRef = useRef(null);
  const nameRef = useRef(null);
  const detailsNextPhotoRef = useRef(null);
  const detailsUnderPhotoRef = useRef(null);
  const [versionStyle, setVersionStyle] = useState(0);

  const { sizeScreen } = useScreenDetector();

  const getImage = useMemo(() => {   
    if(!dish || !dish.urlFirstPhoto)
      return (<></>)
    if(dish.urlFirstPhoto.slice(-4) == ".jpg" || dish.urlFirstPhoto.slice(-4) == ".png"){
      return (
        <div className="">
          {dish.urlFirstPhoto.includes('cloudinary') ? (
            <CloudinaryImage
              src={dish.urlFirstPhoto}
              cropMode="fill"
              width={150}
              height={150}
              className="item-img-style"
            />
          ) : (
            <img
              src={dish.urlFirstPhoto}
              className="item-img-def-style"
            />
          )}
        </div>
      )
    }
    else{
      return (
        <div className='dish-picture-container'>
          <div>
            <VideoPlayerComponent
              id="demo-player"
              publicId={dish.urlFirstPhoto}
              className="dish-video-style"
              autoplay={false}
              playIfFocus={false}
              canPlay={false}
              currentTime={0}
              muted
            />
          </div>
        </div>
      )
    }
  }, [dish])

  const getDesc = useMemo(() => {
    if(!dish?.description){
      return (<></>)
    }
    return (
      <div ref={descRef}>
          <span className="item-element-style item-description-desc-style margin-bottom-sm">{dish.description}</span>
      </div>
    )
  }, [dish, versionStyle])

  const getDetails = useMemo(() => {
    return (
      <div ref={detailsRef}>
      {!!dish.dishType && 
                    <div className="item-element-style">
                    <span className="item-label">{translate("type")}</span>
                      <span className="item-description-style">{dish.dishType?.text}</span>
                    </div>
                }
                {!!dish.dishItems && dish.dishItems.length > 0 &&
                <div className="item-element-style">
                  <span className="item-label">{translate("elements")}</span>
                  <span className="item-description-style">
                    {dish.dishItems.map(({text}, index) => (
                      <span key={index}>{text}{index < dish.dishItems.length-1 && ","} </span>
                    ))}
                  </span>
                </div>
                }
                {!!dish.allergens && dish.allergens.length > 0 && 
                  <div className="item-element-style">
                    <span className="item-label">{translate("allergens")}</span>
                    <span className="item-description-style">
                      {dish.allergens.map((item, index) => (
                        <span key={index}>{translate(item)}{index < dish.allergens.length-1 && ","} </span>
                      ))}
                    </span>
                  </div>
                }
                { (dish.isVegetarian || dish.isVegan || dish.isLactoseFree || dish.isGlutenFree) &&
                  <div className="item-element-style">
                    {dish.isVegetarian &&
                      <span className="margin-right-sm">
                        <IconWithTooltipComponent icon={vegetarian} text={translate("vegetarian")} />
                      </span>
                    }
                    {dish.isVegan && 
                      <span className="margin-right-sm">
                        <IconWithTooltipComponent icon={vegan} text={translate("vegan")} />
                      </span>
                    }
                    {dish.isLactoseFree && 
                      <span className="margin-right-sm">
                        <IconWithTooltipComponent icon={lactoseFree} text={translate("lactoseFree")} />
                      </span>
                    }
                    {dish.isGlutenFree && 
                      <span className="margin-right-sm">
                        <IconWithTooltipComponent icon={glutenFree} text={translate("glutenFree")} />
                      </span>
                    }              
                  </div>
                }
                {!!dish.hrefUrl &&
                  <Button appearance="primary" size="sm" onClick={(e) => {e.stopPropagation(); window.open(dish.hrefUrl, '_blank')}}>{translate("goTo")}</Button>
                }
      </div>
    )
  }, [dish, versionStyle])

  const getPrice = useMemo(() => {
    return (
      <div ref={priceRef} className="price-font-style">
                    <DishItemPriceComponent  prices={dish.prices} priceVariants={dish.priceVariants} />
      </div>
    )
  }, [dish, versionStyle])


  const getDetailsNextToPhoto = useMemo(() => {
    return (
      <>
        {[0, 1, 2].includes(versionStyle) && getDesc}
        {[0, 1].includes(versionStyle) && getDetails}
        {[0].includes(versionStyle) && getPrice}
      </>
    )
  }, [dish, versionStyle])

  const getDetailsUnderPhoto = useMemo(() => {
    return (
      <>
        {[3].includes(versionStyle) && getDesc}
        {[2, 3].includes(versionStyle) && getDetails}
        {[1, 2, 3].includes(versionStyle) && getPrice}
      </>
    )
  }, [dish, versionStyle])


  useLayoutEffect(() => {
    if(Math.abs(renderedSizeScrren - sizeScreen[0]) > 100){
      setRenderedSizeScreen(sizeScreen[0]);
      let descHeight = descRef?.current?.clientHeight ?? 0;
      let detailsHeight = detailsRef?.current?.clientHeight ?? 0;
      let nameHeight = nameRef?.current?.clientHeight ?? 0;
      let picHeight = picRef?.current.clientHeight ?? 0;
      let priceHeight = priceRef?.current.clientHeight ?? 0;
      if(dish.prices?.length && priceHeight){
        priceHeight = 0;
      }
      let verStyle = (picHeight > nameHeight + descHeight + detailsHeight + priceHeight) ? 0 : (picHeight > nameHeight + descHeight + detailsHeight) ? 1 : (picHeight > nameHeight + descHeight) ? 2 : 3;
      setVersionStyle(verStyle)
      let priceVariantSelector = document.querySelector('.price-variant');
      if(priceVariantSelector){
        let variantStyle = window.getComputedStyle(priceVariantSelector);
        let minWidthPriceVariant = variantStyle.getPropertyValue('min-width');
        // let priceBody = priceRef?.current?.getElementsByClassName('price-variant-prices')
        let variantsContainer = priceRef?.current?.getElementsByClassName('price-variant-container')
        let variants = priceRef?.current?.getElementsByClassName('price-variant')
        let variantsInside = priceRef?.current?.getElementsByClassName('price-variant-inside')
        if(variantsInside){
          let maxWidth = Array.from(variantsInside).reduce((max, element) => {
            return Math.max(max, element.offsetWidth);
          }, parseInt(minWidthPriceVariant));
    
          Array.from(variants).forEach((item, idx) => {
            if(verStyle == 0){
              variantsContainer[idx].style.display = (detailsNextPhotoRef?.current?.getBoundingClientRect()?.width < maxWidth + 100) ? 'block' : 'flex';
            }
  
            if(verStyle > 0){
              variantsContainer[idx].style.display = (detailsUnderPhotoRef?.current?.getBoundingClientRect()?.width < maxWidth + 100) ? 'block' : 'flex';
            }
          })
        }
      }
    }
  }, [sizeScreen, versionStyle, renderedSizeScrren]); //scrollPosition

  return (
    <div>
      <div>
        {openItemModal &&
          <ModalItemComponent dish={dish} setOpenModal={() => setOpenItemModal(false)}/>
        }
      </div>
      <div className="item-def-style" onClick={() => setOpenItemModal(true)}>
        <div className="item-def-inside-style" onClick={() => setOpenItemModal(true)}>
          <div className="margin-auto" ref={picRef}>
            {!!dish && !!dish.urlFirstPhoto &&
              <div className="item-image-loader">
                <Loader size="md" className='dropzone-icon' />
              </div>
            }
            {/* {!!dish && !!dish.urlFirstPhoto && !(dish.urlFirstPhoto.slice(-4) == ".jpg" && dish.urlFirstPhoto.slice(-4) == ".png") &&
              <div className="item-image-play">
                <FaPlay size="sm" className="item-image-play-inside" />
              </div>
            } */}
            {getImage}
          </div>
          <div className={`item-def-details-style ${!dish.urlFirstPhoto ? "padding-left-sx-important" : ""}`}>
            <div ref={detailsNextPhotoRef} className="item-details-inside-style">
              <div ref={nameRef} className="item-name-style">{dish.dishName}</div>
              {/* {!!dish.description && !isDescUnderPhoto &&
                <div ref={descRef}>
                  <span className="item-element-style item-description-desc-style">{dish.description}</span>
                </div>
              } */}
              <div className="item-details-next-to-photo">
                {getDetailsNextToPhoto}
              </div>
            </div>
          </div>
        </div>
        <div ref={detailsUnderPhotoRef} className="item-details-under-photo">
          {getDetailsUnderPhoto}      
        </div>
      </div>
    </div>
  );
}

export default DishItemForRestaurantComponent;
