import React from "react";
import { InputGroup, InputNumber, Input } from 'rsuite';
import { IoClose } from "react-icons/io5";
import { useI18nContext } from "../../../../i18n/context/context";
import SelectDirectiveComponent from "../../../../directives/SelectDirectiveComponent";
import { MdCurrencyExchange } from "react-icons/md";
import { FaPencil } from "react-icons/fa6";


function MenuItemEditPriceComponent({ prices, currencies, setPrice, setCurrency, setConvert, clearFunction }) {
    const { translate, language } = useI18nContext();  


    const onChangePriceValue = (idx, val) => {
        setPrice(idx, val)
    }

    const onChangeCurrency = (idx, curr) => {
        setCurrency(idx, curr)
    }

    return (
        <div className="">
            {!!prices && 
                <div className="price-body-inside">
                  {[...prices, {value: "", currency: "", convert: false}].filter(x => {
                      if(currencies.filter(x => !prices.map(y => y.currency).includes(x)).length > 0){
                        return true;
                      }
                      return (!!x.value || !!x.convert)
                  }).map((item, idx) => (
                    <div className="price-single-full-element-edit" key={idx}>
                      <InputGroup className={`price-input ${(!!item.value || !!item.convert) ? "sd-selected" : ""}`}>
                        <Input
                          className={`${!!item.convert ? "cursor-default" : ""}`}
                          placeholder={translate("price")}
                          min={0}
                          type={!item.convert ? 'number' : 'text'}
                          disabled={!!item.convert}
                          value={(!!item.convert && !item?.value) ? '~' : item?.value} 
                          onChange={(val, e) => onChangePriceValue(idx, val)} step={0.01} />
                        <InputGroup.Addon className="input-number-clear">
                        {idx > 0 &&
                          <>
                            <MdCurrencyExchange  className={`sd-convert-icon ${!!item.convert ? "sd-convert-selected" : ""}`}
                                  onClick={(e) => {
                                    setConvert(idx, !item.convert)
                                  }} 
                              />
                            {/* {!!item.convert &&
                              <FaPencil  className="sd-clear-button"
                                  onClick={(e) => {
                                    setConvert(idx, false)
                                  }} 
                              />
                            } */}
                          </>
                        }
                        
                          {(!!item.value || !!item.convert) && 
                            <IoClose className="sd-clear-button"
                              onClick={(e) => {
                                clearFunction(idx)
                              }} 
                          />}
                        </InputGroup.Addon>
                      </InputGroup>
                      {(!!item.value || !!item.convert) &&
                        <div className="edit-price-currency-admin">
                          <SelectDirectiveComponent 
                          options={currencies.filter(x => !prices.map(y => y.currency).includes(x)).map(x => ({ text: x, value: x }))}
                          clearable={false}
                          parentElementId="modalEditBodyContainer"
                          defaultValue={currencies.filter(x => !prices.map(y => y.currency).includes(x))[0]}
                          placeholder={translate("currency")}
                          onChange={(val) => onChangeCurrency(idx, val)}
                          selectedOption={{text: item.currency, value: item.currency}}/>
                        </div>
                      }
                    </div>
                  ))}
                </div>
              }
        </div>
    );
}

export default MenuItemEditPriceComponent;
