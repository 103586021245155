import React, { useEffect } from "react";
import { useI18nContext } from "../i18n/context/context";
import LoaderDirectiveComponent from "../directives/LoaderDirectiveComponent";
import { useParams } from "react-router-dom";
import { ApiLibrary } from '../helpers/api/ApiLibrary';

function QrPage({ }) {
  const { language } = useI18nContext();
  const { restaurantCode } = useParams();

  useEffect(() => {
    const fetchData = async() => {
        new ApiLibrary(language).restaurants.general.getIp({restaurantCode: restaurantCode});
        window.location.href = window.location.origin.concat('/').concat(restaurantCode)
    };
    
    if (restaurantCode) {
        fetchData();
    }

  }, [restaurantCode])

  return (
    <>
        <LoaderDirectiveComponent />
    </>    
  );
}

export default QrPage;
