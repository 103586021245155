import "../../css/restaurant.css";
import React, { useMemo } from "react";
import MapStaticComponent from "../base/right/MapStaticComponent";
import { useOpeningHours } from '../../hooks/useOpeningHours';
import { useI18nContext } from '../../i18n/context/context';

function RestaurantTabComponent({ restaurant, OpenModal }) {
  const { translate, language } = useI18nContext();
  const openingHours = useOpeningHours(restaurant);

  const getHeaderPictures = useMemo(() => {
    if(!restaurant || !restaurant.mainPhotoUrl)
      return (<></>)
    return (
      <img src={restaurant.mainPhotoUrl} className="restaurant-selected-backgruond-picture" />
    )
  }, [restaurant])
  
  const getLogoPictures = useMemo(() => {
    if(!restaurant || !restaurant.logoPhotoUrl)
      return (<></>)
    return (
      <div className={`restaurant-picture-container ${!restaurant.mainPhotoUrl ? "restaurant-picture-container-without-header" : ""}`}>
        <img src={restaurant.logoPhotoUrl} className="restaurant-picture" />
      </div>
    )
  }, [restaurant])

  const getQrCodePictures = useMemo(() => {
    if(!restaurant || !restaurant.qrPhoto)
      return (<></>)
    return (
      <div className="restaurant-qr-container">
        <div className="restaurant-qr-picture-container">
          {/* <MdFullscreen className="qr-full-screen-icon" style={{pointerEvents: 'none'}} /> */}
          <img src={restaurant.qrPhoto} className="restaurant-qr-picture cursor-pointer" onClick={() => OpenModal(null, [restaurant.qrPhoto], 0)} />
        </div>
      </div>
    )
  }, [restaurant])

  return (
    <div>
      <div className="adding-right-mx">
        <div className="">
          {getHeaderPictures}
        </div>
        <div className="display-flex">
          {getLogoPictures}
          <div className="restaurant-name-cointener">
            <div className="restaurant-name-style">
              {restaurant.restaurantName}
            </div>
          </div>
        </div>
        <div className="">
          {getQrCodePictures}
          <div className="restaurant-info-details-cointener padding-bottom-sm">
            <div className="restaurant-details-element-style">
              {!!restaurant.restaurantType?.id && (
                <span className="restaurant-label">
                  {translate("restaurantType")}
                </span>
              )}
              {!!restaurant.restaurantType?.id && (
                <span className="restaurant-info-value">{restaurant.restaurantType?.items?.filter(x => x.lang == language && (!x.restaurantId || x.restaurantId == restaurant.id))?.sort((a, b) => { if (a.restaurantId === null) return 1; if (b.restaurantId === null) return -1; return a - b; })[0]?.text}</span>
              )}
            </div>
            <div className="restaurant-details-element-style">
              {!!restaurant.address && (
                <span className="restaurant-label">{translate("address")}</span>
              )}
              {!!restaurant.address && (
                <span className="restaurant-info-value">
                  {restaurant.address.fullAddress}
                  {/* {getFormattedAddress(restaurant.address)}
                  , {restaurant.address.city} */}
                </span>
              )}
            </div>
            <div className="restaurant-details-element-style">
              {!!restaurant.phones && restaurant.phones.length > 0 && (
                <span className="restaurant-label">{translate("phone")}</span>
              )}
              {!!restaurant.phones && restaurant.phones.length > 0 && 
                <span className="restaurant-info-value">
                  {restaurant.phones.map((item, index) => (
                    <div key={index}>
                       {item}{/*{index < restaurant.phones.length-1 && ","}  */}
                    </div>)
                  )}
                </span>
              }
            </div>
            <div className="restaurant-details-element-style">
              {!!restaurant.mail?.length && (
                <span className="restaurant-label">{translate("mail")}</span>
              )}
              {!!restaurant.mail?.length && <span className="restaurant-info-value">
                {restaurant.mail.map((item, idx) => (
                  <div key={idx}>
                    {item}
                  </div>
                ))}
                </span>}
            </div>
            <div className="restaurant-details-element-style">
              {!!restaurant.restaurantUrls && restaurant.restaurantUrls.length > 0 && (
                <>
                  <span className="restaurant-label">
                    {translate("restaurantUrl")}
                  </span>
                  {!!restaurant.restaurantUrls?.length && <span className="restaurant-info-value">
                    {restaurant.restaurantUrls.map((item, idx) => (
                      <div key={idx}>
                        <a href={item}>{item}</a>
                      </div>
                    ))}
                    </span>}
                </>
              )}
            </div>
            <div className="restaurant-details-element-style">
              {!!openingHours.hours && openingHours.hours.length > 0 && (
                <span className="restaurant-label">
                  {translate("openingHours")}
                </span>
              )}
              {!!openingHours.hours && (
                <>
                  <div className="opening-days"> 
                  {  
                    openingHours.hours.map((item, index) => 
                    (
                      <div key={index}> {translate(item.days[0])} {item.days.length == 2 && ", "} {item.days.length > 2 && " - "} {item.days.length > 1 && translate(item.days[item.days.length-1])}</div>
                    ))
                  }
                  </div>
                  <div> 
                  {  
                    openingHours.hours.map((item, index) => 
                    (
                      <div key={index}>{item.time}</div>
                    ))
                  }
                  </div>
                </>
              )}
            </div>
            {!!restaurant.location &&
              <div className="margin-top-xl">
                <MapStaticComponent positions={restaurant.location} color={restaurant.color} />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestaurantTabComponent;
