export const getFormattedAddress = (address) => {
  return `${address.street} ${[address.streetNumber, address.houseNumber].filter(number => number).join('/')}`;
};

export const getFormattedCurrency = (currencyCode) => {
  const currencyMapper = {
    [Currency.EUR]: 'EUR',
    [Currency.PLN]: 'PLN',
    [Currency.USD]: 'USD',
    [Currency.GBP]: 'GBP',
    [Currency.UAH]: 'UAH',
    [Currency.BRL]: 'BRL',
    [Currency.CZK]: 'CZK',
    [Currency.BGN]: 'BGN',
    [Currency.DKK]: 'DKK',
    [Currency.HUF]: 'HUF',
    [Currency.JPY]: 'JPY',
    [Currency.KRW]: 'KRW',
    [Currency.NOK]: 'NOK',
    [Currency.RON]: 'RON',
    [Currency.SEK]: 'SEK',
    [Currency.TRY]: 'TRY',
    [Currency.CNY]: 'CNY'
  };

  return currencyMapper[currencyCode] ?? '∅';
};

export const Currency = {
  EUR: 'EUR',
  PLN: 'PLN',
  GBP: "GBP",
  USD: "USD",
  UAH: "UAH",
  BRL: "BRL",
  CZK: "CZK",
  BGN: "BGN",
  DKK: "DKK",
  HUF: "HUF",
  JPY: "JPY",
  KRW: "KRW",
  NOK: "NOK",
  RON: "RON",
  SEK: "SEK",
  TRY: "TRY",
  CNY: "CNY"
};

export const Allergen = {
  MILK: 'allergen:milk',
  EGGS: 'allergen:eggs',
  FISH: 'allergen:fish',
  CRUSTACEAN_SHELLFISH: 'allergen:crustacean-shellfish',
  TREE_NUTS: 'allergen:tree-nuts',
  PEANUTS: 'allergen:peanuts',
  SOYBEANS: 'allergen:soybeans',
  SESAME: 'allergen:sesame',
  GLUTEN: "allergen:gluten",
  CELERY: "allergen:celery",
  MOLLUSCS: "allergen:molluscs",
  LUPINE: "allergen:lupine",
  MUSTARD: "allergen:mustard",
  SULFUR_DIOXIDE: "allergen:sulfur-dioxide"
}

export const generateGuid = () => {
  return Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
}