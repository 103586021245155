import "../../css/restaurant.css";
import React, { useEffect, useCallback, useState, useMemo } from "react";
import RestaurantResultItemComponent from "./RestaurantResultItemComponent";
import NavBarScrollFroMapItemComponent from "../base/head/NavBarScrollFroMapItemComponent";
import HomeDescriptionComponent from "../home/HomeDescriptionComponent";
import HomeItemPicturesComponent from "../home/HomeItemPicturesComponent";
import HomeDishItemsListPerRestaurantMenuComponent from "../home/HomeDishItemsListPerRestaurantMenuComponent";
import HomeRestaurantBasicDataComponent from "../home/HomeRestaurantBasicDataComponent";
import HomeRestaurantHeaderComponent from "../home/HomeRestaurantHeaderComponent";
import { useI18nContext } from '../../i18n/context/context';
import { ApiLibrary } from '../../helpers/api/ApiLibrary';

function RestaurantItemFromMapComponent({ restaurant, OpenModal }) {
  const goToRestaurant = () => {
    window.location.href = window.location.href.concat(
      restaurant.restaurantCode
    );
  }

  const mouseDownHandler = ( event ) => {
    if( event.button === 0 ) {
      window.location.href = window.location.href.concat(restaurant.restaurantCode);
    }
    if( event.button === 1 ) {
      window.open(window.location.href.concat(restaurant.restaurantCode));
    }
  }

  const { language } = useI18nContext();
  const [rawDishesData, setRawDishesData] = useState(null);
  const [resultRestaurant, setResultRestaurant] = useState(null)
  const [activeTab, SetActiveTab] = useState("basicData");
  const [tabs, setTabs] = useState(["basicData", "menu", "description", "pictures"])

  const GetItemsRefs = (refs) => {
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await new ApiLibrary(language).menu.cards.getFlatDishesList(restaurant.menus[0].id);

      if (!response.data) {
        return;
      }

      setRawDishesData(response.data);
    }
    
    if (restaurant.menus[0]) {
      fetchData();
    }
  }, [restaurant, language]);

  const calculateItems = useCallback((items) => {
    if (!items) {
      return;
    }

    let categoriesList = items
    .map((dish) => dish.category?.text)
    .filter((value, index, self) => self.indexOf(value) === index);
    let menuItems = {};
    categoriesList.map(
      (item) =>
        (menuItems[item] = items.filter((x) => x.category?.text == item)),
    );
    return menuItems;
  }, [])

  const categorizedDishes = useMemo(() => {
    return calculateItems(rawDishesData);
  }, [rawDishesData]);

  useEffect(() => {
    if(!!restaurant){
      setResultRestaurant(restaurant)
    }
  }, [restaurant])

  return (
    <div>
    {!!resultRestaurant && 
      <div key={resultRestaurant._id}>
        <div className="restaurant-map-item-style">
        <div className="restaurant-map-item-style cursor-pointer" onMouseDown={mouseDownHandler}>
          <HomeRestaurantHeaderComponent restaurant={resultRestaurant}/>
        </div>
        <NavBarScrollFroMapItemComponent 
                      items={tabs}
                      setSelectedMenuItem={SetActiveTab}
                      className="hm-menu-for-map-item"
                      />
          <div className="hm-item-elements">
          {
            activeTab == "basicData" && <HomeRestaurantBasicDataComponent restaurant={resultRestaurant} />
          }
          {
            activeTab == "description" && <HomeDescriptionComponent items={resultRestaurant.description.items} />
          }
          {
            activeTab == "pictures" && <HomeItemPicturesComponent items={resultRestaurant.pictures.items} OpenModal={OpenModal} />
          }
          { activeTab == "menu" &&
            !!categorizedDishes && (
              <HomeDishItemsListPerRestaurantMenuComponent
                className="hm-menu-items"
                OpenModal={OpenModal}
                dishes={categorizedDishes}
                GetItemsRefs={GetItemsRefs}
              />
              )
          }
          </div>
        </div>
      </div>}
    </div>
  );
}

export default RestaurantItemFromMapComponent;
