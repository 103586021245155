import React, { useState, useRef, useCallback, useEffect, useMemo, useLayoutEffect } from "react";
import { FaChevronLeft, FaChevronRight, FaPlus, FaInfo  } from "react-icons/fa";
import { FaPencil } from "react-icons/fa6";
import useScreenDetector from "../../../hooks/useScreenDetector";
import ModalEditComponent from "../../modal/ModalEditComponent";
import MenuGroupEditComponent from "./MenuGroupEditComponent";
import MenuItemsListAdminComponent from "./MenuItemsListAdminComponent";
import { useI18nContext } from "../../../i18n/context/context";
import { useAdminApiContext } from '../../../hooks/admin/context';
import { Sort } from '../../../helpers/arrays/sorting';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { HiTranslate } from "react-icons/hi";
import { ApiLibrary } from "../../../helpers/api/ApiLibrary";
import LoaderDirectiveComponent from "../../../directives/LoaderDirectiveComponent";
import DictionaryEditModalComponent from "../DictionaryEditModalComponent";
import DictionaryEditTextAreaModalComponent from "../DictionaryEditTextAreaModalComponent";
import { useToaster, Message, IconButton } from 'rsuite';
import { TbFilterSearch } from "react-icons/tb";


function NavBarGroupAdminComponent({ card, extraData, userId, setRunningToken, restaurantId, setIsEditInfo, isFilterActive, filterOpen, setFilterOpen, isActivInfo }) {
  const { translate, language } = useI18nContext();
  // const [groups, setGroups] = useState([])
  const [items, setItems] = useState([])
  // const [selectedObject, setSelectedObject] = useState(null)
  const [canSelectItem, setCanSelectItems] = useState(true)
  const [itemsRefs, setItemsRefs] = useState([]);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const {
    state: { categories = [], selectedCategory, selectedCard } = {},
    fetchCategories,
    setSelectedCategory,
    addCategory,
    removeCategory,
    updateCategory,
    changeCategoriesOrder
  } = useAdminApiContext();

  useEffect(() => {
    fetchCategories(card.id);
  }, [card.id])

  const ourRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0
  });

  const setArrowsInfo = useCallback((sliderMenu) => {
    setShowLeftArrow(sliderMenu.scrollLeft > 1);
    setShowRightArrow(sliderMenu.scrollWidth - sliderMenu.scrollLeft > sliderMenu.clientWidth + 1)
  }, [showLeftArrow, showRightArrow])

  const handleDragStart = useCallback((e) => {
    if (!ourRef.current) return;
    const sliderMenu = ourRef.current.children[0];
    const startX = e.pageX - sliderMenu.offsetLeft;
    const startY = e.pageY - sliderMenu.offsetTop;
    const scrollLeft = sliderMenu.scrollLeft;
    const scrollTop = sliderMenu.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    setIsMouseDown(true);
    document.addEventListener("mouseup", mouseDownOutsideComponent);
  }, [ourRef, mouseCoords, isMouseDown]);

  const handleDragEnd = useCallback((e) => {
    setIsMouseDown(false);
    setCanSelectItems(true);
    document.removeEventListener("mouseup", mouseDownOutsideComponent, false);
    if (!ourRef.current) return;
  }, [ourRef, isMouseDown]);

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const myItems = Array.from(categories);
    const [reorderedItem] = myItems.splice(result.source.index, 1);
    myItems.splice(result.destination.index, 0, reorderedItem);
    // setOrders(myItems)
    await changeCategoriesOrder(selectedCard.id, myItems.map(({id}) => id))
  }
  
  const handleDrag = useCallback((e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const sliderMenu = ourRef.current.children[0];
    const x = e.pageX - sliderMenu.offsetLeft;
    const y = e.pageY - sliderMenu.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const walkY = (y - mouseCoords.current.startY) * 1.5;
    sliderMenu.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    sliderMenu.scrollTop = mouseCoords.current.scrollTop - walkY;
    if(Math.abs(mouseCoords.current.scrollLeft - sliderMenu.scrollLeft) > 5){
      setCanSelectItems(false);
    }
  }, [isMouseDown, ourRef]);

  const handleWheel = useCallback((e) => {
    if (!ourRef.current || (ourRef.current.children[0].clientWidth >= ourRef.current.children[0].scrollWidth)) return;
    e.preventDefault()
    const sliderMenu = ourRef.current.children[0];
    sliderMenu.scrollLeft = sliderMenu.scrollLeft + (e.deltaY/5);
  }, [ourRef, showLeftArrow, showRightArrow]);

  const mouseDownOutsideComponent = useCallback((event) => {
    const el = ourRef?.current
    if (!el || el.contains(event.target)) {
      return;
    }
    handleDragEnd();
  }, [ourRef])

  useLayoutEffect(() => { 
    // document.scrollTo(0, 1);
    // document.scrollTo(0, 0);
  }, []);

  const clickLeft = () => {
    const sliderMenu = ourRef.current.children[0];
    sliderMenu.scrollLeft = sliderMenu.scrollLeft - 30;
    setArrowsInfo(sliderMenu)
  }

  const clickRight = () => {
    const sliderMenu = ourRef.current.children[0];
    sliderMenu.scrollLeft = sliderMenu.scrollLeft + 30;
    setArrowsInfo(sliderMenu)
  }

  const handleScroll = (e) => {
    setArrowsInfo(ourRef.current.children[0])
  }

  const { sizeScreen } = useScreenDetector();

  useEffect(() => {
    if(!ourRef)
      return;

    const sliderMenu = ourRef?.current.children[0];
    if(sliderMenu.scrollWidth - sliderMenu.scrollLeft > sliderMenu.clientWidth + 1){
      sliderMenu.addEventListener("wheel", handleWheel)
      sliderMenu.addEventListener("scroll", handleScroll);
      setArrowsInfo(sliderMenu);
    }
    else{
      sliderMenu.removeEventListener("wheel", handleWheel, false)
      sliderMenu.removeEventListener("scroll", handleScroll, false);
      setArrowsInfo(sliderMenu);
    }
  }, [ourRef, sizeScreen])

  const [newItem, setNewItem] = useState(false)

  const [isEditItem, setIsEditItem] = useState(false)

  const [editItemOldValue, setEditItemOldValue] = useState();

  const sortedCategories = useMemo(() => categories.sort((a, b) => Sort.numericAsc(a.order, b.order)), [categories]);

  const addNewItem = async(val) => {
    if(val){
      addCategory(card.id, {name: val}, restaurantId)
    }
    setNewItem(false);
    setIsEditItem(false);
    setEditItemOldValue()
  }

  const addNewGroup = (val) => {
    addCategory();
  }

  const edit = (newVal) => {
    editGroup(editItemOldValue, {...editItemOldValue, name: newVal});
    setNewItem(false);
    setIsEditItem(false);
    setEditItemOldValue()
  }

  const addGroup = () => {
    setNewItem(true);
  }

  const editItem = (item) => {
    setEditItemOldValue(item)
    setIsEditItem(true)
  }

  const removeItem = () => {
    removeGroup(editItemOldValue)
  }

  const editGroup = async(category, newValue) => {
    if(category.name == newValue.name){
      return;
    }
    updateCategory(category.id, newValue, restaurantId);
  }

  const removeGroup = (category) => {
    removeCategory(category.id)
  }
  const [isSaving, setIsSaving] = useState(false)
  const toaster = useToaster();

  const [dictionaryItem, setDictionaryItem] = useState(null);
  const [dictionaryInfoItem, setDictionaryInfoItem] = useState(null);

  const changeDictionary = async (dictionaryItem, val) => {
    dictionaryItem.text = val;
    setIsSaving(true)
    let result = await new ApiLibrary(language).dictionary.change(dictionaryItem)
    setIsSaving(false)
    dictionaryItem =result.data;
    toaster.push(
      <Message showIcon type="success" closable>
        {translate("changedTranslation")}
      </Message>, 
      { placement: "topEnd", duration: 5000 }
    )
  }

  const FilterButtonAppearance = {
    DEFAULT: 'ghost',
    ACTIVE: 'primary'
  };

  const filterButtonAppearance = useMemo(() => (
      isFilterActive ? FilterButtonAppearance.ACTIVE : FilterButtonAppearance.DEFAULT
    ), [isFilterActive]);

    const infoButtonAppearance = useMemo(() => (
      isActivInfo ? FilterButtonAppearance.ACTIVE : FilterButtonAppearance.DEFAULT
    ), [isActivInfo]);

    

  return (
    <>
    {isSaving && 
        <LoaderDirectiveComponent />
      }
    {!!dictionaryItem &&
          <ModalEditComponent
            setOpen={() => setDictionaryItem(null)}
            setModelChanges={(val) => changeDictionary(dictionaryItem, val)}        
            hideLanguages
            isSimpleModel
            hideDeleteButton
            title={translate("translate")}
        >
          <DictionaryEditModalComponent model={dictionaryItem.text} />
        </ModalEditComponent>
        }

        {!!dictionaryInfoItem &&
                  <ModalEditComponent
                    setOpen={() => setDictionaryInfoItem(null)}
                    setModelChanges={(val) => changeDictionary(dictionaryInfoItem, val)}
                    hideLanguages
                    isSimpleModel
                    hideDeleteButton
                    modalContainerClassName="modal-edit-container-info"
                    title={translate("translate")}
                >
                  <DictionaryEditTextAreaModalComponent model={dictionaryInfoItem.text} />
                </ModalEditComponent>
                }

    <div className="display-flex">
    <div className="padding-left-md admin-filter-button">
                          <IconButton
                            circle
                            icon={<TbFilterSearch  size={15}/>}
                            onClick={() => setFilterOpen(!filterOpen)}
                            appearance={filterButtonAppearance}
                            disabled={extraData.defaultLang != language}
                          />
                        </div>
      <div className="info-button-admin">
                <IconButton
                   icon={extraData.defaultLang == language ? <FaInfo  size={15}/> : <HiTranslate  size={15}/>}
                   onClick={() => {extraData.defaultLang == language ? setIsEditInfo(true) : setDictionaryInfoItem(card.info?.items?.find(x => x.lang == language))}}
                   disabled={extraData.defaultLang != language && !isActivInfo}
                   appearance={infoButtonAppearance}
                  />
     </div>

      <div className="header-group-scroll-admin">
      
        {showLeftArrow &&
        (<div className="admin-group-arrow card-left-arrow" onClick={clickLeft}>
          <FaChevronLeft size={20} className="admin-group-arrow-icon menu-left-arrow-nav-bar-scroll"/>
        </div>)
        }      
        <div
          ref={ourRef}
          // onMouseDown={handleDragStart}
          // onMouseUp={handleDragEnd}
          // onMouseMove={handleDrag}
          className="horizontal-menu-container scroll-horizontal"
        >
          <div id="sliderMenu" className="container-menu scroll-horizontal">
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable direction='horizontal' droppableId="categories">
                {(provided) => (
                  <div style={{display: 'flex'}} {...provided.droppableProps} ref={provided.innerRef}>
                    {sortedCategories?.map((category, idx) => (
                      <Draggable key={category.id} draggableId={category.id} index={idx} isDragDisabled={extraData.defaultLang != language}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={idx} onClick={() => setSelectedCategory(category.id)}>
                            <div className={`horizontal-menu-item display-flex ${category.id == selectedCategory?.id ? "horizontal-menu-item-selected" : ""} ${!category.name ? "special-empty-element" : ""}`}>
                              <div ref={itemsRefs[category]}>
                                {category.name?.items.find(x => x.lang == language)?.text ?? translate('Brak kategorii')}
                              </div>
                              <>
                              {extraData.defaultLang == language ?
                                  <div className="cursor-pointer" onClick={(e) => { e.stopPropagation(); editItem(category)}} >
                                    <FaPencil className="margin-left-md" size={15} />
                                  </div>
                                  :
                                  <span className="translate-button" onClick={(e) => { e.stopPropagation(); setDictionaryItem(category.name?.items?.find(x => x.lang == language))}}>
                                    <HiTranslate size={16} />
                                  </span>
                              }
                              </> 
                            </div>
                          </div>)}
                      </Draggable>
                    ))}

                    {newItem && 
                      <>
                        <ModalEditComponent isSimpleModel title={translate("creationCategory")} setOpen={setNewItem} setModelChanges={(val) => addNewItem(val)} hideDeleteButton hideLanguages >
                          <MenuGroupEditComponent model={""} validModel={sortedCategories} />
                        </ModalEditComponent>
                      </>
                    }
                    
                    {isEditItem && 
                      <>
                        <ModalEditComponent isSimpleModel title={translate("editCategory")} setOpen={setIsEditItem} setModelChanges={edit} deleteItem={removeItem} hideLanguages >
                          <MenuGroupEditComponent model={editItemOldValue.name.items.find(x => x.lang == language).text} validModel={sortedCategories.filter(({ id }) => id != editItemOldValue.id)} />
                        </ModalEditComponent>
                      </>
                    }
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {extraData.defaultLang == language &&
              <div className="cursor-pointer horizontal-menu-item" onClick={() => addGroup()}>
                <FaPlus size={15} className=""/>
              </div>
            }
          </div>
          {showRightArrow && 
            <div className="admin-group-arrow card-right-arrow" onClick={clickRight}>
              <FaChevronRight size={20} className="admin-group-arrow-icon menu-right-arrow-nav-bar-scroll"/>
            </div>
          }
        </div>
      </div>
    </div>
      {!!selectedCategory && <MenuItemsListAdminComponent restaurantId={restaurantId} extraData={extraData} setItems={setItems} userId={userId} setRunningToken={setRunningToken} />}
    </>
    
  );
}

export default NavBarGroupAdminComponent;
