/* eslint-disable react-hooks/exhaustive-deps */
import "../App.css";
import "../css/dish.css";
import "../css/global.css";
import "../css/admin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Favicon from "react-favicon";
import { useState, useEffect } from "react";
import { Button, useToaster, Message, IconButton  } from "rsuite";
import { useI18nContext } from "../i18n/context/context";
import { useNavigate, useParams } from 'react-router-dom';
import { useAdminApiContext } from '../hooks/admin/context';
import AdminPreviewComponent from "../components/admin/AdminPreviewComponent";
import { useCookies } from 'react-cookie';
import { ApiLibrary } from '../helpers/api/ApiLibrary';
import { useLogger } from '../hooks/logger/useLogger';
import LoaderDirectiveComponent from "../directives/LoaderDirectiveComponent";
import LogoBackComponent from "../components/base/top/LogoBackComponent";
import MyPage from "./adminSubPages/MyPage";
import AdditionalWindows from "./adminSubPages/AdditionalWindows";
import Configuration from "./adminSubPages/Configuration";
import LanguageComponent from "../components/base/top/LanguageComponent";
import colors from '../datas/colors.json'
import logos from '../datas/logo.json'
import DialogModalComponent from "../components/modal/DialogModalComponent";
import ProfileComponent from "../components/base/top/ProfileComponent";
import TranslateComponent from "../components/base/top/TranslateComponent";

function AdministratorPage({ OpenEditModal, OpenModal, setRunningToken, runningToken }) {
  const { translate, language, setRestaurantLanguages, setIsAutoTranslatet, notTranslatedElementsExist, setNotTranslatedElementsExist } = useI18nContext();
  const toaster = useToaster();
  const [restaurant, setRestaurant] = useState();
  const [isPreview, setIsPreview] = useState(false)
  const { userId, restaurantCode } = useParams();
  const navigate = useNavigate();
  const { fetchCards } = useAdminApiContext();
  const [cookies, setCookie, deleteCookie] = useCookies(['sefooToken']);
  const logger = useLogger();
  const [isLoading, setIsLoader] = useState(false)
  const [checkChangeBookmark, setCheckChangeBookmark] = useState(false);
  const [askQuestion, setAskQuestion] = useState(false);
  const [afterQuestion, setAfterQuestion] = useState(false);
  const [faviconUrl, setFaviconUrl] = useState(window.location.origin.concat("/sefoo.svg"))

  const pages = ["myPage", "additionalWindows", "configuration"]
  const [activPage, setActivePage] = useState("myPage")
  const [activNextPage, setActiveNextPage] = useState(null);

  const translateDictionaries = async(restaurantId) => {
    setIsLoader(true)
    await new ApiLibrary(language).dictionary.translateNotTranslated({ restaurantId: restaurantId })
    toaster.push(
      <Message showIcon type="success" closable>
        {translate("translationUpdates")}
      </Message>, 
      { placement: "topEnd", duration: 5000 }
    )
    setNotTranslatedElementsExist(false)
    setIsLoader(false)
  }

  useEffect(() => {
    const verify = async () => {
      try {
        const myCookie = cookies['sefooToken'];

        if(!myCookie?.token){
          window.location.href = window.location.origin.concat("/login");
          return false;
        }

        setIsLoader(true)
        const response = await new ApiLibrary(language).restaurants.general.getRestaurantDataForAdmin(myCookie?.userId, restaurantCode, myCookie?.token);
        setIsLoader(false)

        if (response?.data?.id) {
          setRestaurantLanguages(response?.data?.extraConfig.langs.concat(response?.data?.defaultLang));
          setRestaurant(response.data);
          let root = document.querySelector(':root');
          let firstColor = response?.data?.extraConfig?.firstColor ?? colors[response.data.color]?.firstColor ?? colors.sefoo.firstColor;
          let secondColor = response?.data?.extraConfig?.secondColor ?? colors[response.data.color]?.secondColor ?? colors.sefoo.secondColor;
          let thirdColor = response?.data?.extraConfig?.thirdColor ?? colors[response.data.color]?.thirdColor ?? colors.sefoo.thirdColor;
          let fourthColor = response?.data?.extraConfig?.fourthColor ?? colors[response.data.color]?.fourthColor ?? colors.sefoo.fourthColor;
          let fifthColor = response?.data?.extraConfig?.fifthColor ?? colors[response.data.color]?.fifthColor ?? colors.sefoo.fifthColor;
          let sixthColor = response?.data?.extraConfig?.sixthColor ?? colors[response.data.color]?.sixthColor ?? colors.sefoo.sixthColor;
    
          root.style.setProperty('--sefooColor', firstColor);
          root.style.setProperty('--secondSefooColor', secondColor);
          root.style.setProperty('--rs-btn-primary-bg', firstColor);
          root.style.setProperty('--rs-btn-primary-hover-bg', secondColor);
          root.style.setProperty('--rs-menuitem-active-text', secondColor);
          root.style.setProperty('--rs-dropdown-item-text-active', secondColor);
          root.style.setProperty('--rs-btn-primary-active-bg', secondColor);
          root.style.setProperty('--rs-btn-ghost-text', firstColor);
          root.style.setProperty('--rs-btn-ghost-border', secondColor);
          root.style.setProperty('--rs-btn-ghost-hover-text', secondColor);
          root.style.setProperty('--rs-btn-ghost-hover-border', secondColor);
          root.style.setProperty('--rs-toggle-checked-hover-bg', firstColor);
          root.style.setProperty('--rs-input-focus-border', firstColor);
          root.style.setProperty('--secondBackgroundColor', thirdColor);
          root.style.setProperty('--rs-dropdown-item-bg-active', thirdColor);
          root.style.setProperty('--rs-listbox-option-hover-bg', fourthColor);
          root.style.setProperty('--rs-menuitem-active-bg', fourthColor);
          root.style.setProperty('--rs-message-info-bg', sixthColor);
          root.style.setProperty('--rs-message-info-icon', firstColor);
          root.style.setProperty('--rs-color-focus-ring', fifthColor);
          document.title = response.data.restaurantName;
          let logo = window.location.origin.includes('https://sefoo.') ? logos.smallEmptyUrl : logos.testSmallEmptyUrl;
          setFaviconUrl(logo.replace('{sefooColor}', firstColor.replace('#', '')));
          
          setIsAutoTranslatet(response?.data?.isAutoTranslate);
          if(!response?.data?.isAutoTranslate){
            new ApiLibrary(language).dictionary.checkIfNotTranslatedExist({ restaurantId: response?.data?.id }).then(data => {
              setNotTranslatedElementsExist(data.data)
            })
          }

          return true;
        } else {
          deleteCookie('sefooToken');
          window.location.href = window.location.origin.concat("/login");
          return false;
        }
      } catch(error) {
        deleteCookie('sefooToken');
        window.location.href = window.location.origin.concat("/login");
      }
    }

    verify().then((result) => {
      if(result){
        fetchCards(restaurantCode);
      }
      else{
        window.location.href = window.location.origin.concat("/login");
      }
    });
  }, [restaurantCode, translate]);

  const watchChanges = () =>{
    setCheckChangeBookmark(true)
  }

  const checkChangePage = (page) => {
    let saveButtonElement = document.getElementById("buttonSaveContainer")
    if(saveButtonElement && !checkChangeBookmark){
      setActiveNextPage(page)
      setCheckChangeBookmark(true)
      return
    }
    if(checkChangeBookmark){
      setActiveNextPage(page)
      setAskQuestion(true)
    }
    else{
      setActivePage(page)
    }
  }

  const setAnswere = (val) => {
    if(val && activNextPage){
      setActivePage(activNextPage)
    }
    setCheckChangeBookmark(!val);
    setActiveNextPage(null)
    setAfterQuestion(true)
  }

  const handleImageChange = async (field, val) => {
    setRestaurant(prevRestaurant => { 
      let newModel = {
        ...prevRestaurant, 
        [field]: val
      }
      new ApiLibrary(language).restaurants.general.update(newModel.id, newModel).then(response => {
        if(!response?.data){
          toaster.push(
            <Message showIcon type="error" closable>
              {translate("somethingWrong")}
            </Message>, 
            { placement: "topEnd", duration: 5000 }
          )
          return
        }
        return response?.data;
      });
      // setRestaurant(newModel);
      return newModel;
    });
  }

  const onRestaurantChange = (restaurantUpdate) => {
    setRestaurant(restaurantUpdate)
  };

  return (
    <>
        <Favicon url={faviconUrl} />
        {isLoading &&
          <LoaderDirectiveComponent />
        }
        {askQuestion &&
          <DialogModalComponent blockCloseOutside open={askQuestion} onClose={() => setAskQuestion(false)} setAnswere={setAnswere} text={translate("doYouWantLoseChanges")} type="confirm"/>
        }
        {isPreview &&
          <AdminPreviewComponent OpenModal={OpenModal} setPreview={setIsPreview} />
        }
        {!isPreview && !!restaurant &&
        <div>
          <div>
            <div className="col-12 margin-bottom-sm header-flex-style main-header-style">
              <div className="margin-top-sx" onClick={() => navigate(`/${restaurantCode}`)}>
                <LogoBackComponent color={restaurant?.extraConfig?.color} pathBack={window.location.origin.concat('/user/').concat(cookies['sefooToken']?.userId)} />
              </div>
              <div className="display-flex">
                <div className="admin-main-header-button">
                  <Button className="asd" appearance="ghost" onClick={() => setIsPreview(true)}>{translate("preview")}</Button>
                </div>
                {!restaurant?.isAutoTranslate &&
                  <div className="admin-main-header-button">
                    <TranslateComponent restaurantId={restaurant.id} notTranslatedElementsExist={notTranslatedElementsExist} translateDictionaries={translateDictionaries} />
                  </div>
                }
                <LanguageComponent defLang={restaurant.defaultLang} langs={restaurant.extraConfig.langs} reload={!isPreview} />
                <ProfileComponent />
              </div>
            </div>
          </div>
          <div className="admin-page-container">


          <div className="admin-subpages-container display-flex">
                {pages.map((page, idx) => (
                  <div key={idx} className={`admin-page ${page == activPage ? "selected-admin-page" : ""}`} onClick={() => {
                        checkChangePage(page)
                      }
                    }>
                      {translate(page)}
                  </div>
                ))}
              </div>
            {!!restaurant && 
              <>
                {activPage == "myPage" && <MyPage handleImageChange={handleImageChange} checkChangeBookmark={checkChangeBookmark} handleChange={onRestaurantChange} setCheckChangeBookmark={setCheckChangeBookmark} setAnswere={setAnswere} setAskQuestion={setAskQuestion} watchChanges={watchChanges} userId={userId} setRunningToken={setRunningToken} afterQuestion={afterQuestion} setAfterQuestion={setAfterQuestion} OpenEditModal={OpenEditModal} Restaurant={restaurant} />}
                {activPage == "additionalWindows" && <AdditionalWindows checkChangeBookmark={checkChangeBookmark}  setCheckChangeBookmark={setCheckChangeBookmark} setAnswere={setAnswere} setAskQuestion={setAskQuestion} afterQuestion={afterQuestion} setAfterQuestion={setAfterQuestion} watchChanges={watchChanges} Restaurant={restaurant} />}
                {activPage == "configuration" && <Configuration Restaurant={restaurant} setCheckChangeBookmark={setCheckChangeBookmark} handleChange={onRestaurantChange} runningToken={runningToken} setRunningToken={setRunningToken} userId={userId} />}
              </>      
            }
          </div>
        </div>
        }
    </>
  )
}

export default AdministratorPage;
