import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import { FaUpload } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Loader } from 'rsuite';
import VideoPlayerComponent from '../components/base/VideoPlayerComponent';

function DropzoneDirectiveComponent({onUpload, disabled, isMulti, item, containerClassName, emptyClassName, photoClassName, canLoading=true, onlyPhoto, playIfFocus, currentTime}) {
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState("")

  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true)
    await onUpload(acceptedFiles);
    setLoading(false)
  }, [])

  useEffect(() => {
    setFile(item)
  }, [item])

  const {getRootProps, getInputProps} = useDropzone({onDrop,
    accept: !onlyPhoto ? {
      'image/*': ['.jpeg', '.png'],
      "video/mp4": [".mp4"],
      "video/mpeg": [".mpeg"],
      "video/webm": [".webm"]
    } : {
      'image/*': ['.jpeg', '.png']
    }, 
    multiple: !!isMulti,
    disabled: disabled
  })

  const getItem = useMemo(() => {
    if(!file)
      return (<></>)
    if(file.slice(-4) == ".jpg" || file.slice(-4) == ".png"){
      return (
        <div className='dropzone-picture-container' onClick={(e) => e.stopPropagation()}>
          <div style={{display: "contents"}}>
            <img src={file.replace('http://', 'https://')} className={` ${photoClassName} dropzone-picture-style  `} />
          </div>
          <div className='delete-image'>
            <IoClose
              className="remove-button"
              onClick={(e) => {e.stopPropagation(); onUpload([]); setFile("")}}
            />
          </div>
        </div>
      )
    }
    else{
      return (
        <div className='dropzone-picture-container' onClick={(e) => e.stopPropagation()}>
          <div>
            <VideoPlayerComponent
              id="demo-player"
              publicId={file.replace('http://', 'https://')}
              className={` ${photoClassName} dropzone-picture-style  `}
              autoplay={false}
              playIfFocus={playIfFocus}
              currentTime={currentTime}
              muted
            />
          </div>
          <div className='delete-image'>
            <IoClose
              className="remove-button"
              onClick={(e) => {e.stopPropagation(); onUpload([])}}
            />
          </div>
        </div>
      )
    }
    
  }, [file])

  return (
    <div {...getRootProps()} className={` ${containerClassName} ${!file && emptyClassName} ${disabled ? "disabled-style" : ""} ${!!loading && !file && canLoading ? "disable-click" : ""}`}>
      {canLoading &&
        <>
          {!loading && !file &&
            <div className={` ${emptyClassName} admin-dish-picture-empty-style  `}>
              <input {...getInputProps()} />
              <FaUpload  className='dropzone-icon-style dropzone-icon'/>
            </div>
          }
          {!!loading && !file && canLoading &&
            <div className={` ${emptyClassName} dropzone-loading  `}>
              <Loader size="lg" className='dropzone-icon' />
            </div>
          }
          {!!file && 
            <>
              {getItem}
            </>
          }
        </>
      }
      {!canLoading && 
        <div className={` admin-dish-picture-empty-style ${emptyClassName} `}>
          <input {...getInputProps()} />
          <FaUpload className='dropzone-icon-style dropzone-icon'/>
        </div>
      }
    </div>
  )
}

export default DropzoneDirectiveComponent;