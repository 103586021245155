function DescriptionComponent({ items, content }) {
  return (
    <div className="description-style">
      <div className="padding-left-md padding-right-md ql-editor">
        <div dangerouslySetInnerHTML={{__html: content}} />
      </div>
    </div>
  );
}

export default DescriptionComponent;
