import React, { useCallback, useRef, useState, useMemo, useEffect }  from "react";
import { useI18nContext } from "../../../i18n/context/context";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L, {Icon} from 'leaflet'
import blackMarker from '../../../assets/images/blackMarker.png'
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { hexToCSSFilter } from 'hex-to-css-filter';


function AdminBasicMapComponent({ restaurant, isEditable, setLocation }) {
  const [marker, setMarker] = useState(blackMarker)

  const [position, setPosition] = useState({
    lat: restaurant?.location?.y,
    lng: restaurant?.location?.x
  })

  useEffect(() => {
    const observer = new MutationObserver((mutationsList, observer) => { 
      const markers = document.getElementsByClassName('leaflet-marker-icon');
      let filter = hexToCSSFilter(restaurant.extraConfig?.firstColor).filter;
      for (let i = 0; i < markers.length; i++) {
        markers[i].style.filter = filter.replace(';', '');
      }
    }); 
    observer.observe(document.body, { childList: true, subtree: true }); 
    return () => { observer.disconnect(); }; 
  }, [restaurant])

  const legalIcon = new Icon ({
    iconUrl: marker,
    iconSize: [40, 40]
  })

  const provider = new OpenStreetMapProvider();

  const searchControl = new GeoSearchControl({
    provider: provider,
  });

  function DraggableMarker () {
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            let location = marker.getLatLng();
            setPosition(location);
            if(setLocation){
              setLocation(location)
            }
          }
        },
      }),
      [],
    )
    const toggleDraggable = useCallback(() => {
      setDraggable((d) => !d)
    }, [])
  
    return (
      <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
        icon={legalIcon}>
      </Marker>
    )
  }

  return (
    <div className="admin-basic-map-container">
      <MapContainer center={position}  zoom={15}  scrollWheelZoom={true} className="admin-basic-map">
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> authors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <DraggableMarker />
      </MapContainer>
    </div>
    
  );
}

export default AdminBasicMapComponent;
