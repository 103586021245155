import stringsPl from "../language/strings_pl.json";
import stringsEnGb from "../language/strings_en-gb.json";
import stringsEnUS from "../language/strings_en-us.json";
import stringsUk from "../language/strings_uk.json";
import stringsPtPT from "../language/strings_pt-pt.json";
import stringsPtBR from "../language/strings_pt-br.json";
import stringsCs from "../language/strings_cs.json";
import stringsAr from "../language/strings_ar.json";
import stringsId from "../language/strings_id.json";
import stringsRu from "../language/strings_ru.json";
import stringsBg from "../language/strings_bg.json";
import stringsDa from "../language/strings_da.json";
import stringsEt from "../language/strings_et.json";
import stringsDe from "../language/strings_de.json";
import stringsEl from "../language/strings_el.json";
import stringsEs from "../language/strings_es.json";
import stringsFi from "../language/strings_fi.json";
import stringsFr from "../language/strings_fr.json";
import stringsHu from "../language/strings_hu.json";
import stringsIt from "../language/strings_it.json";
import stringsJa from "../language/strings_ja.json";
import stringsKo from "../language/strings_ko.json";
import stringsLt from "../language/strings_lt.json";
import stringsLv from "../language/strings_lv.json";
import stringsNl from "../language/strings_nl.json";
import stringsNb from "../language/strings_nb.json";
import stringsRo from "../language/strings_ro.json";
import stringsSk from "../language/strings_sk.json";
import stringsSl from "../language/strings_sl.json";
import stringsSv from "../language/strings_sv.json";
import stringsTr from "../language/strings_tr.json";
import stringsZh from "../language/strings_zh.json";



const languageMapper = {
    "pl": stringsPl,
    "en-gb": stringsEnGb,
    "en-us": stringsEnUS,
    "uk": stringsUk,
    "pt-pt": stringsPtPT,
    "pt-br": stringsPtBR,
    "cs": stringsCs,
    "ar": stringsAr,
    "id": stringsId,
    "ru": stringsRu,
    "bg": stringsBg,
    "da": stringsDa,
    "et": stringsEt,
    "de": stringsDe,
    "el": stringsEl,
    "es": stringsEs,
    "fi": stringsFi,
    "fr": stringsFr,
    "hu": stringsHu,
    "it": stringsIt,
    "ja": stringsJa,
    "ko": stringsKo,
    "lt": stringsLt,
    "lv": stringsLv,
    "nl": stringsNl,
    "nb": stringsNb,
    "ro": stringsRo,
    "sk": stringsSk,
    "sl": stringsSl,
    "sv": stringsSv,
    "tr": stringsTr,
    "zh": stringsZh
}

export default languageMapper;