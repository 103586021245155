import { BaseApi } from '../shared/baseApi';
import { createGetRequest, createPostRequest } from '../shared/fetch';

export class AnnouncementsApi extends BaseApi {
  get(restaurantId) {
    return createGetRequest(`restaurants/${restaurantId}/announcement`, this._language);
  }

  updateOrCreate(restaurantId, payload) {
    return createPostRequest(`restaurants/${restaurantId}/announcement`, JSON.stringify(payload), this._language);
  }
}