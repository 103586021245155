import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Input, InputGroup, InputNumber, Radio, RadioGroup, Toggle } from 'rsuite';
import { useI18nContext } from "../../../i18n/context/context";
import { ApiLibrary } from "../../../helpers/api/ApiLibrary";
import LoaderDirectiveComponent from "../../../directives/LoaderDirectiveComponent";
import AutoCompleteDirectiveComponent from "../../../directives/AutoCompleteDirectiveComponent";
import SelectDirectiveComponent from "../../../directives/SelectDirectiveComponent";
import SelectRadioDirective from "../../../directives/SelectRadioDirective";


function RestaurantEditForUserComponent({ model, setChanges, setCanSave, canSave, validModel }) {
  const { translate, language, languageList } = useI18nContext();
  const [isLoading, setIsLoading] = useState(false);
  const [myModel, setMyModel] = useState({...model});
  const [sefooCodes, setSefooCodes] = useState([])
  const [sefooCodeIdx, setSefooCodeIdx] = useState(-1)
  const [addressCandidates, setAddressCandidates] = useState([])
  const [addressCandidatesIdx, setAddressCandidatesIdx] = useState(-1)
  const [restaurantTypes, setRestaurantTypes] = useState([])

  const getSefooCodes = async (restaurantId, name, city, street, streetNumber, code, coordines, firstTime) => {
    setIsLoading(true);
    let response = await new ApiLibrary(language).user.users.getSefooCodes({restaurantId, name, city, street, streetNumber});
    setIsLoading(false);

    if(!!response?.data?.sefooCodes){
      setSefooCodes(response.data.sefooCodes.map(x => ({code: x, value: window.location.origin.concat('/').concat(x)})))
      if(!!myModel?.restaurantCode){
        let myIdx = firstTime ? response.data.sefooCodes.indexOf(code) : sefooCodeIdx;
        if(myIdx == -1){
          myIdx = 0;
        }
        selectCode(response.data.sefooCodes.map(x => ({code: x, value: window.location.origin.concat('/').concat(x)}))[myIdx], myIdx)
      }
    }

    if(!!response?.data?.candidates){
      setAddressCandidates(response.data.candidates.map(x => ({code: x, value: x.address})))
      if(!!myModel?.location?.y && !!myModel?.location?.y){
        let myIdx = firstTime ? response.data.candidates.findIndex(cand => cand.location.x == coordines.x && cand.location.y == coordines.y) : addressCandidatesIdx;
        if(myIdx == -1){
          myIdx = 0;
        }
        selectAddressCandidate(response.data.candidates.map(x => ({code: x, value: x.address}))[myIdx], myIdx)
      }
    }
  }

  const checkChangeCode = (thisModel, firstTime) => {
    if(thisModel?.restaurantName &&
      thisModel?.address?.city &&
      thisModel?.address?.street &&
      thisModel?.address?.streetNumber
    ){
      getSefooCodes(thisModel?.id, thisModel?.restaurantName, thisModel?.address?.city, thisModel?.address?.street, thisModel?.address?.streetNumber, thisModel?.restaurantCode, thisModel?.location, firstTime)
    }
  }

  useEffect(() => {
    checkChangeCode(model, true)
  }, [model])

  useEffect(() => {
    const fetchData = async () => {
      let response = await new ApiLibrary(language).dictionary.getDictionariesByCodes({
        codes: ["restaurantType"]
      })
      setRestaurantTypes(response?.data?.restaurantType ?? []);
    }
    fetchData();

  }, [])

  const setModelChanges = (thisModel) => {
    setMyModel(thisModel)
    setChanges(thisModel)
    setCanSave(thisModel.restaurantName && thisModel.address.city && thisModel.address.street && thisModel.address.streetNumber && thisModel.restaurantType && thisModel.restaurantCode && thisModel?.location?.x &&  thisModel?.location?.y)
  }

  const selectCode = (item, idx) => {
    let thisModel = {...myModel};
    thisModel.restaurantCode = item.code;
    setModelChanges(thisModel);
    setSefooCodeIdx(idx);
  }

  const selectAddressCandidate = (item, idx) => {
    let thisModel = {...myModel};
    thisModel.address.fullAddress = item.code.address;
    thisModel.location = {x: item.code.location.x, y: item.code.location.y};
    setModelChanges(thisModel);
    setAddressCandidatesIdx(idx);

  }

  if(!myModel){
    return
  }
  
  return (
    <div id="addRestaurantModal" className="edit-item-style">
      {isLoading &&
        <LoaderDirectiveComponent />
      }

      <div className="admin-restaurant-basic-edit margin-top-xl">
        <div className="admin-basic-label">
            {translate("name")}
        </div>
        <div className="admin-basic-edit-item-field-text  ">
        <Input className={`admin-basic-edit-item-field-text ${!!myModel?.restaurantName? "sd-selected" : ""}`}
                placeholder={translate("name")}
                defaultValue={myModel.restaurantName}
                onBlur={(e) => checkChangeCode(myModel)}
                onChange={(val, e) => {
                  let thisModel = {...myModel};
                  thisModel.restaurantName = val;
                  setModelChanges(thisModel);
                }}
              />
        </div>
      </div>
      <div className="admin-restaurant-basic-edit margin-top-xl">
        <div className="admin-basic-label">
          {translate("defaultLanguage")}
        </div>
        <div className="admin-basic-edit-item-field-text  ">
          {translate(language)}
        </div>
        {/* <div className="admin-basic-label">
                  {translate("defaultLanguage")}
                </div>
                <div className="admin-basic-edit-item-field-text">
                  <SelectDirectiveComponent 
                    placeholder={translate("defaultLanguage")}
                    parentElementId="App"
                    options={languageList.map(x => ({value: x.value, text: x.label}))}
                    selectedOption={languageList.map(x => ({value: x.value, text: x.label})).filter(x => x.value == myModel.defaultLang)[0]}
                    clearable={false}
                    onChange={(val) => {
                      let thisModel = {...myModel};
                      let lang = languageList.map(x => ({value: x.value, text: x.label})).filter(x => x.value == val)[0]
                      thisModel.extraConfig.langs = thisModel.extraConfig.langs.filter(x => x != val)
                      thisModel.defaultLang = lang.value;
                      setModelChanges(thisModel);
                    }}/>
                  </div> */}
      </div>
      <div className="admin-restaurant-basic-edit margin-top-xl">
        <div className="admin-basic-label  ">
            {translate("restaurantType")}
        </div>
        <div className="admin-basic-edit-item-field-text  ">
          <AutoCompleteDirectiveComponent 
            clearable={false}
            parentElementId="addRestaurantModal"
            value={myModel?.restaurantType?.text ? myModel?.restaurantType : myModel?.restaurantType?.items?.find(x => x. lang == language)} 
            placeholder={translate("restaurantType")}
            onChange={(val) => {
              const updatedModel = {
                ...myModel,
                restaurantType: val
              }
              setModelChanges(updatedModel);
            }}
            options={restaurantTypes}
          />

        {/* <AutoCompleteDirectiveComponent 
                clearable={false}
                  parentElementId="addRestaurantModal"
                  value={myModel?.restaurantType?.items?.find(x => x. lang == language)}
                  onChange={(val) => {
                    let thisModel = {...myModel};
                    thisModel.restaurantType = val;
                    setModelChanges(thisModel);
                  }} 
                  options={!!myModel.restaurantType?.text ? restaurantTypes.filter(x => x.text?.toLocaleLowerCase().includes(myModel.restaurantType.text?.toLocaleLowerCase())) : []}
                  placeholder={translate("restaurantType")}
                />  */}
              </div>
        </div>
      <div className="admin-restaurant-basic-edit margin-top-xl">
              <div className="admin-basic-label  ">
                {translate("address")}
              </div>
              <div className="edit-basic-items-field  ">
              <div className="admin-restaurant-basic-edit admin-restaurant-basic-edit-address margin-top-xl">
                  <div className="admin-basic-items-label  ">
                    {translate("city")}
                  </div>
                  <div className="admin-basic-edit-item-field-text  ">
                    <Input className={` admin-basic-edit-item-field-text  ${!!myModel.address?.city ? "sd-selected" : ""}`}
                          placeholder={translate("city")}
                          defaultValue={myModel.address?.city}
                          onBlur={(e) => checkChangeCode(myModel)}
                          onChange={(val, e) => {
                            let thisModel = {...myModel};
                            thisModel.address.city = val;
                            setModelChanges(thisModel);
                          }}
                        />
                  </div>
                </div>
                <div className="admin-restaurant-basic-edit admin-restaurant-basic-edit-address margin-top-xl">
                  <div className="admin-basic-items-label  ">
                    {translate("street")}
                  </div>
                  <div className="admin-basic-edit-item-field-text  ">
                    <Input className={` admin-basic-edit-item-field-text  ${!!myModel.address?.street ? "sd-selected" : ""}`}
                          placeholder={translate("street")}
                          defaultValue={myModel.address?.street}
                          onBlur={(e) => checkChangeCode(myModel)}
                          onChange={(val, e) => {
                            let thisModel = {...myModel};
                            thisModel.address.street = val;
                            setModelChanges(thisModel);
                          }}
                        />
                  </div>
                </div>
                <div className="admin-restaurant-basic-edit admin-restaurant-basic-edit-address margin-top-xl">
                  <div className="admin-basic-items-label  ">
                    {translate("streetNumber")}
                  </div>
                  <div className="admin-basic-edit-item-field-text  ">
                    <Input className={` admin-basic-edit-item-field-text  ${!!myModel.address?.streetNumber? "sd-selected" : ""}`}
                      placeholder={translate("streetNumber")}
                      defaultValue={myModel?.address?.streetNumber}
                      onBlur={(e) => checkChangeCode(myModel)}
                      onChange={(val, e) => {
                        let thisModel = {...myModel};
                        thisModel.address.streetNumber = val;
                        setModelChanges(thisModel);
                      }}
                    />
                  </div>
                </div>
                {!!addressCandidates && !!addressCandidates.length > 0 &&
                    <div className="margin-top-xl">
                      <SelectRadioDirective list={addressCandidates} selectedIndex={addressCandidatesIdx} selectItem={selectAddressCandidate} />
                    </div>
                  }
              </div>
            </div>
            {/* {!!addressCandidates && !!addressCandidates.length > 0 &&
              <div className="display-flex margin-top-xl">
                <div className="admin-basic-label  ">
                    {translate("address")}
                </div>
                <div className="admin-basic-edit-item-field-text  ">
                  <SelectRadioDirective list={addressCandidates} selectedIndex={addressCandidatesIdx} selectItem={selectAddressCandidate} />
                </div>
              </div>
            } */}
            {!!sefooCodes && !!sefooCodes.length > 0 &&
              <div className="admin-restaurant-basic-edit margin-top-xl">
                <div className="admin-basic-label  ">
                    {translate("sefooUrl")}
                </div>
                <div className="admin-basic-edit-item-field-text  ">
                  <SelectRadioDirective list={sefooCodes} selectedIndex={sefooCodeIdx} selectItem={selectCode} />
                </div>
              </div>
            }
    </div>
  );
}

export default RestaurantEditForUserComponent;
