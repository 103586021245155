import { Dropdown, IconButton } from "rsuite";
import { useState, useCallback, useEffect } from "react";

function SortComponent({ data, SetSortFunction, defSelectedItemKey }) {
  const [selectedItem, SetSelectedItem] = useState(data[0]);

  useEffect(() => {
    if(!!defSelectedItemKey){
      SetSelectedItem(data.filter(x => x.key == defSelectedItemKey)[0])
    }
  }, [defSelectedItemKey])

  const SelectSort = useCallback((eventKey) => {
    SetSelectedItem(data.filter(x => x.key == eventKey)[0]);
    SetSortFunction(eventKey);
  }, [selectedItem])

  const renderIconButton = (props, ref) => {
    return (
      <IconButton {...props} ref={ref} icon={selectedItem.icon} circle appearance="primary" />
    );
  };

  return (
    <div className="">
      <Dropdown renderToggle={renderIconButton} onSelect={SelectSort} activeKey={selectedItem.key} title={selectedItem.title} placement="bottomStart">
        {data.map((item, key) => (
          <Dropdown.Item key={key} icon={item.icon} eventKey={item.key}>{item.title}</Dropdown.Item> 
        ))}
      </Dropdown>
    </div>
  );
}

export default SortComponent;
