import "../../../css/filters.css";
import React from "react";
import FiltersComponent from "../left/FiltersComponent";

function FiltersRestaurantComponent({ SetFilterData, config, filters, isInCard }) {

  return (
    // ${isInCard ? 'sticky-position-style': 'sticky-zero-position-style'}
    <div className={`filter-container-style sticky-zero-position-style `}>
      <div className="filter-overflow-auto">
        <div className="fake-field-mx"></div>
        <FiltersComponent 
          SetFilterData={SetFilterData}
          config={config}
          filters={filters}
        />
      </div>
    </div>
  );
}

export default FiltersRestaurantComponent;
