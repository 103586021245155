import { BaseApi } from '../shared/baseApi';
import { createGetRequest, createPutRequest, createPostRequest, createGetRequestWithToken } from '../shared/fetch';

export class UsersApi extends BaseApi {

  register(payload){
    return createPostRequest(`user/register`, JSON.stringify(payload), this._language);
  }

  login(payload){
    return createPostRequest(`user/login`, JSON.stringify(payload), this._language);
  }

  
  resetPasswordAsk(payload){
    return createPostRequest(`user/resetPasswordAsk`, JSON.stringify(payload), this._language);
  }

  resetPasswordAnswere(payload){
    return createPostRequest(`user/resetPasswordAnswere`, JSON.stringify(payload), this._language);
  }

  changePassword(payload){
    return createPostRequest(`user/changePassword`, JSON.stringify(payload), this._language);
  }

  activateUser(payload){
    return createPostRequest(`user/activateUser`, JSON.stringify(payload), this._language);
  }

  verifyKey(payload){
    return createPostRequest(`user/verifyKey`, JSON.stringify(payload), this._language);
  }

  getConfigurationData(userId, token) {
    return createGetRequestWithToken(`user/getConfigurationData/${userId}`, this._language, token);
  }

  getSefooCodes(params) {
    return createPostRequest(`user/getSefooCodes`, JSON.stringify(params), this._language);
  }

  verifyToken(userId, token) {
    return createPostRequest(`user/verifyToken`, JSON.stringify({userId: userId, token: token}), this._language);
  }

  verifyTokenWithAppVersion(userId, token, version){
    return createPostRequest(`user/verifyTokenWithAppVersion`, JSON.stringify({userId: userId, token: token, version: version}), this._language);
  }

  deleteUser(userId, token) {
    return createPostRequest(`user/deleteUser`, JSON.stringify({userId: userId, token: token}), this._language);
  }

  logOut(userId, token) {
    return createPostRequest(`user/logOut`, JSON.stringify({userId: userId, token: token}), this._language);
  }
}