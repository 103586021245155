import React, { useEffect, useState } from "react";
import { Input } from 'rsuite';
import { useI18nContext } from "../../../i18n/context/context";

function MenuCardEditInfoComponent({ model, setChanges, setCanSave, validModel }) {
  const { translate, language } = useI18nContext();
    
  const [myModel, setMyModel] = useState(null)

  useEffect(() => {
    setCanSave(true)
    setMyModel(model)
  }, [model])

  const changeValue = (val) => {
    setChanges(val);
    setMyModel(val)
  }

  return (
    <div className="edit-item-style">
      <div className="label-value-admin-modal-style">
        <div className="admin-edit-item-label">
          {translate("info")}
        </div>
        <div className="edit-def-item-field">

          <Input as="textarea" rows={4} className={` ${!!myModel ? "sd-selected" : ""}`}
            defaultValue={model}
            placeholder={translate("info")}
            onChange={(val, e) => changeValue(val)}
          />
        </div>
      </div>
    </div>
  );
}

export default MenuCardEditInfoComponent;
