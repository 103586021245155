/* eslint-disable react-hooks/exhaustive-deps */
import "../../App.css";
import "../../css/dish.css";
import "../../css/global.css";
import "../../css/admin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import useScreenDetector from "../../hooks/useScreenDetector";
import { useI18nContext } from "../../i18n/context/context";
import PosterEditorComponent from '../../components/admin/poster/PosterEditorComponent';
import AnnouncementEditorComponent from '../../components/admin/announcement/AnnouncementEditorComponent';


function AdditionalWindows({ Restaurant, checkChangeBookmark, setAnswere, setAskQuestion, watchChanges, afterQuestion, setAfterQuestion }) {
  const { translate, language } = useI18nContext();
  
  const { sizeScreen } = useScreenDetector();
  const [tabs, setTabs] = useState([]);
  const [activeTab, SetActiveTab] = useState(null);
  const [activeNextTab, SetActiveNextTab] = useState(null);

  useEffect(() => {
    if(afterQuestion){
      SetActiveNextTab(null)
      setAfterQuestion(false)
    }
  }, [afterQuestion, activeNextTab])

  useEffect(() => {
    if(!checkChangeBookmark){
      if(activeNextTab){
        SetActiveTab(activeNextTab)
      }
      SetActiveNextTab(null)
    }
  }, [checkChangeBookmark, activeNextTab])

  const changeActiveTab = (tab) => {
    let saveButtonElement = document.getElementById("buttonSaveContainer")
    if(saveButtonElement && !checkChangeBookmark && !activeNextTab){
      SetActiveNextTab(tab)
      watchChanges()
      return
    }
    if(!checkChangeBookmark){
      SetActiveTab(tab)
    }
    else{
      SetActiveNextTab(tab)
      setAskQuestion(true)
    } 
  }

  useEffect(() => {
    const mTabs = ['starter', 'announcement'];
    setTabs(mTabs);
    if (!mTabs.includes(activeTab) || !activeTab) {
      SetActiveTab(mTabs[0]);
    }
  }, [sizeScreen])

  return (
    <>
      <div className="admin-header-underline" />
      <div className="admin-body">
      <div>
        { !!tabs &&
          <div className="admin-tabs-container">
            {tabs.map((tab, idx) => (
              <div key={idx} className={`admin-tab ${tab == activeTab ? "selected-admin-tab" : ""}`} onClick={() => changeActiveTab(tab)}>
                {translate(tab)}
              </div>
            ))}
        </div>
        }
      </div>
          

          {activeTab === 'starter' && <PosterEditorComponent checkChangeBookmark={checkChangeBookmark} setAnswere={setAnswere} setAskQuestion={setAskQuestion} restaurantId={Restaurant?.id} extraData={{defaultLang: Restaurant.defaultLang }} />}
          {activeTab === 'announcement' && <AnnouncementEditorComponent checkChangeBookmark={checkChangeBookmark} setAnswere={setAnswere} setAskQuestion={setAskQuestion} restaurantId={Restaurant?.id} extraData={{defaultLang: Restaurant.defaultLang }} />}
        </div>
    </>);
}

export default AdditionalWindows;
