import React from "react";
import DropzoneDirectiveComponent from "../../../../directives/DropzoneDirectiveComponent";
import { ApiLibrary } from "../../../../helpers/api/ApiLibrary";
import { useToaster, Message } from 'rsuite';
import { useI18nContext } from "../../../../i18n/context/context";

function MenuItemEditDropzoneComponent({photoUrl, setPhotoUrl, setIsLoading }) {
  const toaster = useToaster();
  const { translate } = useI18nContext();


    const setFile = async ([file]) => {
        if (file) {
          try {
            setIsLoading(true);
            const response = await new ApiLibrary().common.add(file);
            setIsLoading(false);
            setPhotoUrl(response.data?.secure_url);
          } catch (error) {
            setIsLoading(false);
            toaster.push(
              <Message showIcon type="error" closable>
                {translate("somethingWrong")}
              </Message>, 
              { placement: "topEnd", duration: 5000 }
            )
          }
        } else {
          try{
            setIsLoading(true);
            await new ApiLibrary().common.removeByUrl(photoUrl);
            setIsLoading(false);
            setPhotoUrl(null);
          }
          catch(error){
            setIsLoading(false);
            toaster.push(
              <Message showIcon type="error" closable>
                {translate("somethingWrong")}
              </Message>, 
              { placement: "topEnd", duration: 5000 }
            )
          }
        }
      }

    return (
        <div className="">
            <DropzoneDirectiveComponent
              onUpload={setFile}
              item={photoUrl}
              emptyClassName="menu-item-edit-dropzone-empty"
              className="dropzone-def-style"
              playIfFocus={true}
              currentTime={0}
            />  
        </div>
    );
}

export default MenuItemEditDropzoneComponent;
