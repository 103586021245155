import '../App.css';
import '../css/home.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect, useCallback } from 'react';
import MapDefComponent from '../components/home/MapDefComponent';
import HomeRestaurantPanelContainer from '../components/home/HomeRestaurantPanelContainer';
import HomeRestaurantsList from '../components/home/HomeRestaurantsList';
import {FaChevronUp, FaChevronDown  } from "react-icons/fa";
import PropTypes from 'prop-types';
import { useI18nContext } from '../i18n/context/context';
import { ApiLibrary } from '../helpers/api/ApiLibrary';
import LoaderDirectiveComponent from '../directives/LoaderDirectiveComponent';

function HomeDefPage({ OpenModal, isDemo }) {
  const { language, translate } = useI18nContext();
  const [restaurants, setRestaurants] = useState(null)
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [restaurantCode, setRestaurantCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.documentElement.dataset.panelposition = -2;
  }, [])

  const fetchRestaurants = useCallback(async() => {
    setIsLoading(true);
    const response = await new ApiLibrary(language).restaurants.general.getList(isDemo);
    if (!response.data) {
      return;
    }
    setIsLoading(false);
    setRestaurants(response.data);
  }, [language]);


  useEffect(() => {
    setRestaurants(null);
    fetchRestaurants();
  }, [language]);

  return (
    <div>
      {isLoading &&
        <LoaderDirectiveComponent />
      }
      <div id="" className='home-container'>
        <MapDefComponent restaurants={restaurants} setSelectedItem={setSelectedRestaurant} selectedItem={selectedRestaurant} defPosition={restaurants?.find(x => x.restaurantCode == restaurantCode)?.map(x => {return [x.location.x, x.location.y]}) } />
        <div className='home-restaurant-panel'>
          <div className='home-restaurant-panel-control'>
            <div className='home-restaurant-panel-control-back'>
              {selectedRestaurant && <div className='home-restaurant-panel-control-back-text' onClick={() => setSelectedRestaurant(null)}>{translate("list")}</div>}
            </div>
            <div className='home-restaurant-panel-control-down'>
              <FaChevronDown size={20} className='home-restaurant-panel-control-down-icon' onClick={() => {document.documentElement.dataset.panelposition = Number(document.documentElement.dataset.panelposition) - 1}} />
            </div>
            <div className='home-restaurant-panel-control-up'>
              <FaChevronUp size={20} className='home-restaurant-panel-control-up-icon' onClick={() => {document.documentElement.dataset.panelposition = Number(document.documentElement.dataset.panelposition) + 1}} />
            </div>
            {/* <div className='home-restaurant-panel-control-down'>
              <FaChevronDown size={20} className='home-restaurant-panel-control-down-icon' onClick={() => {document.documentElement.dataset.panelposition = Number(document.documentElement.dataset.panelposition) - 1}} />
            </div>
            <div className='home-restaurant-panel-control-up'>
              <FaChevronUp size={20} className='home-restaurant-panel-control-up-icon' onClick={() => {document.documentElement.dataset.panelposition = Number(document.documentElement.dataset.panelposition) + 1}} />
            </div> */}
            <div className='home-restaurant-panel-control-go'>
              {selectedRestaurant && <div className='home-restaurant-panel-control-go-text' onClick={(e) => {window.location.href = window.location.origin.concat('/').concat(selectedRestaurant?.restaurantCode)}}>{translate("goTo")}</div>}
            </div>
          </div>
          <div className='home-restaurant-list'><HomeRestaurantsList restaurants={restaurants} setSelectedItem={setSelectedRestaurant} /></div>
          {selectedRestaurant && <HomeRestaurantPanelContainer OpenModal={OpenModal} setSelectedItem={setSelectedRestaurant} restaurantCode={selectedRestaurant?.restaurantCode} />}
        </div>
      </div>
    </div>
  );
}

HomeDefPage.propTypes = {
  OpenModal: PropTypes.func.isRequired
};

export default HomeDefPage;
